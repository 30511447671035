const { REACT_APP_BASE_URL: BASE_API_URL } = process.env;

export const GET_CARE_STACK_API_KEY = `${BASE_API_URL}/care_stack/sub_organizations/:subOrgId/connected_channels/:channelId/get_hash_key`;

export const CONNECT_CARE_STACK_CHANNEL = `${BASE_API_URL}/care_stack/sub_organizations/connect_care_stack`;

export const CREATE_CARE_STACK_PATIENT = `${BASE_API_URL}/care_stack/create_patient`;

export const LINK_CARE_STACK_PATIENT = `${BASE_API_URL}/care_stack/link_patient`;

export const GET_CARE_STACK_APPOINTMENT_STATUS = `${BASE_API_URL}/care_stack/get_cs_appointment_status`;

export const GET_CARE_STACK_LOCATION_STATUS = `${BASE_API_URL}/care_stack/get_cs_location_status`;

export const GET_LEAD_MAPPED_LOCATION = `${BASE_API_URL}/care_stack/get_lead_mapped_location`;

export const GET_CARESTACK_RECORDING = `${BASE_API_URL}/care_stack/get_cs_convo_recording/:callId/:subOrgId`;
