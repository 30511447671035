import { createSlice } from '@reduxjs/toolkit';

const accessSlice = createSlice({
  name: 'accessSlice',
  initialState: {
    accessStatus: null,
    roleChangeStatus: null
  },
  reducers: {
    setAccess: (state, action) => {
      state.accessStatus = action.payload;
    },
    setRoleChangeStatus: (state, action) => {
      state.roleChangeStatus = action.payload;
    }
  }
});

export const { setAccess, setRoleChangeStatus } = accessSlice.actions;

export default accessSlice.reducer;
