import React, { useEffect, useState } from 'react';
import { detectBrowser } from 'utils/browser';
import Lottie from 'lottie-react';
import Modal from 'components/modal';
import spinner from 'lottie/spinner.json';

const BrowserInfo = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [browserInfo, setBrowserInfo] = useState({
    browserName: '',
    version: ''
  });

  useEffect(() => {
    setIsLoading(true);
    const detectedBrowserInfo = detectBrowser();
    setBrowserInfo(detectedBrowserInfo);
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, []);

  const checkSafariBelow12 = (browser) => {
    return browser.browserName == 'Safari' && parseInt(browser.version) < 12;
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <Lottie style={{ height: 50 }} animationData={spinner} />
      </div>
    );
  }

  const PopUp = () => {
    return (
      <Modal.Main open={true} className="modal--channels-request">
        <Modal.Body className="pb-10 overflow-y-auto">
          <React.Fragment>
            <div className="flex items-center justify-center gap-2">
              <h2
                className="text-xl text-white font-semibold text-center"
                style={{ color: 'white' }}
              >
                Incompatible Browser
              </h2>
            </div>
            <div
              className="text-13 text-center mt-3 px-16 "
              style={{ color: 'white' }}
            >
              Some features may not work correctly in this browser. For the best
              experience, please update your browser.
            </div>
          </React.Fragment>
        </Modal.Body>
      </Modal.Main>
    );
  };

  if (checkSafariBelow12(browserInfo)) {
    return <PopUp />;
  }

  return <>{children}</>;
};

export default BrowserInfo;
