import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import { CONNECT_SMS_CHANNEL } from 'urls/sms';
import { replaceUrl } from 'utils/urlReplace';

const smsSlice = createSlice({
  name: 'smsSlice',
  initialState: {
    smsData: {}
  },
  reducers: {
    setSmsData: (state, action) => {
      state.smsData = action.payload;
    }
  }
});

export const { setSmsData } = smsSlice.actions;

export const ConnectSmsChannel = (payload) => {
  return async (dispatch) => {
    const { subOrgId, body, callback, errorCallback } = payload;
    try {
      new APIRequest()
        .post(replaceUrl(CONNECT_SMS_CHANNEL, 'subOrgId', subOrgId), body)
        .then((resp) => {
          if (resp?.data?.message && errorCallback) {
            errorCallback(resp);
          } else {
            if (callback) {
              callback(resp.data);
            }
            dispatch(setSmsData(resp.data));
          }
        })
        .catch((e) => {
          console.log({ e });
          errorCallback(e);
        });
    } catch (e) {
      errorCallback(e);
      console.log(e);
    }
  };
};

export default smsSlice.reducer;
