const { REACT_APP_BASE_URL: BASE_API_URL } = process.env;

// api to get all connected zaps
export const GET_CONNECTED_ZAPS = `${BASE_API_URL}/zapier/get_zap_forms/:channelId`;
//api to toggle on off zap form
export const TOGGLE_ON_OFF_ZAP_FORMS = `${BASE_API_URL}/zapier/toggle_zap_form`;
//api to assign location to zap form
export const ASSIGN_LOCATION_TO_ZAP_FORM = `${BASE_API_URL}/zapier/assign_location_to_zap_form`;
// to hide help guid for zapier
export const HIDE_ZAPIER_HELP_GUIDE = `${BASE_API_URL}/zapier/hide_message_for_user`;
