import { createSlice } from '@reduxjs/toolkit';
import { IP_DATA_URL } from 'urls/signup';

const initialState = {
  authToken: null,
  signUpFirstName: '',
  signUpSubOrgId: null,
  signUpAuthToken: null,
  ipData: {},
  currentUser: null,
  crmLoading: false,
  email: '',
  signUpChoice: 0,
  error: null,
  redirect: null,
  isBoxGenarationFailed: false
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
    setSignUpAuthToken: (state, action) => {
      state.signUpAuthToken = action.payload;
    },
    clearAuthToken: (state) => {
      state.authToken = null;
      state.signUpAuthToken = null;
      state.signUpFirstName = '';
      state.signUpSubOrgId = null;
      state.currentUser = null;
      state.email = '';
      state.signUpChoice = 0;
      state.error = null;
      state.redirect = null;
      state.ipData = {};
      state.crmLoading = false;
    },
    setIpData: (state, action) => {
      state.ipData = action.payload;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setSignUpChoice: (state, action) => {
      state.signUpChoice = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setRedirect: (state, action) => {
      state.redirect = action.payload;
    },
    setSignUpSubOrgId: (state, action) => {
      state.signUpSubOrgId = action.payload;
    },
    setSignUpFirstName: (state, action) => {
      state.signUpFirstName = action.payload;
    },
    setCrmLoading: (state, action) => {
      state.crmLoading = action.payload;
    },
    setisBoxGenarationFailed: (state, action) => {
      state.isBoxGenarationFailed = action.payload;
    }
  }
});

export const {
  setAuthToken,
  setSignUpAuthToken,
  clearAuthToken,
  setCurrentUser,
  setError,
  setEmail,
  setSignUpChoice,
  setRedirect,
  setIpData,
  setSignUpSubOrgId,
  setSignUpFirstName,
  setCrmLoading,
  setisBoxGenarationFailed
} = authSlice.actions;

export const getIpData = async (callback) => {
  await fetch(IP_DATA_URL)
    .then((res) => res.json())
    .then((data) => {
      if (callback) callback(data);
    });
};

export const createPassword = () => {};

export const forgotPassword = () => {};

export default authSlice.reducer;
