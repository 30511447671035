const { REACT_APP_BASE_URL: BASE_API_URL } = process.env;

export const CONNECT_CS_PHONES_CHANNEL = `${BASE_API_URL}/care_stack/sub_organizations/connect_cs_phones`;

export const GET_CS_PHONES_RECORDING = `${BASE_API_URL}/care_stack/get_cs_phones_recording/:callId/:subOrgId`;

export const GET_LIST_OF_CS_PHONE_NUMBERS = `${BASE_API_URL}/care_stack/get_list_of_csphone_numbers`;

export const UPDATE_CS_PHONES_MAPPED_LOCATION = `${BASE_API_URL}/care_stack/csphone_numbers/:csPhoneId/update_location`;

export const SYNC_CS_PHONE_NUMBER = `${BASE_API_URL}/care_stack/sync_cs_phone_number`;
