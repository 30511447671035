export const getPlanTypeKey = () => {
  return 'enquirybox_plan_type';
};

export const getPlanCouponKey = () => {
  return 'enquirybox_plan_coupon';
};

export const getPlanFreePlan = () => {
  return 'enquirybox_plan_is_free';
};

export const getPartnerId = () => {
  return 'partner_id';
};

export const getEbWebsiteChatbot = () => {
  return 'eb_website_chatbot';
};

export const getWebsiteLink = () => {
  return 'eb_client_website_link';
};

export const getInterNetSpeedKey = () => {
  return 'boxly_last_api_call';
};

const storageFactory = () => {
  let inMemoryStorage = {};
  const isStorageSupported = () => {
    try {
      const testKey = '__some_random_key';
      localStorage.setItem(testKey, null);
      localStorage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  };

  const getItem = (name) => {
    if (isStorageSupported()) {
      return localStorage.getItem(name);
    }
    if (inMemoryStorage[name]) {
      return inMemoryStorage[name];
    }
    return null;
  };

  const setItem = (name, value) => {
    if (isStorageSupported()) {
      return localStorage.setItem(name, value);
    } else {
      inMemoryStorage[name] = value;
    }
  };

  const removeItem = (name) => {
    if (isStorageSupported()) {
      localStorage.removeItem(name);
    } else {
      delete inMemoryStorage[name];
    }
  };

  const clear = () => {
    inMemoryStorage = {};
  };

  return {
    getItem,
    setItem,
    removeItem,
    clear
  };
};

export default storageFactory;
