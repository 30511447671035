import choices from 'choices';
import { timeFormatter, isEmpty } from 'utils/utils';
import defaultProfile from 'assets/icons/boxly/icon-default-profile.svg';
import { isFirstCharDigit } from 'utils/utils';
import { INBOUND, OUTBOUND, MISSED } from 'utils/constants';
// import { removeSignatureFromContent } from 'utils/dom';
import { ReactComponent as WhatsappIcon } from 'assets/icons/boxly/channelIcons/icon-channel-whatsapp.svg';
import { ReactComponent as WebsiteChatbotIcon } from 'assets/icons/boxly/channelIcons/icon-channel-website-chatbot.svg';
import { ReactComponent as FbLeadAdIcon } from 'assets/icons/boxly/channelIcons/icon-channel-fb-lead-ad.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/boxly/channelIcons/icon-channel-email.svg';
import ZapierIcon from 'assets/icons/boxly/channelIcons/icon-channel-zapier.svg';
import { ReactComponent as FbIcon } from 'assets/icons/boxly/channelIcons/icon-channel-fb.svg';
import { ReactComponent as WebformIcon } from 'assets/icons/boxly/channelIcons/icon-channel-web-form.svg';
import ebotIcon from 'assets/icons/boxly/channelIcons/icon-channel-ebot.png';
import { ReactComponent as CareStackIcon } from 'assets/icons/boxly/channelIcons/icon-channel-carestack.svg';
import { ReactComponent as SmsIcon } from 'assets/icons/boxly/channelIcons/icon-channel-sms.svg';
import { ReactComponent as ManuelLeadIcon } from 'assets/icons/boxly/channelIcons/icon-channel-manuel-lead.svg';
import instagramIcon from 'assets/icons/boxly/channelIcons/icon-channel-instagram.png';

const { JUST_TO_STORE_DATA } = choices.LeadReplyChoice;
const {
  WEB_FORMS,
  FACEBOOK,
  INSTAGRAM,
  FACEBOOK_LEADGEN,
  MANUALLY_ADDED,
  IMPORTED_FROM_FILE,
  SMS,
  EBOT,
  WEBSITE_CHAT_BOT,
  ZAPIER,
  EMAIL_FORWARDING,
  WHATSAPP,
  WEBFORM_FORWARDING,
  CARE_STACK,
  CS_PHONES
} = choices.LeadChannelChoice;
const {
  DUMMY_RIGHT_LEADS,
  DUMMY_BOX_STAGES,
  DUMMY_SMART_RULES,
  DUMMY_CUSTOM_LEAD_FORM,
  DUMMY_AUTOMATED_SEQUENCES,
  DUMMY_CUSTOM_LEAD_CARDS,
  DUMMY_TRACK_CONVERSIONS
} = choices.DummyLeadChoices;

export const getChannelDescription = (
  channel,
  is_cs_appointment_message,
  email
) => {
  switch (channel) {
    case FACEBOOK:
      return 'Facebook';
    case WEB_FORMS:
      return 'Webform';
    case INSTAGRAM:
      return 'Instagram';
    case FACEBOOK_LEADGEN:
      return 'Facebook Lead Ads';
    case MANUALLY_ADDED:
      return 'Manual';
    case IMPORTED_FROM_FILE:
      return 'Manual';
    case SMS:
      return 'Sms';
    case EBOT:
      return 'Enquirybot';
    case WEBSITE_CHAT_BOT:
      return 'Website Chatbot';
    case ZAPIER:
      return 'Zapier';
    case EMAIL_FORWARDING:
      return email ? `Email - ${email}` : 'Email';
    case WEBFORM_FORWARDING:
      return 'Webform';
    case CARE_STACK:
      return is_cs_appointment_message
        ? 'CareStack - Appointment Message'
        : 'CareStack';
    case WHATSAPP:
      return 'Whatsapp';
    case CS_PHONES:
      return 'CS Phones';
    default:
      return 'Lead';
  }
};

export const GetChannelIcons = ({ channel }) => {
  return (
    <div
      style={{
        minHeight: '16px',
        minWidth: '16px',
        maxHeight: '16px'
      }}
    >
      {(() => {
        switch (channel) {
          case FACEBOOK:
            return <FbIcon />;
          case WEB_FORMS:
            return <WebformIcon />;
          case INSTAGRAM:
            return (
              <img
                height={15}
                width={15}
                src={instagramIcon}
                alt="instagram-icon"
              />
            );
          case FACEBOOK_LEADGEN:
            return <FbLeadAdIcon />;
          case MANUALLY_ADDED:
            return <ManuelLeadIcon />;
          case IMPORTED_FROM_FILE:
            return <ManuelLeadIcon />;
          case SMS:
            return <SmsIcon />;
          case EBOT:
            return (
              <img
                height={15}
                width={15}
                src={ebotIcon}
                alt="enquirybot-icon"
              />
            );
          case WEBSITE_CHAT_BOT:
            return <WebsiteChatbotIcon />;
          case ZAPIER:
            return (
              <img
                height={15}
                width={15}
                src={ZapierIcon}
                alt="zapierIcon-icon"
              />
            );
          case EMAIL_FORWARDING:
            return <EmailIcon />;
          case WEBFORM_FORWARDING:
            return <WebformIcon />;
          case CARE_STACK:
            return <CareStackIcon />;
          case WHATSAPP:
            return <WhatsappIcon />;
          case CS_PHONES:
            return <CareStackIcon />;
          default:
            return <></>;
        }
      })()}
    </div>
  );
};

export const getLastReply = (lead) => {
  const { last_reply, dummy_choices, box_dummy_lead, lead_summary } = lead;
  if (box_dummy_lead && dummy_choices === DUMMY_RIGHT_LEADS) {
    return 'Connect your Channels to get leads in';
  }
  if (box_dummy_lead && dummy_choices === DUMMY_BOX_STAGES) {
    return 'See where each lead is at, at a glance';
  }
  if (box_dummy_lead && dummy_choices === DUMMY_SMART_RULES) {
    return 'Save time with AI powered rules';
  }
  if (box_dummy_lead && dummy_choices === DUMMY_CUSTOM_LEAD_FORM) {
    return 'Customise the form to add new leads to this Box';
  }
  if (box_dummy_lead && dummy_choices === DUMMY_TRACK_CONVERSIONS) {
    return 'Keep track of which leads convert';
  }
  if (box_dummy_lead && dummy_choices === DUMMY_AUTOMATED_SEQUENCES) {
    return 'Trigger an automation based on the lead stage';
  }
  if (box_dummy_lead && dummy_choices === DUMMY_CUSTOM_LEAD_CARDS) {
    return 'See all the right lead info at a glance';
  }
  if (!isEmpty(last_reply?.attachments) && !last_reply?.message) {
    return 'File';
  }
  // if (
  //   last_reply?.reply_type == JUST_TO_STORE_DATA ||
  //   ((channel === ZAPIER || channel === FACEBOOK || channel === INSTAGRAM) &&
  //     last_reply?.reply_type == NORMAL_REPLY &&
  //     !last_reply.is_reply_sent) ||
  //   !last_reply
  // ) {
  //   if (!isEmpty(lead_summary)) {
  //     return lead_summary;
  //   } else {
  //     return getChannelDescription(channel);
  //   }
  // }
  // if (last_reply?.reply_type === CS_REPLY) {
  //   return getCareStackLastReply(last_reply);
  // }
  if (!isEmpty(lead_summary)) {
    return lead_summary;
  }
  // const updatedString = removeSignatureFromContent(last_reply?.message);
  // return formatMessage(
  //   updatedString
  //     ?.replace(/<style[^>]*>[^<]*<\/style>/gi, '')
  //     ?.replace(/(<([^>]+)>)/gi, '')
  // );
};

// get care stack last reply in lead
export const getCareStackLastReply = (last_reply) => {
  let tempData = {};
  try {
    tempData = JSON.parse(last_reply?.message);
  } catch (e) {
    console.log(e);
  }
  if (isEmpty(tempData)) {
    return 'Care Stack Call';
  } else {
    return isEmpty(tempData?.carestack_data?.['IQ Summary'])
      ? getCSReplyText(tempData?.carestack_data)
      : tempData?.carestack_data?.['IQ Summary'];
  }
};

// get cs call status text for last reply in lead
export const getCSReplyText = (data) => {
  if (data?.status !== MISSED && data?.direction === OUTBOUND) {
    return 'Call Made';
  } else if (data?.status !== MISSED && data?.direction === INBOUND) {
    return 'Call Received';
  } else {
    return 'Missed Call';
  }
};

export const getLeadTime = (
  { last_reply, created_at, updated_at, latest_reply_received_at },
  timezone
) => {
  if (last_reply?.reply_type == JUST_TO_STORE_DATA) {
    return timeFormatter(latest_reply_received_at, timezone);
  }
  return timeFormatter(latest_reply_received_at || updated_at, timezone);
};

export const isReplySent = ({ last_reply }) => {
  return last_reply?.reply_type !== JUST_TO_STORE_DATA;
};

export const getLeadName = (lead) => {
  if (lead?.name) {
    return lead?.name;
  } else {
    return (
      (lead?.first_name ? lead?.first_name : '') +
      ' ' +
      (lead?.last_name ? lead?.last_name : '')
    );
  }
};

export const getLeadAvatar = (channel, name) => {
  if (
    channel === FACEBOOK_LEADGEN ||
    channel === WEB_FORMS ||
    channel === SMS
  ) {
    return 'https://all-logos.s3.eu-west-1.amazonaws.com/Prod/sms-suzie.svg';
  } else if (channel === INSTAGRAM) {
    return 'https://all-logos.s3.eu-west-1.amazonaws.com/Prod/instagram-annie.svg';
  } else if (channel === FACEBOOK) {
    return 'https://all-logos.s3.eu-west-1.amazonaws.com/Prod/facebook-freddie.svg';
  } else if (channel === WEBSITE_CHAT_BOT || channel === WHATSAPP) {
    return 'https://all-logos.s3.eu-west-1.amazonaws.com/Prod/chatbot-charlie.svg';
  } else if (channel === CS_PHONES) {
    if (isFirstCharDigit(name)) {
      return defaultProfile;
    } else return null;
  }
};

export const isSpamLead = (isSpamLead) => isSpamLead;
