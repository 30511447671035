import { createSlice } from '@reduxjs/toolkit';
import { GET_LOGIN_WAYS, UPDATE_USER_PROFILE_NAME } from 'urls';
import APIRequest from 'apiRequest';
import { replaceUrl } from 'utils/urlReplace';

import { setUserInformation } from 'slices/myInfoSlice';

export const userProfileSlice = createSlice({
  name: 'userProfileSlice',
  initialState: {
    profileUserName: null,
    loginWays: []
  },
  reducers: {
    profileUserName: (state, action) => {
      state.profileUserName = action.payload;
    },
    setLoginways: (state, action) => {
      state.loginWays = action.payload;
    }
  }
});

export const { profileUserName, setLoginways } = userProfileSlice.actions;

export const updateUserProfileName = (payload, onSuccess) => {
  const { userId, data, myInfo } = payload;
  return async (dispatch) => {
    try {
      new APIRequest()
        .put(replaceUrl(UPDATE_USER_PROFILE_NAME, 'user_id', userId), data)
        .then(({ data }) => {
          const updatedMyInfo = { ...myInfo };
          updatedMyInfo['name'] = data.name;
          dispatch(setUserInformation(updatedMyInfo));
          if (onSuccess) {
            onSuccess();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateAssignedToFilter = (payload, onSuccess, onError) => {
  const { data } = payload;
  return async (dispatch, getState) => {
    const { myInformation } = getState().myInfo;
    const userId = myInformation.id;
    try {
      new APIRequest()
        .patch(replaceUrl(UPDATE_USER_PROFILE_NAME, 'user_id', userId), data)
        .then(({ data }) => {
          const updatedMyInfo = { ...myInformation };
          updatedMyInfo['is_assigned_to_filter_in_things_to_do_active'] =
            data.is_assigned_to_filter_in_things_to_do_active;
          dispatch(setUserInformation(updatedMyInfo));
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch(() => {
          if (onError) {
            onError();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getLoginWays = () => {
  return async (dispatch) => {
    try {
      new APIRequest().get(GET_LOGIN_WAYS).then(({ data }) => {
        dispatch(setLoginways(data));
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export default userProfileSlice.reducer;
