import { createSlice } from '@reduxjs/toolkit';
import { replaceUrl } from 'utils/urlReplace';
import APIRequest from 'apiRequest';
import {
  CONNECT_WEBSITE_CHATBOT,
  CREATE_OPENING_MESSAGES,
  GET_CHATBOT_DETAILS,
  UPDATE_OPENING_MESSAGES,
  UPDATE_OPTIONS
} from 'urls/chatbot';

export const chatbot = createSlice({
  name: 'chatbot',
  initialState: {
    chatBotDetail: {},
    openingMessages: [],
    openingOptions: [],
    isChatBotError: false
  },
  reducers: {
    setChatBotDetail: (state, action) => {
      state.chatBotDetail = action.payload;
      state.openingMessages = action.payload.opening_messages;
      state.openingOptions = action.payload.opening_starter_options;
    },
    setOpeningMessages: (state, action) => {
      state.openingMessages = action.payload;
    },
    setOpeningOptions: (state, action) => {
      state.openingOptions = action.payload;
    },
    setChatBotIsError: (state, action) => {
      state.isChatBotError = action.payload;
    }
  }
});

export const {
  setChatBotDetail,
  setOpeningMessages,
  setOpeningOptions,
  setChatBotIsError
} = chatbot.actions;

export default chatbot.reducer;

export const connectChatBotChannel = (payload) => {
  return async (dispatch, getState) => {
    const { channelId, onSuccess, onError } = payload;
    try {
      const subOrgId = getState().myInfo.subOrganizationId;
      new APIRequest()
        .post(
          replaceUrl(
            CONNECT_WEBSITE_CHATBOT,
            ['channelId', 'subOrgId'],
            [channelId, subOrgId]
          )
        )
        .then((res) => {
          if (onSuccess) {
            onSuccess(res.data);
          }
        });
    } catch (e) {
      console.log(e);
      if (onError) {
        onError();
      }
    }
  };
};

export const getChatbotDetails = (payload) => {
  return async (dispatch, getState) => {
    const { channelId, onSuccess, onFail } = payload;
    try {
      const subOrgId = getState().myInfo.subOrganizationId;
      new APIRequest()
        .get(
          replaceUrl(
            GET_CHATBOT_DETAILS,
            ['channelId', 'subOrgId'],
            [channelId, subOrgId]
          )
        )
        .then((res) => {
          if (res.status == 200) {
            dispatch(setChatBotDetail(res.data));
          }
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch(() => {
          if (onFail) {
            onFail();
          }
        });
    } catch (e) {
      if (onFail) {
        onFail();
      }
      console.log(e);
    }
  };
};

export const createOpeningMessages = (payload) => {
  return async (dispatch, getState) => {
    const { body, onSuccess, onError } = payload;
    try {
      const subOrgId = getState().myInfo.subOrganizationId;
      new APIRequest()
        .post(replaceUrl(CREATE_OPENING_MESSAGES, 'subOrgId', subOrgId), body)
        .then((res) => {
          if (res.status == 201) {
            if (onSuccess) {
              onSuccess(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
      if (onError) {
        onError();
      }
    }
  };
};

export const updateOpeningMessages = (payload) => {
  return async (dispatch, getState) => {
    const { body, id, onSuccess, onError } = payload;
    try {
      const subOrgId = getState().myInfo.subOrganizationId;
      new APIRequest()
        .patch(
          replaceUrl(
            UPDATE_OPENING_MESSAGES,
            ['subOrgId', 'openingMessageId'],
            [subOrgId, id]
          ),
          body
        )
        .then((res) => {
          if (res.status == 200) {
            if (onSuccess) {
              onSuccess(res.data);
            }
          }
        });
    } catch (e) {
      if (onError) {
        onError();
      }
      console.log(e);
    }
  };
};

export const deleteOpeningMessages = (payload) => {
  return async (dispatch, getState) => {
    const { id, onSuccess, onError } = payload;
    try {
      const subOrgId = getState().myInfo.subOrganizationId;
      new APIRequest()
        .delete(
          replaceUrl(
            UPDATE_OPENING_MESSAGES,
            ['subOrgId', 'openingMessageId'],
            [subOrgId, id]
          )
        )
        .then((res) => {
          if (res.status == 204) {
            if (onSuccess) {
              onSuccess();
            }
          }
        });
    } catch (e) {
      if (onError) {
        onError();
      }
      console.log(e);
    }
  };
};

export const updateMcqOptions = (payload) => {
  return async (dispatch, getState) => {
    const { body, id, onSuccess, onError } = payload;
    try {
      const subOrgId = getState().myInfo.subOrganizationId;
      new APIRequest()
        .patch(
          replaceUrl(
            UPDATE_OPTIONS,
            ['subOrgId', 'mcqOptionId'],
            [subOrgId, id]
          ),
          body
        )
        .then((res) => {
          if (res.status == 200) {
            if (onSuccess) {
              onSuccess(res.data);
            }
          }
        })
        .catch(() => {
          if (onError) {
            onError();
          }
        });
    } catch (e) {
      if (onError) {
        onError();
      }
      console.log(e);
    }
  };
};

export const updateChatBotDetails = (payload) => {
  return async (dispatch, getState) => {
    const { body, id, onSuccess, onError } = payload;
    try {
      const subOrgId = getState().myInfo.subOrganizationId;
      const { chatBotDetail } = getState().chatbot;
      new APIRequest()
        .patch(
          replaceUrl(
            GET_CHATBOT_DETAILS,
            ['subOrgId', 'channelId'],
            [subOrgId, id]
          ),
          body
        )
        .then((res) => {
          if (res.status == 200) {
            dispatch(
              setChatBotDetail({
                ...chatBotDetail,
                ...res.data
              })
            );
            if (onSuccess) {
              onSuccess(res.data);
            }
          }
        })
        .catch(() => {
          if (onError) {
            onError();
          }
        });
    } catch (e) {
      console.log(e);
      if (onError) {
        onError();
      }
    }
  };
};
