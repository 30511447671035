import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import modalCloseIcon from 'assets/icons/icon-modal-close.svg';
import { errorBoundaryWrap } from 'components/errorBoundary';

const Main = (props) => {
  const {
    open,
    children,
    className,
    containerStyle,
    mainStyle,
    containerClassName
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsOpen(open);
    }, 50);

    return () => {
      // Clean up the timeout to avoid memory leaks
      clearTimeout(timeout);
      setIsOpen(false);
    };
  }, [open]);

  return (
    <div
      className={`modal ${isOpen ? 'show' : ''} ${className}`}
      style={mainStyle}
    >
      <div
        className={`modal__container ${
          containerClassName && containerClassName
        }`}
        style={containerStyle}
      >
        {children}
      </div>
    </div>
  );
};

const Header = (props) => {
  const { title, onClose, style, hideClose, isLoading, closeIconClassName } =
    props;
  return (
    <div className="modal__header" style={style}>
      <h2 className="modal__heading pr-5">{title}</h2>
      {!hideClose && (
        <div
          className={`modal__close-icon ${
            closeIconClassName && closeIconClassName
          }`}
          onClick={isLoading ? () => {} : onClose}
          style={
            isLoading
              ? {
                  cursor: 'not-allowed'
                }
              : {}
          }
        >
          <img src={modalCloseIcon} alt="" style={{ width: 12, height: 12 }} />
        </div>
      )}
    </div>
  );
};

const Body = ({ className, id, children, style }) => {
  return (
    <div className={`modal__body px-8 py-6 ${className}`} id={id} style={style}>
      {children}
    </div>
  );
};

const Footer = ({ className, children, style }) => {
  return (
    <div className={`modal__body px-8 py-6 ${className}`} style={style}>
      {children}
    </div>
  );
};

const WrappedMain = errorBoundaryWrap(Main);
const WrappedHeader = errorBoundaryWrap(Header);
const WrappedBody = errorBoundaryWrap(Body);
const WrappedFooter = errorBoundaryWrap(Footer);

export default {
  Main: WrappedMain,
  Header: WrappedHeader,
  Body: WrappedBody,
  Footer: WrappedFooter
};

Main.propTypes = {
  open: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

Main.defaultProps = {
  className: '',
  mainStyle: {}
};

Header.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  style: PropTypes.object,
  hideClose: PropTypes.bool,
  isLoading: PropTypes.bool
};

Header.defaultProps = {
  title: 'title',
  onClose: () => {},
  hideClose: false,
  isLoading: false
};

Body.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

Body.defaultProps = {
  className: ''
};

Footer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

Footer.defaultProps = {
  className: ''
};
