import { createSlice } from '@reduxjs/toolkit';
import { replaceUrl } from 'utils/urlReplace';
import APIRequest from 'apiRequest';
import { GET_CARE_STACK_API_KEY } from 'urls/careStack';
import {
  CONNECT_CS_PHONES_CHANNEL,
  GET_CS_PHONES_RECORDING,
  GET_LIST_OF_CS_PHONE_NUMBERS,
  UPDATE_CS_PHONES_MAPPED_LOCATION,
  SYNC_CS_PHONE_NUMBER
} from 'urls/csPhones';
import { getAllConnectedChannels } from 'slices/channelsSlice';
import { setLocationStatus } from 'slices/careStack/slice';

export const csPhonesSlice = createSlice({
  name: 'csPhones',
  initialState: {
    apiKey: null,
    locations: []
  },
  reducers: {
    setApiKey: (state, action) => {
      state.apiKey = action.payload;
    },
    setLocations: (state, action) => {
      state.locations = action.payload;
    }
  }
});

export const { setApiKey, setLocations } = csPhonesSlice.actions;

export default csPhonesSlice.reducer;

export const getApiKey = (payload) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      const { channelId, onSuccess, onError } = payload;
      new APIRequest()
        .get(
          replaceUrl(
            GET_CARE_STACK_API_KEY,
            ['subOrgId', 'channelId'],
            [subOrgId, channelId]
          )
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(setApiKey(res?.data?.hash_key));
          }
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((e) => {
          console.log(e);
          if (onError) {
            onError();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// connect cs phones channel
export const connectCsPhonesChannel = (payload) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      const {
        api_key,
        vendorKey,
        accountKey,
        accountId,
        clientId,
        clientSecret,
        onSuccess,
        onError
      } = payload;
      const body = {
        sub_organization: subOrgId,
        api_key: api_key,
        csp_account_key: accountKey,
        csp_vendor_key: vendorKey,
        csp_account_id: accountId,
        csp_client_id: clientId,
        csp_client_secret: clientSecret
      };

      new APIRequest()
        .post(CONNECT_CS_PHONES_CHANNEL, body)
        .then((res) => {
          if (res.status == 201) {
            dispatch(getAllConnectedChannels({ subOrgId }));
            if (onSuccess) {
              onSuccess(res?.data);
            }
          }
        })
        .catch((e) => {
          if (onError) {
            onError();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// get cs phones call recording
export const getCsPhonesRecording = (payload) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      const { callId, onSuccess } = payload;
      new APIRequest()
        .get(
          replaceUrl(
            GET_CS_PHONES_RECORDING,
            ['callId', 'subOrgId'],
            [callId, subOrgId]
          )
        )
        .then((res) => {
          if (res.status === 200) {
            if (onSuccess) {
              onSuccess(res?.data?.signedUrl);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getListOfCsPhoneNumbers = (payload) => {
  return async (dispatch) => {
    const { channelId, onSuccess, onError } = payload;
    try {
      const query = {
        connected_channel_id: channelId
      };
      new APIRequest()
        .get(GET_LIST_OF_CS_PHONE_NUMBERS, query)
        .then((res) => {
          if (res.status === 200) {
            dispatch(setLocations(res?.data));
            if (onSuccess) {
              onSuccess();
            }
          }
        })
        .catch((e) => {
          console.log(e);
          if (onError) {
            onError();
          }
        });
    } catch (e) {
      console.log(e);
      if (onError) {
        onError();
      }
    }
  };
};

export const updateCsPhonesMappedLocation = (payload) => {
  return async (dispatch, getState) => {
    const {
      csPhones: { locations }
    } = getState();
    const { locationStatus } = getState().careStack;
    try {
      const { csPhoneId, body } = payload;
      new APIRequest()
        .put(
          replaceUrl(UPDATE_CS_PHONES_MAPPED_LOCATION, 'csPhoneId', csPhoneId),
          body
        )
        .then((res) => {
          if (res.status === 200) {
            let updatedLocations;
            if (body?.is_csphones) {
              updatedLocations = locations?.map((item) => {
                return item?.id === csPhoneId ? res?.data : item;
              });
            } else {
              updatedLocations = locationStatus?.map((item) => {
                return item?.id === csPhoneId ? res?.data : item;
              });
            }
            body?.is_csphones
              ? dispatch(setLocations(updatedLocations))
              : dispatch(setLocationStatus(updatedLocations));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const syncCsPhoneNumbers = (payload) => {
  return async (dispatch) => {
    const { channelId, onSuccess, onError } = payload;
    try {
      const query = {
        connected_channel_id: channelId
      };
      new APIRequest()
        .get(SYNC_CS_PHONE_NUMBER, query)
        .then((res) => {
          if (res.status === 200) {
            dispatch(setLocations(res?.data));
            if (onSuccess) {
              onSuccess();
            }
          }
        })
        .catch((e) => {
          if (onError) {
            onError();
          }
          console.log(e);
        });
    } catch (e) {
      console.log(e);
      if (onError) {
        onError();
      }
    }
  };
};
