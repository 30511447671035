/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-useless-escape */
import moment from 'moment';
// import { scrollTo } from 'react-scroll/modules/mixins/scroller';
import {
  isElementInContainerViewport,
  isInViewport,
  removeSignatureFromContent
} from 'utils/dom';
import { getDateInTimezone } from './dateFormat';
import DOMPurify from 'dompurify';
// import { useLockBodyScroll } from "@uidotdev/usehooks";

export function isEmpty(val) {
  // Stolen From: https://stackoverflow.com/a/28953167
  /*
  test results
  --------------
  []        true, empty array
  {}        true, empty object
  null      true
  undefined true
  ""        true, empty string
  ''        true, empty string
  0         false, number
  true      false, boolean
  false     false, boolean
  Date      false
  function  false
  */
  if (val === undefined) return true;
  if (
    typeof val == 'function' ||
    typeof val == 'number' ||
    typeof val == 'boolean' ||
    Object.prototype.toString.call(val) === '[object Date]'
  )
    return false;

  if (val == null || val.length === 0)
    // null or 0 length array
    return true;

  if (typeof val == 'object') if (Object.keys(val).length === 0) return true;

  if (typeof val === 'string') if (val.trim() === '') return true;

  return false;
}

export function getHashParams(hash) {
  return hash.split('&').reduce(function (res, item) {
    var parts = item.split('=');
    res[parts[0].replace(/#/g, '')] = parts[1];
    return res;
  }, {});
}

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const TIME_ZONE = (-1 * new Date().getTimezoneOffset()) / 60;

export function timeFormatter(givenDate, timezone, endDate) {
  if (endDate) {
    return moment(givenDate).from(endDate, true);
  }
  if (timezone) givenDate = getDateInTimezone(givenDate, timezone);
  let date = new Date(givenDate).getDate();
  let monthName = months[new Date(givenDate).getMonth()];
  return moment(givenDate)
    .add(TIME_ZONE, 'mintues')
    .fromNow(
      moment.updateLocale('en', {
        relativeTime: {
          future: 'in%s',
          past: '%s',
          s: 'Now',
          m: '%dm',
          mm: '%dm',
          h: '%dh',
          hh: '%dh',
          d: '%dd',
          dd: '%dd',
          M: `${monthName} ${date}`,
          MM: `${monthName} ${date}`,
          y: '%d y',
          yy: '%d y'
        }
      })
    );
}

export function getKiloBytes(bytes, decimals = 1) {
  if (!+bytes) return '0';
  if (bytes <= 1024) return 1;
  const k = 1024;
  // const dm = decimals < 0 ? 0 : decimals;
  return Math.ceil(parseFloat(bytes / Math.pow(k, 1)));
}

export function formatBytes(bytes, decimals = 1) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function isPhoneValid(value) {
  var pattern = /^[\+\d]?(?:(?!.*--)[\d-\s]*)$/;
  return pattern.test(value);
}

export function isPhoneValidWithoutPlus(value) {
  var pattern = /^[\d]?(?:(?!.*--)[\d-\s]*)$/;
  return pattern.test(value);
}

export function removeSpaceAndHyphensFromPhoneNumber(value) {
  return value.replace(/[- ]/g, '');
}

export function isNumberWithDecimal(value) {
  var pattern = /^\d*\.?\d*$/;
  return pattern.test(value);
}

export function isEditorEmpty(editor) {
  return isEmpty(sanatizeMessageBody(removeSignatureFromContent(editor)));
}

/**
 * Need to remove all spaces, new line characters and dom content from
 * the email body and the signature to check whether there are any content inside it
 */
export function isEditorOnlyContainsUserSignature(emailContent, userSignature) {
  let cleanMailText = emailContent
    ? emailContent.split('\n').join('').trim()
    : '';
  cleanMailText = cleanMailText.split('&nbsp;').join('');
  cleanMailText = cleanMailText
    ? cleanMailText.replace(/(<([^>]+)>)/gi, '')
    : '';
  cleanMailText = cleanMailText.split(' ').join('').trim();
  let cleanUserSignature = userSignature
    ? userSignature.replace(/(<([^>]+)>)/gi, '')
    : '';
  cleanUserSignature = cleanUserSignature.split('\n').join('').trim();
  cleanUserSignature = cleanUserSignature.split(' ').join('').trim();
  cleanUserSignature = cleanUserSignature.split('&nbsp;').join('').trim();
  let isInvalid = false;
  if (
    cleanMailText.trim() === cleanUserSignature.trim() &&
    (cleanMailText || cleanUserSignature)
  ) {
    isInvalid = true;
  }
  return isInvalid;
}

export function isEmailValid(value) {
  var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i; // matches Diana.hazalton@dentex.health
  return pattern.test(value);
}

export function sanatizeMessageBody(content) {
  var temp = document.createElement('div');
  temp.innerHTML = content;
  var sanitized = temp.textContent || temp.innerText;
  temp.remove();
  return sanitized;
}

export function getIcon(src, style) {
  if (src?.includes('https://') || src?.includes('.svg')) {
    return (
      <img data-testid="avatar-img" src={src} alt="avatar" style={style} />
    );
  } else {
    return (
      <div
        style={{ fontSize: '18px', ...style }}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(src) }}
      />
    );
  }
}

export function convertToHoursAndMinutes(totalMinutes) {
  if (!totalMinutes) {
    return 0;
  }
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);
  if (hours === 0) {
    return `${Math.round(minutes)} min`;
  } else {
    if (minutes === 0) {
      return `${hours} hour`;
    } else {
      return `${hours} hour ${Math.round(minutes)} min`;
    }
  }
}

export function handleScroll(childRef, parentRef) {
  setTimeout(() => {
    const child = childRef?.current;
    const parent = parentRef?.current;
    const distanceToTop = child?.offsetTop - (parent?.offsetTop + 50);
    parent.scrollTop = distanceToTop;
  }, 100);
}

export function handleScrollIntoview(id, block) {
  setTimeout(() => {
    var element = document.getElementById(id);
    element?.scrollIntoView({
      block: block,
      behavior: 'smooth'
    });
  }, 300);
}

/**
 * Scrolls a target element into view within a specified container element.
 * The container element will be scrolled to bring the target element into view.
 * This will not affect any parent or grandparent elements with scrollable content.
 * If the container element is not in the viewport, normal scroll into view will be called.
 */
export function scrollTargetElementIntoView(
  targetId,
  containerId,
  position = 'start'
) {
  const containerElement = document.getElementById(containerId);
  const targetElement = document.getElementById(targetId);

  if (containerElement && targetElement) {
    const targetRect = targetElement.getBoundingClientRect();
    const containerRect = containerElement.getBoundingClientRect();

    if (!isInViewport(containerElement)) {
      handleScrollIntoview(targetId, position);
      return;
    }
    // Check if the target element is already visible within the container
    const isTargetElementVisible = isElementInContainerViewport(
      targetElement,
      containerElement
    );
    if (isTargetElementVisible) return;

    const scrollTop =
      containerElement.scrollTop + (targetRect.top - containerRect.top);
    containerElement.scrollTo({
      top: scrollTop,
      behavior: 'smooth'
    });
  }
}

export function formatMessage(message) {
  if (message) {
    return message.replaceAll('*', '');
  }
  return '';
}

export function getInitialLetter(text) {
  if (!isEmpty(text) && typeof text === 'string') return text[0].toUpperCase();
  return '';
}

export function detectMob() {
  var toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  for (var i = 0; i < toMatch.length; i++) {
    if (navigator.userAgent.match(toMatch[i])) {
      return true; // Mobile device detected
    }
  }
  return false; // No mobile device detected
}
export function isMobile() {
  return window.innerWidth <= 500;
}

export function isMac() {
  return window.innerWidth <= 1680;
}

export function getObjectEntries(obj) {
  var ownProps = Object.keys(obj),
    i = ownProps.length,
    resArray = new Array(i);
  while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

  return resArray;
}

export function handlePlanType(data) {
  if (!data) return false;
  if (
    (typeof data === 'string' || data.length !== 0) &&
    typeof parseInt(data) === 'number'
  ) {
    return true;
  } else {
    return false;
  }
}

export function removeWhiteSpace(value) {
  if (value) {
    return value.replace(/\s+/g, ' ').trim();
  }
}

export function formatNumber(value) {
  if (value) {
    let addPlus = value.replace('(', '+');
    return addPlus.replace(')', '');
  }
}

export function formatWhatsAppTemplates(value, array) {
  if (!isEmpty(value) && !isEmpty(array)) {
    let tempValue = value;
    for (let i = 0; i <= array.length; i++) {
      tempValue = tempValue.replace(`{{${i + 1}}}`, array[i]);
    }
    return tempValue;
  }
}

export function isInt(value) {
  var pattern = /^\d*\.?\d*$/;
  return pattern.test(value);
}

export function JSONparse(value) {
  if (value) return JSON.parse(value);
  return null;
}

export function isFirstCharDigit(value) {
  if (value && /\d/.test(value[0])) {
    return true;
  } else return false;
}

export function extractTextFromHtml(message) {
  if (message) {
    const regex1 =
      /<div class="pre">(?:<strong>&nbsp;<\/strong>|&nbsp;)<\/div>/gi;
    const msg = message.replace(regex1, '\n\n');
    let temp = document.createElement('div');
    temp.innerHTML = msg;
    let sanitized = temp.textContent || temp.innerText;
    return sanitized;
  }
  return '';
}

// sms editor is not supporting the above fucntion extractTextFromHtml
// so introduced new regex for sms editor
export function extractTextFromHtmlSmsEditor(message) {
  if (message) {
    const regex1 = /<div class="pre">(.*?)<\/div>/gi;
    const msg = message.replace(regex1, (match, p1) => `${p1}\n`);
    let temp = document.createElement('div');
    temp.innerHTML = msg;
    let sanitized = temp.textContent || temp.innerText;
    return sanitized;
  }
  return '';
}

// Utility debounce function
export function debounce(fn, delay) {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}
