import { ErrorBoundary, withErrorBoundary } from 'react-error-boundary';

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert" className="bg-white">
      <p>Something went wrong:</p>
      {/* <pre>{error.message}</pre> */}
      <button
        onClick={() => {
          resetErrorBoundary();
          window.location.reload();
        }}
      >
        Try again
      </button>
    </div>
  );
}

function GlobalErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert" className="flex justify-center items-center h-screen">
      <div className="bg-white rounded-xl p-10">
        <p className="text-xl">Something went wrong: we are fixing it</p>
        <div className="flex justify-center mt-6">
          <button
            className="btn btn--primary"
            onClick={() => {
              resetErrorBoundary();
              window.location.reload();
            }}
          >
            Try again
          </button>
        </div>
      </div>
    </div>
  );
}

function ErrorBoundaryWrap({ children }) {
  return (
    <ErrorBoundary FallbackComponent={GlobalErrorFallback}>
      {children}
    </ErrorBoundary>
  );
}

export function errorBoundaryWrap(component, config = {}) {
  if (typeof component !== 'function' && typeof component !== 'object') {
    console.error('Invalid component passed to errorBoundaryWrap:', component);
    throw new Error('Invalid component');
  }
  return withErrorBoundary(component, {
    FallbackComponent: ErrorFallback
  });
}

export default ErrorBoundaryWrap;
