/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import choices from 'choices';
import {
  GET_ALL_QUESTIONS,
  REMOVE_QUESTION,
  GET_ALL_ANSWERS,
  UPDATE_ANSWER,
  UPDATE_QUESTION,
  UPDATE_CHOICE_SET,
  UPDATE_CONTACT_DETAILS
} from 'urls';
import { replaceUrl } from 'utils/urlReplace';
import { setActiveLead } from 'slices/conversationSlice';
import { updateLeadInList } from 'slices/eboxLead';
import {
  setStageLeads,
  getAllBoxes,
  setReplyLaterLeads
} from 'slices/boxes/slice';

const { REPLY_LATER, NEW, VIEWED } = choices.LeadStatusChoices;

export const questionSlice = createSlice({
  name: 'questions',
  initialState: {
    isShowQuestionsToAdd: false,
    selectedQuestion: null,
    questionsData: [],
    isShowQuestionForm: false,
    AllAnswer: {},
    isLoading: false,
    isEdit: false,
    editedData: {},
    showNewFieldForm: false,
    selectedField: null
  },
  reducers: {
    setSelectedQuestion: (state, action) => {
      state.selectedQuestion = action.payload;
    },
    setQuestionsData: (state, action) => {
      state.questionsData = action.payload;
    },
    setIsShowQuestionsToAdd: (state, action) => {
      state.isShowQuestionsToAdd = action.payload;
    },
    setIsShowQuestionForm: (state, action) => {
      state.isShowQuestionForm = action.payload;
    },
    setAllAnswer: (state, action) => {
      state.AllAnswer = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsEdit: (state, action) => {
      state.isEdit = action.payload;
    },
    setEditedData: (state, action) => {
      state.editedData = action.payload;
    },
    setShowNewFieldForm: (state, action) => {
      state.showNewFieldForm = action.payload;
    },
    setSelectedField: (state, action) => {
      state.selectedField = action.payload;
    }
  }
});
export const {
  setSelectedQuestion,
  setQuestionsData,
  setIsShowQuestionsToAdd,
  setIsShowQuestionForm,
  setAllAnswer,
  setIsLoading,
  setIsEdit,
  setEditedData,
  setShowNewFieldForm,
  setSelectedField
} = questionSlice.actions;

export default questionSlice.reducer;

export const getQuestionsList = (payload = {}, callback) => {
  const { subOrgId, query = {} } = payload;
  return async (dispatch, getState) => {
    const {
      boxes: { currentBoxId }
    } = getState();
    let Query = {
      ...query,
      box: currentBoxId
    };
    try {
      new APIRequest()
        .get(GET_ALL_QUESTIONS, {
          sub_organization: subOrgId,
          ...Query
        })
        .then((resp) => {
          dispatch(setQuestionsData(resp.data));
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const AddQuestion = (payload) => {
  return async () => {
    try {
      const { body, callback } = payload;
      new APIRequest().post(GET_ALL_QUESTIONS, body).then((resp) => {
        if (resp.status === 201) {
          if (callback) {
            callback(resp.data);
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateQuestion = (payload) => {
  return async (dispatch, getState) => {
    try {
      const { body, questionId, callback } = payload;
      const sub_organization = getState().myInfo.subOrganizationId;
      new APIRequest()
        .patch(replaceUrl(UPDATE_QUESTION, 'questId', questionId), body)
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(getQuestionsList({ subOrgId: sub_organization }));
            if (callback) {
              callback(resp.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const DeleteQuestion = (payload) => {
  return async (dispatch) => {
    try {
      const { questionId, callback, box_id } = payload;
      new APIRequest()
        .delete(replaceUrl(REMOVE_QUESTION, 'questId', questionId))
        .then((resp) => {
          if (resp.status === 200) {
            if (box_id) {
              dispatch(getAllBoxes());
            }
            if (callback) {
              callback(questionId);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getAllAnswer = (payload, callback) => {
  return async (dispatch) => {
    try {
      const { leadId, query = {} } = payload;
      new APIRequest()
        .get(GET_ALL_ANSWERS, {
          lead: leadId,
          ...query
        })
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(setAllAnswer(resp.data));
            if (callback) {
              callback();
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const createAnswer = (payload, onSuccess) => {
  return async (dispatch) => {
    try {
      const { body, callback, stageId } = payload;
      new APIRequest().post(GET_ALL_ANSWERS, body).then((resp) => {
        if (resp.status === 201) {
          dispatch(getAllAnswer({ leadId: body?.lead }, onSuccess));
          if (stageId) {
            dispatch(
              updateLeadPostContractValueUpdate({
                data: resp.data,
                stageId,
                leadId: body?.lead
              })
            );
          }
          if (callback) {
            callback(resp.data);
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateAnswer = (payload, onSuccess) => {
  return async (dispatch) => {
    try {
      const { body, answerId, callback, stageId } = payload;
      new APIRequest()
        .patch(replaceUrl(UPDATE_ANSWER, 'answerId', answerId), body)
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(getAllAnswer({ leadId: body?.lead }, onSuccess));
            if (stageId) {
              dispatch(
                updateLeadPostContractValueUpdate({
                  data: resp.data,
                  stageId,
                  leadId: body?.lead
                })
              );
            }
            if (callback) {
              callback(resp.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

const getUpdatedList = ({ leads, leadIndex, data }) => {
  const currentLead = { ...leads[leadIndex] };
  const answerIndex = currentLead.answer_set.findIndex(
    (answer) => answer.id === data.id
  );
  const answerSet = [...currentLead.answer_set];
  if (answerIndex >= 0) {
    answerSet.splice(answerIndex, 1, data);
  } else {
    answerSet.unshift(data);
  }
  const updatedCurrentLead = {
    ...currentLead,
    answer_set: answerSet
  };
  leads[leadIndex] = updatedCurrentLead;
  return leads;
};

// when contract value is updated this function updates the lead in the list
const updateLeadPostContractValueUpdate = (payload) => {
  const { stageId, leadId, data } = payload;
  return async (dispatch, getState) => {
    const {
      boxes: { stageLeads, currentBoxCardList, replyLaterLeads }
    } = getState();
    if (stageId) {
      const leadsList = stageLeads[stageId];
      const replyLaterList = replyLaterLeads[stageId];
      if (currentBoxCardList == REPLY_LATER) {
        if (replyLaterList.results) {
          const leadIndex = replyLaterList.results?.findIndex(
            (lead) => lead.id === leadId
          );
          if (leadIndex >= 0) {
            const leads = [...replyLaterList.results];
            const updatedLeads = getUpdatedList({ leads, leadIndex, data });
            const updatedList = {
              ...replyLaterLeads,
              [stageId]: {
                ...replyLaterList,
                results: updatedLeads
              }
            };
            dispatch(setReplyLaterLeads(updatedList));
          }
        }
      }
      if (currentBoxCardList == NEW || currentBoxCardList == VIEWED) {
        if (leadsList?.results) {
          const leadIndex = leadsList.results?.findIndex(
            (lead) => lead.id === leadId
          );
          if (leadIndex >= 0) {
            const leads = [...leadsList.results];
            const updatedLeads = getUpdatedList({ leads, leadIndex, data });
            const updatedList = {
              ...stageLeads,
              [stageId]: {
                ...leadsList,
                results: updatedLeads
              }
            };
            dispatch(setStageLeads(updatedList));
          }
        }
      }
    }
  };
};

export const updateChoiceSet = (payload) => {
  return async (dispatch, getState) => {
    try {
      const { body, choiceId, callback } = payload;
      const sub_organization = getState().myInfo.subOrganizationId;
      new APIRequest()
        .put(replaceUrl(UPDATE_CHOICE_SET, 'choiceId', choiceId), body)
        .then((resp) => {
          if (resp.status === 201) {
            if (callback) {
              callback();
              dispatch(getQuestionsList({ subOrgId: sub_organization }));
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateContactDetails = (payload) => {
  return async (dispatch, getState) => {
    try {
      const {
        leadConversation: { activeLead }
      } = getState();
      const { body, leadId, callback } = payload;
      new APIRequest()
        .put(replaceUrl(UPDATE_CONTACT_DETAILS, 'leadId', leadId), body)
        .then((resp) => {
          if (resp.status === 201) {
            dispatch(setActiveLead({ ...activeLead, ...resp.data }));
            dispatch(updateLeadInList());
            if (callback) {
              callback();
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
