/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import {
  GET_MY_BOX_PROVIDED_EMAIL,
  GET_EMAIL_VERIFICATION_PROVIDERS,
  CREATE_CONNECTED_CHANNEL,
  ADD_EMAIL_ADDRESS,
  SEND_BLOCKED_ALLOWED_FORWARDED_EMAIL
} from 'urls/emailAddress';
import { replaceUrl } from 'utils/urlReplace';
import APIRequest from 'apiRequest';
import { UPDATE_CHANNEL_NAME } from 'urls';

const initialState = {
  box_provided_email: null,
  email_provider: null,
  forwarded_email_list: []
};

const reducers = {
  setBoxProvidedEmail: (state, action) => {
    state.box_provided_email = action.payload;
  },
  setEmailProvider: (state, action) => {
    state.email_provider = action.payload;
  },
  setForwardedEmailList: (state, action) => {
    state.forwarded_email_list = action.payload;
  }
};

export const emailAddress = createSlice({
  name: 'emailAddress',
  initialState: initialState,
  reducers: reducers
});

export const { setBoxProvidedEmail, setEmailProvider, setForwardedEmailList } =
  emailAddress.actions;

export const getProvidedEmail = () => {
  return async (dispatch) => {
    await new APIRequest().get(GET_MY_BOX_PROVIDED_EMAIL).then((res) => {
      dispatch(setBoxProvidedEmail(res.data));
    });
  };
};

export const getEmailVerificationProvider = () => {
  return async (dispatch) => {
    await new APIRequest().get(GET_EMAIL_VERIFICATION_PROVIDERS).then((res) => {
      dispatch(setEmailProvider(res.data));
    });
  };
};
export const connectToEmailForwardingChannel = (payload, onSuccess) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      await new APIRequest()
        .post(
          replaceUrl(CREATE_CONNECTED_CHANNEL, 'subOrgId', subOrgId),
          payload
        )
        .then((res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateEmailForwardingChannelName = (payload, onSuccess) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const { channel, channel_name } = payload;
    try {
      await new APIRequest()
        .patch(
          replaceUrl(
            UPDATE_CHANNEL_NAME,
            ['subOrgId', 'channelId'],
            [subOrgId, channel]
          ),
          { name: channel_name }
        )
        .then(() => {
          if (onSuccess) {
            onSuccess();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const addEmailAddress = (payload, onSuccess) => {
  return async () => {
    const { connected_channel, email, name_of_the_email_address, location } =
      payload;
    const data = {
      connected_channel: connected_channel,
      email: email,
      name_of_the_email_address: name_of_the_email_address,
      location: location
    };
    try {
      await new APIRequest()
        .post(
          replaceUrl(ADD_EMAIL_ADDRESS, 'channelId', data?.connected_channel),
          data
        )
        .then(() => {
          if (onSuccess) {
            onSuccess();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// to update the location for email address
export const updateEmailAddress = (payload, onSuccess) => {
  const { data, channelId, forwardedEmailId } = payload;
  return async () => {
    try {
      await new APIRequest()
        .patch(
          replaceUrl(
            SEND_BLOCKED_ALLOWED_FORWARDED_EMAIL,
            ['channelId', 'forwardedEmailId'],
            [channelId, forwardedEmailId]
          ),
          data
        )
        .then(() => {
          if (onSuccess) {
            onSuccess();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getForwardedEmailList = (channelId) => {
  return async (dispatch) => {
    await new APIRequest()
      .get(replaceUrl(ADD_EMAIL_ADDRESS, 'channelId', channelId))
      .then((res) => {
        dispatch(setForwardedEmailList(res?.data));
      });
  };
};

export const sendBlockedOrForwardedEmails = (payload, onSuccess, onError) => {
  return async () => {
    const { channelId, forwardedEmailId, payloadData } = payload;
    try {
      await new APIRequest()
        .patch(
          replaceUrl(
            SEND_BLOCKED_ALLOWED_FORWARDED_EMAIL,
            ['channelId', 'forwardedEmailId'],
            [channelId, forwardedEmailId]
          ),
          payloadData
        )
        .then((res) => {
          if (onSuccess) {
            onSuccess();
          }
        });
    } catch (e) {
      if (onError) {
        onError();
      }
      console.log(e);
    }
  };
};
export const deleteForwardedEmails = (payload, onSuccess) => {
  return async () => {
    const { channelId, forwardedEmailId } = payload;
    try {
      await new APIRequest()
        .delete(
          replaceUrl(
            SEND_BLOCKED_ALLOWED_FORWARDED_EMAIL,
            ['channelId', 'forwardedEmailId'],
            [channelId, forwardedEmailId]
          )
        )
        .then(() => {
          if (onSuccess) {
            onSuccess();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export default emailAddress.reducer;
