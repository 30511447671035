const { REACT_APP_BASE_URL: BASE_API_URL } = process.env;

// api to create,update or delete practitioner
export const ADD_OR_EDIT_PRACTIONER = `${BASE_API_URL}/leads/sub_organization/:subOrgId/practitioner`;

// api to get the practitioner
export const GET_PRACTITIONER = `${BASE_API_URL}/leads/sub_organization/:subOrgId/get_practitioners`;

// hide help text from practitioner listing
export const HIDE_HELP_TEXT_PRACTITIONER = `${BASE_API_URL}/users/update_user_info/:userId`;

//assign practitioner api
export const ASSIGN_PRACTITIONER_TO_LEAD = `${BASE_API_URL}/leads/sub_organization/:subOrgId/assign_practitioner`;
