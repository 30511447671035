/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import {
  GET_LEADS,
  LEAD_UPDATE_STATUS,
  GET_TOTAL_LEADS,
  GET_LIST_ENQUIRIES_WITH_TASKS
} from 'urls';
import { MOVE_TO_BOX } from 'urls/boxes';
import { HIDE_EBOX_CHANNEL_INTRODUCTION_VIDEO } from 'urls';
import { replaceUrl } from 'utils/urlReplace';
import _ from 'lodash';
import { isEmpty } from 'utils/utils';
import APIRequest from 'apiRequest';
import {
  ENQUIRY_TYPE,
  REPLY,
  TASK,
  WHOEVER__IS_AVAILABLE
} from 'utils/constants';
import { updateShowVideoPreference } from 'slices/myInfoSlice';
import choices from 'choices';

import { setActiveLead } from './conversationSlice';
import { getAllTasks, getTaskEnquiryCount } from './taskThingsToDo/slice';
import { filterTask } from 'modules/enquirybox/helper/lead';

const { REPLY_LATER, NEW, VIEWED, REPLY_SENT, TASK_ADDED, NOTE_ADDED } =
  choices.LeadStatusChoices;

const REPLYLATER = REPLY_LATER;
const SEEN = VIEWED;

export const eboxLeads = createSlice({
  name: 'leads',
  initialState: {
    currentCardList: 0,
    activeNewLead: null, // only active when the lead is clicked in the new section
    previousActiveNewLead: null, // will be set post the active new lead along with data
    previousClickedLead: null, // this is used to check the data of lead that was previously clicked
    previousClickedLeadList: 0,
    unReadLeads: [],
    readLeads: [],
    replyLaterLeads: [],
    totalLeadCount: 0,
    isReplyLater: false,
    isMovedSuccess: false,
    showInboxActions: true,
    showContactModal: false,
    editorActiveTab: REPLY,
    leadTasks: [],
    allLeads: [],
    pageNo: 1,
    replyLaterCount: 0,
    totalPages: 0,
    totalNewSeenLeadCount: 0,
    nextPage: 1,
    isRefetchLastLeadPage: false,
    clickedLeadIndex: null,
    totalReplyLaterCount: 0,
    replyLaterPageNo: 1,
    replyLaterNextPage: 1,
    replyLaterTotalPages: 0,
    replyLaterExcludedLeadId: [],
    newSeenExcludedLeadId: [],
    thingsToDoLocation: null,
    locationBasedUsers: []
  },
  reducers: {
    setCurrentCardList: (state, action) => {
      state.currentCardList = action.payload;
    },
    setThingsToDoLocation: (state, action) => {
      state.thingsToDoLocation = action.payload;
    },
    setLocationBasedUsers: (state, action) => {
      state.locationBasedUsers = action.payload;
    },
    setActiveNewLead: (state, action) => {
      state.activeNewLead = action.payload;
    },
    setPreviousActiveNewLead: (state, action) => {
      state.previousActiveNewLead = action.payload;
    },
    setPreviousClickedLead: (state, action) => {
      state.previousClickedLead = action.payload;
    },
    setPreviousClickedLeadList: (state, action) => {
      state.previousClickedLeadList = action.payload;
    },
    setUnReadLeads: (state, action) => {
      state.unReadLeads = action.payload;
    },
    setReadLeads: (state, action) => {
      state.readLeads = action.payload;
    },
    setReplyLaterLeads: (state, action) => {
      state.replyLaterLeads = action.payload;
    },
    setIsReplyLater: (state, action) => {
      state.isReplyLater = action.payload;
    },
    setIsMovedSuccess: (state, action) => {
      state.isMovedSuccess = action.payload;
    },
    setShowInboxActions: (state, action) => {
      state.showInboxActions = action.payload;
    },
    setEditorActiveTab: (state, action) => {
      state.editorActiveTab = action.payload;
    },
    setShowContactModal: (state, action) => {
      state.showContactModal = action.payload;
    },
    setTotalLeadCount: (state, action) => {
      state.totalLeadCount = action.payload;
    },
    setLeadTasks: (state, action) => {
      state.leadTasks = action.payload;
    },
    setAllLeads: (state, action) => {
      state.allLeads = action.payload;
    },
    setPageNo: (state, action) => {
      state.pageNo = action.payload;
    },
    setReplyLaterCount: (state, action) => {
      state.replyLaterCount = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setTotalNewSeenLeadCount: (state, action) => {
      state.totalNewSeenLeadCount = action.payload;
    },
    setNextPage: (state, action) => {
      state.nextPage = action.payload;
    },
    setIsRefetchLastLeadPage: (state, action) => {
      state.isRefetchLastLeadPage = action.payload;
    },
    setClickedLeadIndex: (state, action) => {
      state.clickedLeadIndex = action.payload;
    },
    setTotalReplyLaterCount: (state, action) => {
      state.totalReplyLaterCount = action.payload;
    },
    setReplyLaterNextPage: (state, action) => {
      state.replyLaterNextPage = action.payload;
    },
    setReplyLaterExcludedLeadId: (state, action) => {
      state.replyLaterExcludedLeadId = action.payload;
    },
    setNewSeenExcludedLeadId: (state, action) => {
      state.newSeenExcludedLeadId = action.payload;
    },
    setReplyLaterPageNo: (state, action) => {
      state.replyLaterPageNo = action.payload;
    },
    setReplyLaterTotalPages: (state, action) => {
      state.replyLaterTotalPages = action.payload;
    }
  }
});

export const {
  setUnReadLeads,
  setReadLeads,
  setReplyLaterLeads,
  setIsReplyLater,
  setIsMovedSuccess,
  setActiveNewLead,
  setPreviousActiveNewLead,
  setPreviousClickedLead,
  setPreviousClickedLeadList,
  setShowInboxActions,
  setIsFooterExpanded,
  setEditorActiveTab,
  setShowContactModal,
  setTotalLeadCount,
  setCurrentCardList,
  setLeadTasks,
  setAllLeads,
  setPageNo,
  setReplyLaterCount,
  setTotalPages,
  setTotalNewSeenLeadCount,
  setNextPage,
  setIsRefetchLastLeadPage,
  setClickedLeadIndex,
  setTotalReplyLaterCount,
  setReplyLaterNextPage,
  setReplyLaterExcludedLeadId,
  setNewSeenExcludedLeadId,
  setReplyLaterPageNo,
  setReplyLaterTotalPages,
  setThingsToDoLocation,
  setLocationBasedUsers
} = eboxLeads.actions;

export const getTotalOrganisationLeads = () => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      await new APIRequest()
        .get(GET_TOTAL_LEADS, {
          sub_organization_id: subOrgId
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setTotalLeadCount(res?.data?.lead_count));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getReadLeads = (payload = {}, successCb) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const channel_id = getState().leadsFilter.channelId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const thingsToDoLocation = getState().leads.thingsToDoLocation;
    const params = {
      filter_by: 2,
      channel: channel_id,
      location_ids: thingsToDoLocation?.id,
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id
    };
    try {
      const { query = {} } = payload;
      await new APIRequest()
        .get(replaceUrl(GET_LEADS, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setReadLeads(res.data));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getUnReadLeads = (payload = {}, successCb) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const channel_id = getState().leadsFilter.channelId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const thingsToDoLocation = getState().leads.thingsToDoLocation;
    const params = {
      filter_by: 1,
      channel: channel_id,
      location_ids: thingsToDoLocation?.id,
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id
    };
    try {
      const { query = {} } = payload;
      await new APIRequest()
        .get(replaceUrl(GET_LEADS, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setTotalLeadCount(res.data?.results.length));
            dispatch(setUnReadLeads(res.data));
            dispatch(setActiveNewLead(null));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getReplyLaterLeads = (payload = {}, successCb) => {
  return async (dispatch, getState) => {
    const { pageNo } = payload;
    const pageNumber = pageNo;
    const thingsToDoLocation = getState().leads.thingsToDoLocation;
    const subOrgId = getState().myInfo.subOrganizationId;
    const channel_id = getState().leadsFilter.channelId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const params = {
      page: pageNumber,
      filter_by: 3,
      channel: channel_id,
      location_ids: thingsToDoLocation?.id,
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id
    };
    try {
      const { query = {} } = payload;
      await new APIRequest()
        .get(replaceUrl(GET_LEADS, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setReplyLaterLeads(res.data));
            dispatch(setTotalReplyLaterCount(res.data.results?.length));
            dispatch(setReplyLaterCount(res.data.reply_later_count));
            dispatch(setReplyLaterPageNo(res.data.current_page));
            dispatch(setReplyLaterTotalPages(res.data.total_pages));
            dispatch(setReplyLaterNextPage(res.data.next));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getReplyLaterLeadsByPageNumber = (payload = {}, successCb) => {
  return async (dispatch, getState) => {
    const { pageNo } = payload;
    const thingsToDoLocation = getState().leads.thingsToDoLocation;
    const subOrgId = getState().myInfo.subOrganizationId;
    const channel_id = getState().leadsFilter.channelId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const previousLeads = getState().leads.replyLaterLeads;
    const pageNumber = pageNo;
    const params = {
      page: pageNumber,
      filter_by: 3,
      location_ids: thingsToDoLocation?.id,
      channel: channel_id,
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id
    };
    try {
      const { query = {} } = payload;
      await new APIRequest()
        .get(replaceUrl(GET_LEADS, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            const totalCount =
              previousLeads.results.length + res.data?.results.length;
            dispatch(setTotalReplyLaterCount(totalCount));
            dispatch(setReplyLaterCount(res.data.reply_later_count));
            dispatch(setReplyLaterPageNo(res.data.current_page));
            dispatch(setReplyLaterTotalPages(res.data.total_pages));
            dispatch(setReplyLaterNextPage(res.data.next));
            dispatch(setActiveNewLead(null));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const onLoadMoreReplyLaterLeads = (payload = {}, successCb) => {
  return async (dispatch, getState) => {
    const { pageNo } = payload;
    const pageNumber = pageNo;
    const thingsToDoLocation = getState().leads.thingsToDoLocation;
    const subOrgId = getState().myInfo.subOrganizationId;
    const channel_id = getState().leadsFilter.channelId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const previousLeads = getState().leads.replyLaterLeads;
    const replyLaterExcludedLeadId = getState().leads.replyLaterExcludedLeadId;
    const params = {
      page: pageNumber,
      filter_by: 3,
      location_ids: thingsToDoLocation?.id,
      channel: channel_id,
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id
    };

    if (!isEmpty(replyLaterExcludedLeadId)) {
      params['exclude_lead_ids'] = replyLaterExcludedLeadId;
    }

    try {
      const { query = {} } = payload;
      await new APIRequest()
        .get(replaceUrl(GET_LEADS, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            const totalCount =
              previousLeads.results.length + res.data?.results.length;
            dispatch(setTotalReplyLaterCount(totalCount));
            dispatch(setReplyLaterPageNo(res.data.current_page));
            dispatch(setReplyLaterTotalPages(res.data.total_pages));
            dispatch(setReplyLaterNextPage(res.data.next));
            dispatch(
              setReplyLaterLeads({
                current_page: res.data.current_page,
                next: res.data.next,
                previous: res.data.previous,
                reply_later_count: res.data.reply_later_count,
                results: [...previousLeads.results, ...res.data.results],
                total_count: res.data.total_count,
                total_pages: res.data.total_pages
              })
            );
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getReplyLaterAfterMoving = (payload = {}, successCb) => {
  return async (dispatch, getState) => {
    const { pageNo, exclude_lead_ids } = payload;
    const pageNumber = pageNo;
    const thingsToDoLocation = getState().leads.thingsToDoLocation;
    const subOrgId = getState().myInfo.subOrganizationId;
    const channel_id = getState().leadsFilter.channelId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const previousReplyLaterExcludedIds =
      getState().leads.replyLaterExcludedLeadId;
    const allIds = [...previousReplyLaterExcludedIds, exclude_lead_ids];
    const params = {
      page: pageNumber,
      filter_by: 3,
      channel: channel_id,
      location_ids: thingsToDoLocation?.id,
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id,
      exclude_lead_ids: !isEmpty(previousReplyLaterExcludedIds)
        ? allIds
        : [exclude_lead_ids]
    };
    dispatch(
      setReplyLaterExcludedLeadId([
        ...previousReplyLaterExcludedIds,
        exclude_lead_ids
      ])
    );
    try {
      const { query = {} } = payload;
      await new APIRequest()
        .get(replaceUrl(GET_LEADS, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setTotalReplyLaterCount(res.data.results?.length));
            dispatch(setReplyLaterPageNo(res.data.current_page));
            dispatch(setReplyLaterTotalPages(res.data.total_pages));
            dispatch(setReplyLaterNextPage(res.data.next));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getNewSeenLeads = (payload = {}, successCb) => {
  return async (dispatch, getState) => {
    const { pageNo } = payload;
    const thingsToDoLocation = getState().leads.thingsToDoLocation;
    const subOrgId = getState().myInfo.subOrganizationId;
    const channel_id = getState().leadsFilter.channelId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const pageNumber = pageNo;
    const params = {
      page: pageNumber,
      filter_by: 1,
      location_ids: thingsToDoLocation?.id,
      channel: channel_id,
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id
    };
    try {
      const { query = {} } = payload;
      await new APIRequest()
        .get(replaceUrl(GET_LEADS, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setTotalLeadCount(res.data?.results.length));
            dispatch(setAllLeads(res.data));
            dispatch(setReplyLaterCount(res.data.reply_later_count));
            dispatch(setPageNo(res.data.current_page));
            dispatch(setTotalPages(res.data.total_pages));
            dispatch(setTotalNewSeenLeadCount(res.data.total_count));
            dispatch(setNextPage(res.data.next));
            dispatch(setActiveNewLead(null));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getNewSeenLeadsAfterMoving = (payload = {}, successCb) => {
  return async (dispatch, getState) => {
    const { pageNo, exclude_lead_ids } = payload;
    const thingsToDoLocation = getState().leads.thingsToDoLocation;
    const subOrgId = getState().myInfo.subOrganizationId;
    const channel_id = getState().leadsFilter.channelId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const previousNewSeenExcludedLeadIds =
      getState().leads.newSeenExcludedLeadId;
    const pageNumber = pageNo;
    const allIds = [...previousNewSeenExcludedLeadIds, exclude_lead_ids];
    const params = {
      page: pageNumber,
      filter_by: 1,
      location_ids: thingsToDoLocation?.id,
      channel: channel_id,
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id,
      exclude_lead_ids: !isEmpty(previousNewSeenExcludedLeadIds)
        ? allIds
        : [exclude_lead_ids]
    };
    dispatch(
      setNewSeenExcludedLeadId([
        ...previousNewSeenExcludedLeadIds,
        exclude_lead_ids
      ])
    );
    try {
      const { query = {} } = payload;
      await new APIRequest()
        .get(replaceUrl(GET_LEADS, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setTotalLeadCount(res.data?.results.length));
            dispatch(setReplyLaterCount(res.data.reply_later_count));
            dispatch(setPageNo(res.data.current_page));
            dispatch(setTotalPages(res.data.total_pages));
            dispatch(setTotalNewSeenLeadCount(res.data.total_count));
            dispatch(setNextPage(res.data.next));
            dispatch(setActiveNewLead(null));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const onLoadMoreNewSeenLeads = (payload = {}, successCb) => {
  return async (dispatch, getState) => {
    const { pageNo } = payload;
    const thingsToDoLocation = getState().leads.thingsToDoLocation;
    const subOrgId = getState().myInfo.subOrganizationId;
    const channel_id = getState().leadsFilter.channelId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const previousLeads = getState().leads.allLeads;
    const newSeenExcludedLeadId = getState().leads.newSeenExcludedLeadId;
    const pageNumber = pageNo;
    const params = {
      page: pageNumber,
      filter_by: 1,
      location_ids: thingsToDoLocation?.id,
      channel: channel_id,
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id
    };
    if (!isEmpty(newSeenExcludedLeadId)) {
      params['exclude_lead_ids'] = newSeenExcludedLeadId;
    }
    try {
      const { query = {} } = payload;
      await new APIRequest()
        .get(replaceUrl(GET_LEADS, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            const totalCount =
              previousLeads.results.length + res.data?.results.length;
            dispatch(setTotalLeadCount(totalCount));
            dispatch(
              setAllLeads({
                current_page: res.data.current_page,
                next: res.data.next,
                previous: res.data.previous,
                reply_later_count: res.data.reply_later_count,
                results: [...previousLeads.results, ...res.data.results],
                total_count: res.data.total_count,
                total_pages: res.data.total_pages
              })
            );
            dispatch(setReplyLaterCount(res.data.reply_later_count));
            dispatch(setPageNo(res.data.current_page));
            dispatch(setTotalPages(res.data.total_pages));
            dispatch(setTotalNewSeenLeadCount(res.data.total_count));
            dispatch(setNextPage(res.data.next));
            dispatch(setActiveNewLead(null));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getNewSeenLeadsByPageNumber = (payload = {}, successCb) => {
  return async (dispatch, getState) => {
    const { pageNo } = payload;
    const thingsToDoLocation = getState().leads.thingsToDoLocation;
    const subOrgId = getState().myInfo.subOrganizationId;
    const channel_id = getState().leadsFilter.channelId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const previousLeads = getState().leads.allLeads;
    const pageNumber = pageNo;
    const params = {
      page: pageNumber,
      filter_by: 1,
      location_ids: thingsToDoLocation?.id,
      channel: channel_id,
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id
    };
    try {
      const { query = {} } = payload;
      await new APIRequest()
        .get(replaceUrl(GET_LEADS, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            const totalCount =
              previousLeads.results.length + res.data?.results.length;
            dispatch(setTotalLeadCount(totalCount));
            dispatch(setPageNo(res.data.current_page));
            dispatch(setTotalPages(res.data.total_pages));
            dispatch(setTotalNewSeenLeadCount(res.data.total_count));
            dispatch(setNextPage(res.data.next));
            dispatch(setActiveNewLead(null));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const onCardMoveUpdateStatus = (payload, successCb) => {
  return async () => {
    try {
      const { body } = payload;
      await new APIRequest()
        .post(replaceUrl(LEAD_UPDATE_STATUS), body)
        .then((res) => {
          if (res.status === 200) {
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const shiftTheLeadPostClick = () => {
  return async (dispatch, getState) => {
    const {
      replyLaterLeads,
      previousClickedLead,
      previousClickedLeadList,
      allLeads,
      pageNo,
      totalPages,
      replyLaterCount
    } = getState().leads;
    const { assignedTo, activeTab } = getState().leadsFilter;
    if (activeTab == TASK) {
      return;
    }
    if (
      previousClickedLead.status === REPLY_SENT ||
      previousClickedLead.status === TASK_ADDED ||
      previousClickedLead.status === NOTE_ADDED ||
      previousClickedLead.is_closed
    ) {
      if (
        previousClickedLeadList === NEW ||
        previousClickedLeadList === VIEWED
      ) {
        const filteredLeads = allLeads.results.filter(
          (lead) => lead.id !== previousClickedLead.id
        );
        const updatedData = {
          ...allLeads,
          results: filteredLeads
        };
        dispatch(setAllLeads(updatedData));
      }
      if (previousClickedLeadList === REPLY_LATER) {
        const filteredLeads = replyLaterLeads.results.filter(
          (lead) => lead.id !== previousClickedLead.id
        );
        const updatedData = {
          ...replyLaterLeads,
          results: filteredLeads
        };
        dispatch(setReplyLaterLeads(updatedData));
        dispatch(setReplyLaterCount(filteredLeads.length));
      }
      dispatch(getTaskEnquiryCount({ query: { type: ENQUIRY_TYPE } }));
    }
    if (
      previousClickedLead.status === VIEWED &&
      previousClickedLeadList === NEW
    ) {
      const filteredLeads = allLeads.results.filter(
        (lead) => lead.id !== previousClickedLead.id
      );
      const updatedData = {
        ...allLeads,
        results: filteredLeads
      };
      dispatch(setAllLeads(updatedData));
      const setLead = () => {
        const leads = [previousClickedLead, ...allLeads.results];
        const updatedLeadData = {
          ...allLeads,
          results: leads
        };
        dispatch(setAllLeads(updatedLeadData));
      };
      if (!assignedTo) {
        setLead();
      } else if (
        assignedTo?.user_id == null &&
        previousClickedLead.assigned_to == null
      ) {
        setLead();
      } else if (assignedTo?.user_id) {
        if (assignedTo?.user_id == previousClickedLead.assigned_to?.id) {
          setLead();
        } else if (previousClickedLead.assigned_to == null) {
          setLead();
        }
      }
      dispatch(getTaskEnquiryCount({ query: { type: ENQUIRY_TYPE } }));
    }
    if (
      previousClickedLead.status === NEW &&
      previousClickedLeadList === VIEWED
    ) {
      const filteredLeads = allLeads.results.filter(
        (lead) => lead.id !== previousClickedLead.id
      );
      const updatedData = {
        ...allLeads,
        results: filteredLeads
      };
      dispatch(setAllLeads(updatedData));

      const setLead = () => {
        dispatch(
          setAllLeads({
            ...allLeads,
            results: allLeads.results.map((l) => {
              if (l.id == previousClickedLead.id) {
                return previousClickedLead;
              }
              return l;
            })
          })
        );
      };
      if (!assignedTo) {
        setLead();
      } else if (
        assignedTo?.user_id == null &&
        previousClickedLead.assigned_to == null
      ) {
        setLead();
      } else if (assignedTo?.user_id) {
        if (assignedTo?.user_id == previousClickedLead.assigned_to?.id) {
          setLead();
        } else if (previousClickedLead.assigned_to == null) {
          setLead();
        }
      }
      dispatch(getTaskEnquiryCount({ query: { type: ENQUIRY_TYPE } }));
    }
    // when card status is updated to new and the lead is in reply later section
    if (
      previousClickedLead.status === NEW &&
      previousClickedLeadList === REPLYLATER
    ) {
      const filteredLeads = replyLaterLeads.results.filter(
        (lead) => lead.id !== previousClickedLead.id
      );
      const updatedData = {
        ...replyLaterLeads,
        results: filteredLeads
      };
      dispatch(setReplyLaterLeads(updatedData));
      dispatch(setReplyLaterCount(replyLaterCount - 1));

      const setLead = () => {
        if (totalPages === pageNo) {
          const leads = [previousClickedLead, ...allLeads.results];
          const updatedLeadData = {
            ...allLeads,
            results: leads,
            total_count: leads.length,
            total_pages: Math.ceil((leads.length + 1) / 10)
          };
          dispatch(setAllLeads(updatedLeadData));
        } else {
          const removeLastItem = allLeads.results.filter(
            (item, i) => i !== allLeads.results.length - 1
          );
          const leads = [previousClickedLead, ...removeLastItem];
          const updatedLeadData = {
            ...allLeads,
            results: leads,
            total_count: leads.length,
            total_pages: Math.ceil((leads.length + 1) / 10)
          };
          dispatch(setAllLeads(updatedLeadData));
        }
      };
      if (!assignedTo) {
        setLead();
      } else if (
        assignedTo?.user_id == null &&
        previousClickedLead.assigned_to == null
      ) {
        setLead();
      } else if (assignedTo?.user_id) {
        if (assignedTo?.user_id == previousClickedLead.assigned_to?.id) {
          setLead();
        } else if (previousClickedLead.assigned_to == null) {
          setLead();
        }
      }
      dispatch(getTaskEnquiryCount({ query: { type: ENQUIRY_TYPE } }));
    }
    if (previousClickedLead.status === NEW && previousClickedLeadList === NEW) {
      // previousClickedLead
      const leads = allLeads.results.map((item) => {
        if (item.id === previousClickedLead.id) {
          return previousClickedLead;
        }
        return item;
      });
      const updatedLeadData = {
        ...allLeads,
        results: leads
      };
      dispatch(setAllLeads(updatedLeadData));
      dispatch(getTaskEnquiryCount({ query: { type: ENQUIRY_TYPE } }));
    }
  };
};

// shift the lead on reply later or status change
export const shiftTheLead = (payload) => {
  return async (dispatch, getState) => {
    const { data } = payload;
    const { currentCardList, replyLaterLeads, allLeads, replyLaterCount } =
      getState().leads;
    if (currentCardList === NEW || currentCardList === VIEWED) {
      const filteredLeads = allLeads.results.filter(
        (lead) => lead.id !== data.id
      );
      const updatedData = {
        ...allLeads,
        results: filteredLeads,
        count: filteredLeads.length
      };
      dispatch(setAllLeads(updatedData));
    }
    if (currentCardList === REPLY_LATER) {
      const filteredLeads = replyLaterLeads.results.filter(
        (lead) => lead.id !== data.id
      );
      const updatedData = {
        ...replyLaterLeads,
        results: filteredLeads,
        count: filteredLeads.length
      };
      dispatch(setReplyLaterCount(filteredLeads.length));
      dispatch(setReplyLaterLeads(updatedData));
    }
    if (data.status === REPLY_LATER) {
      let leads;
      if (isEmpty(replyLaterLeads)) {
        leads = [data];
      } else {
        leads = [data, ...replyLaterLeads.results];
      }
      const updatedData = {
        ...replyLaterLeads,
        results: leads,
        count: leads.length
      };
      dispatch(setReplyLaterCount(replyLaterCount + 1));
      dispatch(setReplyLaterLeads(updatedData));
    }
  };
};

export const changeLeadStatus = (payload, successCb) => {
  return async (dispatch, getState) => {
    try {
      const { body } = payload;
      await new APIRequest()
        .post(replaceUrl(LEAD_UPDATE_STATUS), body)
        .then((res) => {
          if (res.status === 200) {
            dispatch(
              shiftTheLead({
                data: res.data
              })
            );
            dispatch(setActiveLead(res.data));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// when clicked on new leads change its status to viewed
export const updateUnreadLeadStatus = (payload, type, successCb) => {
  return async (dispatch, getState) => {
    try {
      const { body } = payload;
      const { activeLead } = getState().leadConversation;
      await new APIRequest()
        .post(replaceUrl(LEAD_UPDATE_STATUS), body)
        .then((res) => {
          if (res.status === 200) {
            updateAllLeadStatus(res.data, getState, dispatch);
            if (successCb) {
              successCb(res.data, activeLead);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// not using
export const onNewCardPostClick = (payload) => {
  return async (dispatch, getState) => {
    try {
      const { id } = payload;
      const {
        leads: { unReadLeads, readLeads, totalLeadCount, activeNewLead }
      } = getState();
      if (!isEmpty(activeNewLead)) {
        const previousActiveLead = unReadLeads?.results.find(
          (lead) => lead.id === activeNewLead
        );
        const unReadLeadList = unReadLeads?.results.filter(
          (lead) => activeNewLead !== lead.id
        );
        const updatedData = {
          ...unReadLeads,
          results: unReadLeadList
        };
        dispatch(setTotalLeadCount(totalLeadCount - 1));
        if (previousActiveLead) {
          dispatch(
            setReadLeads({
              ...readLeads,
              results: [previousActiveLead, ...readLeads.results]
            })
          );
          dispatch(setUnReadLeads(updatedData));
        }
        dispatch(setActiveNewLead(id));
      } else {
        dispatch(setActiveNewLead(id));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

// when clicked on new card and then clicked on another card then
// move the previous viewed card to the viewed section
export const onUnreadLeadFocused = (payload) => {
  return async (dispatch, getState) => {
    try {
      const { myInfo } = getState();
      dispatch(
        getNewSeenLeads({ subOrgId: myInfo.subOrganizationId, pageNo: 1 })
      );
    } catch (e) {
      console.log(e);
    }
  };
};

const updateUnreadLeads = (data, getState, dispatch) => {
  const {
    leads: { unReadLeads }
  } = getState();
  const Leads = _.cloneDeep(unReadLeads);
  // Find item index using _.findIndex
  const results = Leads.results;
  if (results) {
    let index = _.findIndex(results, { id: data.id });
    // Replace item at index using native splice
    if (index >= 0) {
      results.splice(index, 1, data);
      const updatedData = {
        ...Leads,
        results
      };
      dispatch(setUnReadLeads(updatedData));
    }
  }
};

const updateAllLeadStatus = (data, getState, dispatch) => {
  const {
    leads: { allLeads }
  } = getState();
  const Leads = _.cloneDeep(allLeads);
  // Find item index using _.findIndex
  const results = Leads.results;
  if (results) {
    let index = _.findIndex(results, { id: data.id });
    // Replace item at index using native splice
    if (index >= 0) {
      results.splice(index, 1, data);
      const updatedData = {
        ...Leads,
        results
      };
      dispatch(setAllLeads(updatedData));
    }
  }
};

const getLead = (draggableId, src, state) => {
  const { replyLaterLeads, allLeads } = state;
  if (parseInt(src.droppableId) === REPLYLATER) {
    const removedLead = replyLaterLeads.results.find(
      (lead) => lead.id === draggableId
    );
    const Lead = _.cloneDeep(removedLead);
    Lead.status = SEEN;
    return Lead;
  }
  if (parseInt(src.droppableId) === SEEN) {
    const removedLead = allLeads.results.find(
      (lead) => lead.id === draggableId
    );
    const Lead = _.cloneDeep(removedLead);
    Lead.status = REPLY_LATER;
    return Lead;
  }
  return null;
};

const getClenedList = (draggableId, src, state, dispatch) => {
  const { replyLaterLeads, allLeads } = state;
  if (parseInt(src.droppableId) === REPLYLATER) {
    const filteredLeads = replyLaterLeads.results.filter(
      (lead) => lead.id !== draggableId
    );
    const updatedData = {
      ...replyLaterLeads,
      reply_later_count: replyLaterLeads.reply_later_count - 1,
      results: filteredLeads,
      total_count: replyLaterLeads.total_count - 1,
      total_pages: Math.ceil((replyLaterLeads.total_count - 1) / 10)
    };
    dispatch(setReplyLaterLeads(updatedData));
  }
  if (parseInt(src.droppableId) === SEEN) {
    const filteredLeads = allLeads.results.filter(
      (lead) => lead.id !== draggableId
    );
    const updatedData = {
      ...allLeads,
      reply_later_count: allLeads.reply_later_count + 1,
      results: filteredLeads,
      total_count: allLeads.total_count - 1,
      total_pages: Math.ceil((allLeads.total_count - 1) / 10)
    };
    dispatch(setAllLeads(updatedData));
  }
};

export const onMoveSuccess = (payload, callback) => {
  return async (dispatch, getState) => {
    const { draggableId, source, destination } = payload;
    const {
      leads: { replyLaterLeads, allLeads, replyLaterCount }
    } = getState();
    const lead = getLead(draggableId, source, {
      replyLaterLeads,
      allLeads
    });
    if (lead) {
      if (parseInt(destination.droppableId) === REPLYLATER) {
        const Leads = _.cloneDeep(replyLaterLeads);
        const updateReplyLaterLeads = [
          ...Leads.results.slice(0, destination.index),
          lead,
          ...Leads.results.slice(destination.index)
        ];
        const updatedData = {
          ...Leads,
          results: updateReplyLaterLeads
        };
        dispatch(setReplyLaterLeads(updatedData));
        dispatch(setReplyLaterCount(replyLaterCount + 1));
        getClenedList(
          draggableId,
          source,
          { replyLaterLeads, allLeads },
          dispatch
        );
      }

      if (parseInt(destination.droppableId) === SEEN) {
        const Leads = _.cloneDeep(allLeads);
        const updateLeads = [
          ...Leads.results.slice(0, destination.index),
          lead,
          ...Leads.results.slice(destination.index)
        ];
        const updatedData = {
          ...Leads,
          results: updateLeads
        };
        dispatch(setAllLeads(updatedData));
        dispatch(setReplyLaterCount(replyLaterCount - 1));
        getClenedList(
          draggableId,
          source,
          { replyLaterLeads, allLeads },
          dispatch
        );
      }

      await dispatch(
        onCardMoveUpdateStatus(
          {
            body: {
              status: destination.droppableId,
              lead: draggableId,
              is_current: true
            }
          },
          () => {
            if (callback) {
              callback();
            }
          }
        )
      );
    }
  };
};

export const updateListOnCardMove = (formData) => {
  return async (dispatch, getState) => {
    const {
      leads: { replyLaterLeads, currentCardList, allLeads },
      leadConversation: { activeLead }
    } = getState();
    try {
      if (formData.box_id === activeLead.box) {
        dispatch(
          setPreviousActiveNewLead({ ...activeLead, status: activeLead.status })
        );
      } else {
        dispatch(
          setPreviousActiveNewLead({ ...activeLead, status: activeLead.status })
        );
      }
      if (currentCardList === REPLYLATER) {
        let updatedLaterLeadsList = null;
        updatedLaterLeadsList = replyLaterLeads?.results?.filter(
          (lead) => lead.id !== formData.lead
        );
        if (updatedLaterLeadsList) {
          dispatch(
            setReplyLaterLeads({
              ...replyLaterLeads,
              results: updatedLaterLeadsList
            })
          );
        }
      } else {
        let updatedViewedLeadsList = null;
        updatedViewedLeadsList = allLeads?.results?.filter(
          (lead) => lead.id !== formData.lead
        );
        if (updatedViewedLeadsList) {
          dispatch(
            setAllLeads({
              ...allLeads,
              results: updatedViewedLeadsList
            })
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const onMoveToBox = (payload, successCb, errorCb) => {
  return async (dispatch) => {
    try {
      const { formData, isBox, box_name, box_stage_name, is_manual_card } =
        payload;
      await new APIRequest().post(MOVE_TO_BOX, formData).then((res) => {
        if (res.status === 200) {
          if (!isBox) {
            dispatch(
              updateListOnCardMove({
                ...formData,
                box_name,
                box_stage_name,
                is_manual_card
              })
            );
          }
          if (successCb) {
            successCb(res.data);
          }
        }
      });
    } catch (e) {
      if (errorCb) errorCb(e);
      console.log(e);
    }
  };
};

export const updateLeadInList = () => {
  return async (dispatch, getState) => {
    try {
      const {
        leads: { replyLaterLeads, currentCardList, allLeads },
        leadConversation: { activeLead }
      } = getState();
      if (currentCardList == REPLYLATER) {
        if (replyLaterLeads.results) {
          const leadIndex = replyLaterLeads.results?.findIndex(
            (lead) => lead.id === activeLead.id
          );
          const leads = [...replyLaterLeads.results];
          leads.splice(leadIndex, 1, activeLead);
          const updatedList = {
            ...replyLaterLeads,
            results: leads
          };
          dispatch(setReplyLaterLeads(updatedList));
        }
      }
      if (currentCardList == NEW) {
        if (allLeads.results) {
          const leadIndex = allLeads.results?.findIndex(
            (lead) => lead.id === activeLead.id
          );
          const leads = [...allLeads.results];
          leads.splice(leadIndex, 1, activeLead);
          const updatedList = {
            ...allLeads,
            results: leads
          };
          dispatch(setAllLeads(updatedList));
        }
      }
      if (currentCardList == SEEN) {
        if (allLeads.results) {
          const leadIndex = allLeads.results?.findIndex(
            (lead) => lead.id === activeLead.id
          );
          const leads = [...allLeads.results];
          leads.splice(leadIndex, 1, activeLead);
          const updatedList = {
            ...allLeads,
            results: leads
          };
          dispatch(setAllLeads(updatedList));
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getLeadTasks = (payload = {}) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const thingsToDoLocation = getState().leads.thingsToDoLocation;
    const params = {
      location_ids: thingsToDoLocation?.id,
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id
    };
    try {
      const { callback, query = {} } = payload;
      await new APIRequest()
        .get(replaceUrl(GET_LIST_ENQUIRIES_WITH_TASKS, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setLeadTasks(res.data));
            if (callback) {
              callback();
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const hideEboxChannelIntroductionVideo = (payload) => {
  const { activeTab, successCb } = payload;
  return async (dispatch) => {
    try {
      const params = { video_type: activeTab };
      await new APIRequest()
        .post(HIDE_EBOX_CHANNEL_INTRODUCTION_VIDEO, { ...params })
        .then((res) => {
          if (res.status === 200) {
            dispatch(
              updateShowVideoPreference({
                videoId: activeTab,
                preference: false
              })
            );
            if (successCb) {
              successCb();
            }
          }
        });
    } catch (e) {
      console.log('e', e);
    }
  };
};

export default eboxLeads.reducer;
