import { createSlice } from '@reduxjs/toolkit';
import { replaceUrl } from 'utils/urlReplace';
import APIRequest from 'apiRequest';
import {
  CONNECT_CARE_STACK_CHANNEL,
  GET_CARE_STACK_API_KEY,
  CREATE_CARE_STACK_PATIENT,
  LINK_CARE_STACK_PATIENT,
  GET_CARE_STACK_APPOINTMENT_STATUS,
  GET_CARE_STACK_LOCATION_STATUS,
  GET_LEAD_MAPPED_LOCATION,
  GET_CARESTACK_RECORDING
} from 'urls/careStack';
import { getAllConnectedChannels } from 'slices/channelsSlice';

export const careStackSlice = createSlice({
  name: 'careStack',
  initialState: {
    careStackApiKey: null,
    appointmentStatus: [],
    locationStatus: []
  },
  reducers: {
    setCareStackApiKey: (state, action) => {
      state.careStackApiKey = action.payload;
    },
    setAppointmentStatus: (state, action) => {
      state.appointmentStatus = action.payload;
    },
    setLocationStatus: (state, action) => {
      state.locationStatus = action.payload;
    }
  }
});

export const { setCareStackApiKey, setAppointmentStatus, setLocationStatus } =
  careStackSlice.actions;

export default careStackSlice.reducer;

// get Boxly-Api Key
export const getCareStackApiKey = (payload) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      const { channelId, onSuccess, onError } = payload;
      new APIRequest()
        .get(
          replaceUrl(
            GET_CARE_STACK_API_KEY,
            ['subOrgId', 'channelId'],
            [subOrgId, channelId]
          )
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(setCareStackApiKey(res?.data?.hash_key));
          }
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((e) => {
          console.log(e);
          if (onError) {
            onError();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// connect carestack channel
export const connectCareStackChannel = (payload) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      const {
        api_key,
        vendorKey,
        accountKey,
        accountId,
        clientId,
        clientSecret,
        baseUrl,
        is_conversations,
        onSuccess,
        onError
      } = payload;
      const body = {
        sub_organization: subOrgId,
        api_key: api_key,
        cs_account_key: accountKey,
        cs_vendor_key: vendorKey,
        cs_account_id: accountId,
        cs_client_id: clientId,
        cs_client_secret: clientSecret,
        cs_practice_url: baseUrl,
        is_conversations
      };
      new APIRequest()
        .post(CONNECT_CARE_STACK_CHANNEL, body)
        .then((res) => {
          if (res.status == 201) {
            dispatch(getAllConnectedChannels({ subOrgId }));
            if (onSuccess) {
              onSuccess(res?.data);
            }
          }
        })
        .catch((e) => {
          if (onError) {
            onError();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// create carestack patient
export const createCareStackPatient = (payload) => {
  return async () => {
    try {
      const {
        lead_id,
        care_stack,
        first_name,
        last_name,
        email,
        phone,
        dob,
        choice_id,
        sub_choice_ids,
        defaultLocationId,
        onSuccess,
        onError
      } = payload;
      const body = {
        lead_id,
        care_stack,
        first_name,
        last_name,
        email,
        phone,
        dob,
        choice_id,
        sub_choice_ids,
        defaultLocationId
      };
      new APIRequest()
        .post(CREATE_CARE_STACK_PATIENT, body)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            if (onSuccess) {
              onSuccess(res);
            }
          }
        })
        .catch((e) => {
          if (onError) {
            onError();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// link carestack patient
export const linkCareStackPatient = (payload) => {
  return async () => {
    try {
      const {
        lead_id,
        care_stack,
        first_name,
        last_name,
        email,
        phone,
        dob,
        choice_id,
        sub_choice_ids,
        defaultLocationId,
        patient_id,
        onSuccess,
        onError
      } = payload;
      const body = {
        lead_id,
        care_stack,
        first_name,
        last_name,
        email,
        phone,
        dob,
        choice_id,
        sub_choice_ids,
        defaultLocationId,
        patient_id
      };
      new APIRequest()
        .post(LINK_CARE_STACK_PATIENT, body)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            if (onSuccess) {
              onSuccess(res);
            }
          }
        })
        .catch((e) => {
          if (onError) {
            onError();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// get appointment statuses
export const getCareStackAppointmentStatus = (payload = {}) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      const { onSuccess, onError, isSync } = payload;
      const query = {
        sub_org_id: subOrgId,
        ...(isSync ? { refresh: 'True' } : {})
      };
      new APIRequest()
        .get(GET_CARE_STACK_APPOINTMENT_STATUS, query)
        .then((res) => {
          if (res.status === 200) {
            dispatch(setAppointmentStatus(res.data));
            if (onSuccess) {
              onSuccess(res.data);
            }
          }
        })
        .catch((e) => {
          if (onError) {
            onError();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// get location statuses
export const getCareStackLocationStatus = (payload = {}) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      const { onSuccess, onError, isSync } = payload;
      const query = {
        sub_org_id: subOrgId,
        ...(isSync ? { refresh: 'True' } : {})
      };
      new APIRequest()
        .get(GET_CARE_STACK_LOCATION_STATUS, query)
        .then((res) => {
          if (res.status === 200) {
            dispatch(setLocationStatus(res.data));
            if (onSuccess) {
              onSuccess(res.data);
            }
          }
        })
        .catch((e) => {
          if (onError) {
            onError();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// get mapped carestack location
export const getLeadMappedLocation = (payload = {}) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      const { location_id, onSuccess, onError } = payload;
      const query = {
        sub_org_id: subOrgId,
        location_id
      };
      new APIRequest()
        .get(GET_LEAD_MAPPED_LOCATION, query)
        .then((res) => {
          if (res.status === 200) {
            if (onSuccess) {
              onSuccess(res.data);
            }
          }
        })
        .catch((e) => {
          if (onError) {
            onError();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// get carestack call recording
export const getCareStackRecording = (payload) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      const { callId, onSuccess } = payload;
      new APIRequest()
        .get(
          replaceUrl(
            GET_CARESTACK_RECORDING,
            ['callId', 'subOrgId'],
            [callId, subOrgId]
          )
        )
        .then((res) => {
          if (res.status === 200) {
            if (onSuccess) {
              onSuccess(res?.data?.signedUrl);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };
};
