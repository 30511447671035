/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import { replaceUrl } from 'utils/urlReplace';
import {
  FREE_ENQUIRIES_COUNT,
  GET_PLANS,
  GET_ALL_SMS_PLANS,
  UPGRADE_OR_DOWNGRADE_SMS_PLAN,
  GET_SMS_DETAILS,
  CANCEL_SMS_PLAN
} from 'urls/plans';
import { replace } from 'lodash';
import {
  SUBSCRIPTION_CONTINUE_PLAN,
  SUBSCRIPTION_DELETE_ACCOUNT,
  SUBSCRIPTION_STRIPE_REDIRECT,
  SUBSCRIPTION_UPGRADE_PLAN
} from 'urls';

const initialState = {
  postExpiryActiveModal: '', // to know which was last modal opened
  currentPlan: {},
  subscriptionPlans: [],
  smsPlans: [],
  currentSmsPlan: [], // sms plans for the selected country or purchased country
  supportedCountriesForSmsChannel: [], // countries for which sms channel is enabled
  smsDetails: [],
  freeEnquiriesCount: 0,
  showSubscriptionModal: false, // to show susbcription modal
  showEnquiriesExpiredModal: false, // to show 50 leads expire modal
  showCancelAccountModal: false, // to show cancel accoutn modal
  showDeleteAccountModal: false, // to show export data modal
  showFailedPaymentModal: false, //to show modal whenever payment is failed
  showSuccessPaymentModal: false, //to show modal whenever payment is success,
  showPaymentNotCompleted: false, //to show whenever payment is not completed,
  upgradePlanMessage: ''
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: initialState,
  reducers: {
    setShowSubscriptionModal: (state, action) => {
      state.showSubscriptionModal = action.payload;
    },
    setShowEnquiriesExpiredModal: (state, action) => {
      state.showEnquiriesExpiredModal = action.payload;
    },
    setCurrentPlan: (state, action) => {
      state.currentPlan = action.payload;
    },
    setSubscriptionPlans: (state, action) => {
      state.subscriptionPlans = action.payload;
    },
    setFreeEnquiriesCount: (state, action) => {
      state.freeEnquiriesCount = action.payload;
    },
    incrementFreeEnquiriesCount: (state) => {
      state.freeEnquiriesCount = ++state.freeEnquiriesCount;
    },
    setShowCancelAccountModal: (state, action) => {
      state.showCancelAccountModal = action.payload;
    },
    setShowDeleteAccountModal: (state, action) => {
      state.showDeleteAccountModal = action.payload;
    },
    setPostExpiryActiveModal: (state, action) => {
      state.postExpiryActiveModal = action.payload;
    },
    setShowFailedPaymentModal: (state, action) => {
      state.showFailedPaymentModal = action.payload;
    },
    setShowSuccessPaymentModal: (state, action) => {
      state.showSuccessPaymentModal = action.payload;
    },
    setUpgradePlanMessage: (state, action) => {
      state.upgradePlanMessage = action.payload;
    },
    setShowPaymentNotCompleted: (state, action) => {
      state.showPaymentNotCompleted = action.payload;
    },
    setSmsPlans: (state, action) => {
      state.smsPlans = action.payload;
    },
    setSmsDetails: (state, action) => {
      state.smsDetails = action.payload;
    },
    setCurrentSmsPlan: (state, action) => {
      state.currentSmsPlan = action.payload;
    },
    setSupportedCountriesForSmsChannel: (state, action) => {
      state.supportedCountriesForSmsChannel = action.payload;
    }
  }
});

export const {
  setShowSubscriptionModal,
  setShowEnquiriesExpiredModal,
  setCurrentPlan,
  setSubscriptionPlans,
  setFreeEnquiriesCount,
  incrementFreeEnquiriesCount,
  setShowCancelAccountModal,
  setShowDeleteAccountModal,
  setPostExpiryActiveModal,
  setShowFailedPaymentModal,
  setShowSuccessPaymentModal,
  setUpgradePlanMessage,
  setShowPaymentNotCompleted,
  setSmsPlans,
  setSmsDetails,
  setCurrentSmsPlan,
  setSupportedCountriesForSmsChannel
} = subscriptionSlice.actions;

export const getSubscriptionPlans = (subOrganizationId) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId || subOrganizationId;
    try {
      new APIRequest()
        .get(replaceUrl(GET_PLANS, 'subOrgId', subOrgId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setSubscriptionPlans(res?.data || []));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getFreeEnquiriesCount = () => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      new APIRequest()
        .get(replaceUrl(FREE_ENQUIRIES_COUNT, 'subOrgId', subOrgId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setFreeEnquiriesCount(res?.data?.enquiries_count || 0));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export default subscriptionSlice.reducer;

export const subscriptionUpgradePlan = (payload, callback, failCb) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      new APIRequest()
        .post(
          replaceUrl(SUBSCRIPTION_UPGRADE_PLAN, 'subOrgId', subOrgId),
          payload
        )
        .then((res) => {
          if (res.status === 200) {
            if (callback) {
              callback(res.data);
            }
          }
          if (res.status === 400) {
            if (failCb) {
              failCb(res);
            }
          }
        })
        .catch((e) => {
          console.log(e);
          if (failCb) {
            failCb(e);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const subscriptionContinuePlan = (payload, subOrgId, successCb) => {
  return async () => {
    try {
      new APIRequest()
        .post(
          replaceUrl(SUBSCRIPTION_CONTINUE_PLAN, 'subOrgId', subOrgId),
          payload
        )
        .then((res) => {
          if (res.status === 200) {
            successCb(res.data);
          }
        })
        .catch((err) => console.log(err));
    } catch (e) {
      console.log(e);
    }
  };
};

export const subscriptionStripeRedirect = (subOrgId, successCb, failedCb) => {
  return async () => {
    try {
      new APIRequest()
        .get(replaceUrl(SUBSCRIPTION_STRIPE_REDIRECT, 'subOrgId', subOrgId))
        .then((res) => {
          if (res.status === 200) {
            successCb(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          failedCb();
        });
    } catch (e) {
      console.log(e);
      failedCb();
    }
  };
};
export const deleteSubscriptionAccount = (payload, callback) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      new APIRequest()
        .post(
          replaceUrl(SUBSCRIPTION_DELETE_ACCOUNT, 'subOrgId', subOrgId),
          payload
        )
        .then((res) => {
          if (res.status === 200) {
            if (callback) {
              callback();
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getSmsPlans = () => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      new APIRequest()
        .get(replaceUrl(GET_ALL_SMS_PLANS, 'subOrgId', subOrgId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setSmsPlans(res.data.thier_sms_plans));
            dispatch(
              setSupportedCountriesForSmsChannel(res.data.supported_countries)
            );
          }
        })
        .catch((err) => console.log(err));
    } catch (e) {
      console.log(e);
    }
  };
};

export const upgradeSmsPlans = (payload) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const { planId, successCb, errorCb } = payload;
    try {
      new APIRequest()
        .post(
          replaceUrl(
            UPGRADE_OR_DOWNGRADE_SMS_PLAN,
            ['subOrgId', 'planId'],
            [subOrgId, planId]
          )
        )
        .then((res) => {
          if (res.status === 200) {
            if (successCb) {
              successCb();
            }
            dispatch(getSmsDetails());
          }
        })
        .catch((err) => {
          if (errorCb) {
            errorCb();
          }
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getSmsDetails = () => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      new APIRequest()
        .get(replaceUrl(GET_SMS_DETAILS, 'subOrgId', subOrgId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setSmsDetails(res.data));
          }
        })
        .catch((err) => console.log(err));
    } catch (e) {
      console.log(e);
    }
  };
};

export const cancelSmsPlan = (payload) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const { successCb, errorCb } = payload;
    try {
      new APIRequest()
        .delete(replaceUrl(CANCEL_SMS_PLAN, 'subOrgId', subOrgId))
        .then((res) => {
          if (res.status === 200) {
            if (successCb) {
              successCb();
            }
            dispatch(getSmsDetails());
          }
        })
        .catch((err) => {
          if (errorCb) {
            errorCb();
          }
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };
};
