import { createSlice } from '@reduxjs/toolkit';
import {
  GET_WEBFORM_MAPPING_FIELDS,
  UPDATE_WEBFORM_MAPPING_FIELDS,
  GET_WEBFORM_MAPPING_QUESTIONS_NEW,
  GET_WEBFORM_MAPPING_QUESTIONS
} from 'urls';
import { replaceUrl } from 'utils/urlReplace';
import APIRequest from 'apiRequest';

export const webformMapping = createSlice({
  name: 'mapfields',
  initialState: {
    allQuestions: [],
    allFields: {},
    webFormMappingQuestions: []
  },
  reducers: {
    setAllQuestions: (state, action) => {
      state.allQuestions = action.payload;
    },
    setWebFormMappingQuestions: (state, action) => {
      state.webFormMappingQuestions = action.payload;
    },
    setAllFields: (state, action) => {
      state.allFields = action.payload;
    }
  }
});

export const { setAllQuestions, setAllFields, setWebFormMappingQuestions } =
  webformMapping.actions;

export const getAllWebformMappingQuestions = (callback) => {
  return async (dispatch) => {
    try {
      new APIRequest().get(GET_WEBFORM_MAPPING_QUESTIONS).then((res) => {
        if (res.status === 200) {
          dispatch(setAllQuestions(res.data));
          if (callback) {
            callback();
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getWebformMappingQuestions = (callback) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      new APIRequest()
        .get(
          replaceUrl(GET_WEBFORM_MAPPING_QUESTIONS_NEW, 'subOrgId', subOrgId)
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(setWebFormMappingQuestions(res.data));
            if (callback) {
              callback();
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getAllWebformMappingFields = (payload, callback, successCb) => {
  return async (dispatch) => {
    const { formId } = payload;
    try {
      new APIRequest()
        .get(replaceUrl(GET_WEBFORM_MAPPING_FIELDS, 'formId', formId))
        .then((res) => {
          if (res.status === 200) {
            const donotObj = { label: 'Do not map', value: 'donotmap' };
            res.data?.form_fields.unshift(donotObj);
            dispatch(setAllFields(res.data));
            if (callback) {
              callback();
            }
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateWebformMappingFields = (payload, successCb, errorCb) => {
  return async () => {
    try {
      const { formId, body } = payload;
      new APIRequest()
        .put(replaceUrl(UPDATE_WEBFORM_MAPPING_FIELDS, 'formId', formId), body)
        .then((res) => {
          if (res.status === 200) {
            if (successCb) {
              successCb(res.data);
            }
          }
        })
        .catch((err) => {
          if (errorCb) {
            errorCb(err.response.data);
          }
        });
    } catch (e) {
      console.log('e', e);
      return '';
    }
  };
};

export default webformMapping.reducer;
