export function calculateInternetSpeed() {
  const imageUrl =
    'https://all-logos.s3.eu-west-1.amazonaws.com/Prod/image-to-test-internet-speed-2.jpg';
  const imageSize = 99029; // Size of the image in bytes

  const img = new Image();
  img.src = imageUrl;

  // saving starting time for image loading
  const startTime = Date.now();

  img.onload = () => {
    // saving end time after the image is loaded
    const endTime = Date.now();
    // calculating the time taken for the loading in seconds
    const duration = (endTime - startTime) / 1000;
    // calculating the speed in bytes per second
    const speedInBytesPerSecond = imageSize / duration;
    // converting spee into kb per second
    const speedInKbps = parseFloat((speedInBytesPerSecond / 1024).toFixed(2));

    // Send the calculated speed via postMessage
    window.postMessage(
      { type: 'internet_speed', speed: speedInKbps },
      window.location.origin
    );
  };

  img.onerror = (error) => {
    console.error('Error loading the image:', error);
  };
}
