const { REACT_APP_BASE_URL: BASE_API_URL } = process.env;

// api to get the details of the Organization
export const GET_ORG_DETAILS = `${BASE_API_URL}/organization/:orgId/detail`;

// api for org checkout
export const ORG_CHECKOUT = `${BASE_API_URL}/users/organization_checkout`;

// api to get all the accounts in the organization
export const GET_ALL_ACCOUNTS_LIST = `${BASE_API_URL}/organization/:orgId/sub_organizations`;

// api to create account in the organization
export const CREATE_ACCOUNT = `${BASE_API_URL}/organization/:orgId/build_my_crm`;

// api to clone existed account to the organization
export const CLONE_ACCOUNT = `${BASE_API_URL}/organization/:orgId/clone_sub_org`;

// api to update account data
export const UPDATE_ACCOUNT = `${BASE_API_URL}/organization/sub_organizations/:subOrgId`;

// api to invite organization user
export const INVITE_ORG_USER = `${BASE_API_URL}/organization/:orgId/invite_user`;

// api to get organization users
export const GET_ORG_USERS = `${BASE_API_URL}/organization/:orgId/users`;

// api to signup invited org member
export const SIGN_UP_INVITED_ORG_USER = `${BASE_API_URL}/organization/:orgId/signing_up_invited_org_member`;

// api to delete invited org user
export const DELETE_INVITED_ORG_USER = `${BASE_API_URL}/organization/:orgId/organization_users/:userId`;

// api to resend the invite for org member
export const RESEND_INVITE_ORG_USER = `${BASE_API_URL}/organization/:orgId/re_invite_user`;

// api to edit user permission
export const EDIT_ORG_USER_PERMISSION = `${BASE_API_URL}/organization/:orgId/organization_users/:userId`;

// api to change the role of the org user
export const UPDATE_ORG_USER_ROLE = `${BASE_API_URL}/organization/:orgId/change_role/:userId`;
