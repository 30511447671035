import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
// import APIRequest from 'apiRequest';
import choices from 'choices';
import { getBusinessInfoData } from 'slices/businessInfoSlice';
import { GET_FOLLOW_UP_TIME, UPDATE_FOLLOW_UP_TIME } from 'urls/followUp';
import { replaceUrl } from 'utils/urlReplace';

const { MINUTES, HOURS, CHOICES } = choices.FollowUpTimeChoice;
const { GREEN, AMBER } = choices.FollowUpStatusChoice;
export const Minutes = { label: CHOICES[MINUTES], value: MINUTES };

export const followUp = createSlice({
  name: 'followUp',
  initialState: {
    followUp: [],
    followUpGreen: { time: 0, duration: Minutes },
    followUpAmber: { time: 0, duration: Minutes }
  },
  reducers: {
    setFollowUp: (state, action) => {
      state.followUp = action.payload;
    },
    setFollowUpGreen: (state, action) => {
      state.followUpGreen = action.payload;
    },
    setFollowUpAmber: (state, action) => {
      state.followUpAmber = action.payload;
    }
  }
});

export const { setFollowUp, setFollowUpGreen, setFollowUpAmber } =
  followUp.actions;

const evaluateFollowUp = (data, type) => {
  return (dispatch) => {
    if (data) {
      if (type == GREEN) {
        let greenData;
        if (Array.isArray(data)) {
          greenData = data.find((d) => d.status_type == GREEN);
        } else {
          greenData = data;
        }
        if (greenData) {
          const payload = {
            time: greenData.time_duration,
            duration: {
              label: CHOICES[greenData.follow_up_time_type],
              value: greenData.follow_up_time_type == MINUTES ? MINUTES : HOURS
            }
          };
          dispatch(setFollowUpGreen(payload));
        }
      }
      if (type == AMBER) {
        let amberData;
        if (Array.isArray(data)) {
          amberData = data.find((d) => d.status_type == AMBER);
        } else {
          amberData = data;
        }
        if (amberData) {
          const payload = {
            time: amberData.time_duration,
            duration: {
              label: CHOICES[amberData.follow_up_time_type],
              value: amberData.follow_up_time_type == MINUTES ? MINUTES : HOURS
            }
          };
          dispatch(setFollowUpAmber(payload));
        }
      }
    }
  };
};

export const getFollowUpTime = () => {
  return (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      new APIRequest()
        .get(replaceUrl(GET_FOLLOW_UP_TIME, 'SubOrgId', subOrgId))
        .then((resp) => {
          if (resp.status == 200) {
            dispatch(setFollowUp(resp?.data));
            dispatch(evaluateFollowUp(resp?.data, GREEN));
            dispatch(evaluateFollowUp(resp?.data, AMBER));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateFollowUpTime = createAsyncThunk(
  'followUp/updateFollowUpTime',
  async (payload, { getState, dispatch, rejectWithValue }) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const { data } = payload;

    try {
      const response = await new APIRequest().patch(
        replaceUrl(UPDATE_FOLLOW_UP_TIME, 'SubOrgId', subOrgId),
        data
      );

      if (response.status === 200) {
        dispatch(evaluateFollowUp(response?.data, response?.data?.status_type));
        return response?.data;
      }
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// for socket event when follow up timer and business time is changed
export const getBusinessInfoAndFollowTimer = (payload) => {
  return (dispatch, getState) => {
    const myInformation = getState().myInfo.myInformation;
    if (myInformation.id == payload.user_id) {
      return;
    }
    if (payload.type == 'follow_up_timer') {
      dispatch(getFollowUpTime());
      return;
    }
    if (payload.type == 'business_info') {
      dispatch(getBusinessInfoData());
      return;
    }
  };
};

export default followUp.reducer;
