import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import {
  GET_USER_NOTIFICATIONS,
  ACTIVATE_DEACTIVATE_NOTIFICATIONS,
  GET_LEAD_LOCATION_URL
} from 'urls';
import { replaceUrl } from 'utils/urlReplace';
import { setUserInformation } from 'slices/myInfoSlice';
import moment from 'moment';

export const usersNotificationsSlice = createSlice({
  name: 'usersNotifations',
  initialState: {
    todayNotification: {},
    yesterdayNotification: {},
    olderNotification: {},
    filteredNotification: {},
    isFiltered: false
  },
  reducers: {
    setTodayNotification: (state, action) => {
      state.todayNotification = action.payload;
    },
    setYesterdayNotification: (state, action) => {
      state.yesterdayNotification = action.payload;
    },
    setOlderNotification: (state, action) => {
      state.olderNotification = action.payload;
    },
    setFilteredNotification: (state, action) => {
      state.filteredNotification = action.payload;
    },
    setIsFiltered: (state, action) => {
      state.isFiltered = action.payload;
    }
  }
});

export const {
  setTodayNotification,
  setYesterdayNotification,
  setOlderNotification,
  setFilteredNotification,
  setIsFiltered
} = usersNotificationsSlice.actions;

export default usersNotificationsSlice.reducer;

export const getTodayNotificationList = (payload) => {
  const { userId, query = {} } = payload;
  return async (dispatch) => {
    try {
      new APIRequest()
        .get(replaceUrl(GET_USER_NOTIFICATIONS, 'userId', userId), {
          date: moment().format('YYYY-MM-DD'),
          ...query
        })
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(setTodayNotification(resp.data));
          }
        });
    } catch (e) {
      console.log(' e', e);
    }
  };
};

export const getYesterdayNotificationList = (payload) => {
  const { userId, query = {} } = payload;
  return async (dispatch) => {
    try {
      new APIRequest()
        .get(replaceUrl(GET_USER_NOTIFICATIONS, 'userId', userId), {
          date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
          ...query
        })
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(setYesterdayNotification(resp.data));
          }
        });
    } catch (e) {
      console.log(' e', e);
    }
  };
};

export const getOlderNotificationList = (payload) => {
  const { userId, query = {} } = payload;
  return async (dispatch) => {
    try {
      new APIRequest()
        .get(replaceUrl(GET_USER_NOTIFICATIONS, 'userId', userId), {
          older: true,
          ...query
        })
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(setOlderNotification(resp.data));
          }
        });
    } catch (e) {
      console.log(' e', e);
    }
  };
};

export const getFilteredNotificationList = (payload) => {
  const { userId, notification_type, callback, query = {} } = payload;
  return async (dispatch) => {
    try {
      new APIRequest()
        .get(replaceUrl(GET_USER_NOTIFICATIONS, 'userId', userId), {
          filter_by: notification_type,
          ...query
        })
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(setFilteredNotification(resp.data));
            if (callback) {
              callback();
            }
          }
        });
    } catch (e) {
      console.log(' e', e);
    }
  };
};

export const getLeadUrlForNotification = (payload, callback) => {
  const { leadId } = payload;
  return async () => {
    try {
      new APIRequest()
        .get(replaceUrl(GET_LEAD_LOCATION_URL, 'leadId', leadId))
        .then((resp) => {
          if (resp.status === 200) {
            if (callback) {
              callback(resp.data);
            }
          }
        });
    } catch (e) {
      console.log(' e', e);
    }
  };
};

export const togglePushNotifications = (payload, onSuccess, onError) => {
  return async (dispatch, getState) => {
    try {
      const {
        myInfo: { myInformation }
      } = getState();
      const temp = { ...myInformation };
      new APIRequest()
        .post(ACTIVATE_DEACTIVATE_NOTIFICATIONS, payload)
        .then((resp) => {
          if (resp.status === 200) {
            if (onSuccess) {
              onSuccess();
            }
            temp.notify_me_about_everything = !temp.notify_me_about_everything;
            dispatch(setUserInformation(temp));
          }
        });
    } catch (e) {
      if (onError) {
        onError();
      }
      console.log(' e', e);
    }
  };
};
