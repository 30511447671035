import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GET_OR_UPDATE_NUDEGES, GET_SUB_ORG_INFO, MY_INFO } from 'urls';
import APIRequest from 'apiRequest';
import { clearStore } from 'store/store';
import { clearAuthToken } from 'slices/authSlice';
import {
  setCurrentPlan,
  setShowPaymentNotCompleted
} from 'slices/subscription/slice';
import { replaceUrl } from 'utils/urlReplace';
import CHOICES from 'choices';
import { GET_ORG_DETAILS } from 'urls/organizationAccount';
import { setOrganizationId } from './organizationAccount/slice';

const { MULTI_SUB_ORG_SCREEN } = CHOICES.FrontendRedirectChoice;
import { isEmpty } from 'utils/utils';

export const myInfoSlice = createSlice({
  name: 'myInfo',
  initialState: {
    isLoading: true,
    isError: false,
    isSuccess: false,
    myInformation: {},
    isNudgeActive: false,
    isChannelsConnected: false,
    subOrganizationId: null,
    userId: null,
    infoCount: 0,
    isHideWebformGuide: false,
    sender_custom_email: null,
    hasProLocationFeature: false,
    isHidePreactionerGuide: false,
    isMultiSubOrg: false,
    showOrgUserLogOutModal: false,
    showUserPermissionRemovedModal: false,
    showUserDeletedModal: false,
    isHideZapierGuide: false
  },
  reducers: {
    setMyInformation: (state, action) => {
      state.infoCount = state.infoCount + 1;
      state.myInformation = action?.payload;
      state.isChannelsConnected = action?.payload?.is_any_channel_connected;
      state.subOrganizationId = action?.payload.sub_org_id;
      state.hasProLocationFeature = action?.payload?.has_pro_location_feature;
      state.userId = action?.payload?.id;
      state.isMultiSubOrg = action?.payload?.organization?.is_multi_sub_org;
      // if (action?.payload?.sender_emails) {
      //   state.sender_custom_email = action?.payload.sender_emails[0];
      // } else {
      //   state.sender_custom_email = null;
      // }
    },
    setUserId: (state, action) => {
      state.userId = action?.payload;
    },
    updateShowVideoPreference: (state, action) => {
      const { videoId, preference } = action.payload;
      state.myInformation.show_these_videos_to_users[videoId] = preference;
    },
    setIsChannelsConnected: (state, action) => {
      state.isChannelsConnected = action?.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsError: (state, action) => {
      state.isError = action.payload;
    },
    setIsSuccess: (state, action) => {
      state.isSuccess = action.payload;
    },
    setUserInformation: (state, action) => {
      state.myInformation = action.payload;
    },
    setIsNudgeActive: (state, action) => {
      state.isNudgeActive = action.payload;
    },
    setIsHideWebformGuide: (state, action) => {
      state.isHideWebformGuide = action.payload;
    },
    setIsHideZapierGuide: (state, action) => {
      state.isHideZapierGuide = action.payload;
    },
    updateInitialTasksSuborganization: (state, action) => {
      state.myInformation.initial_tasks_suborganization = action.payload;
    },
    setIsHidePractionerGuide: (state, action) => {
      state.isHidePreactionerGuide = action.payload;
    },
    setSubOrganizationId: (state, action) => {
      state.subOrganizationId = action.payload;
    },
    setIsMultiSubOrg: (state, action) => {
      state.isMultiSubOrg = action.payload;
    },
    setShowOrgUserLogOutModal: (state, action) => {
      state.showOrgUserLogOutModal = action.payload;
    },
    setShowUserPermissionRemovedModal: (state, action) => {
      state.showUserPermissionRemovedModal = action.payload;
    },
    setShowUserDeletedModal: (state, action) => {
      state.showUserDeletedModal = action.payload;
    }
  }
});

export const {
  setMyInformation,
  setUserId,
  setIsChannelsConnected,
  setIsLoading,
  setIsError,
  setIsSuccess,
  setUserInformation,
  setIsNudgeActive,
  setIsHideWebformGuide,
  updateShowVideoPreference,
  updateInitialTasksSuborganization,
  setIsHidePractionerGuide,
  setSubOrganizationId,
  setIsMultiSubOrg,
  setShowOrgUserLogOutModal,
  setShowUserPermissionRemovedModal,
  setShowUserDeletedModal,
  setIsHideZapierGuide
} = myInfoSlice.actions;

export default myInfoSlice.reducer;

export const getOnlyUserInfoForLocationAccess = (payload = {}) => {
  const { cb } = payload;
  return async (dispatch, getState) => {
    const { myInformation, hasProLocationFeature } = getState().myInfo;
    if (hasProLocationFeature) {
      const my_info_resp = await new APIRequest().get(MY_INFO);
      if (my_info_resp?.status == 200) {
        dispatch(setMyInformation({ ...myInformation, ...my_info_resp.data }));
        if (cb) {
          cb();
        }
      }
    }
  };
};

export const getMyInfo = (callback) => {
  return async (dispatch, getState) => {
    const sub_org_id = getState().myInfo.subOrganizationId;
    const redirect = getState().auth.redirect;
    try {
      dispatch(setIsLoading(true));
      const my_info_resp = await new APIRequest().get(MY_INFO);
      if (redirect !== MULTI_SUB_ORG_SCREEN) {
        const sub_org = my_info_resp?.data.sub_organizations[0];
        const sub_org_resp = await new APIRequest().get(
          replaceUrl(GET_SUB_ORG_INFO, 'subOrgId', sub_org_id || sub_org?.id)
        );
        Promise.all([my_info_resp, sub_org_resp])
          .then((resp) => {
            const [my_info, sub_org] = resp;
            let data = {};
            if (my_info.status === 200 && sub_org.status === 200) {
              data = {
                ...my_info.data,
                ...sub_org.data
              };
              if (isEmpty(sub_org?.data?.plan_details)) {
                // for exiting users to update the logic for the new feature
                if (navigator.serviceWorker) {
                  navigator.serviceWorker
                    .getRegistrations()
                    .then((registrations) => {
                      for (let registration of registrations) {
                        registration.update();
                      }
                    });
                }
              }
              dispatch(setMyInformation(data));
              dispatch(setCurrentPlan(sub_org?.data.plan_details));
              dispatch(
                setIsHideWebformGuide(my_info?.data.hide_web_form_message)
              );
              dispatch(
                setIsHideZapierGuide(my_info?.data?.hide_zapier_message)
              );
              dispatch(
                setIsHidePractionerGuide(
                  my_info?.data?.hide_practitioners_help_text
                )
              );
              dispatch(setIsSuccess(true));
              dispatch(setIsLoading(false));
            }
            callback(data);
          })
          .catch((err) => {
            console.log({ err });
            if (err?.response?.status == 401) {
              if (
                err?.response?.data?.code === 'user_not_found' ||
                err?.response?.data?.code === 'token_not_valid'
              ) {
                clearStore();
                dispatch(clearAuthToken());
              }
            }
            dispatch(setIsLoading(false));
            dispatch(setIsError(true));
            dispatch(setIsSuccess(false));
          });
      } else {
        const org = my_info_resp?.data?.organization?.id;
        const org_resp = await new APIRequest().get(
          replaceUrl(GET_ORG_DETAILS, 'orgId', org)
        );
        Promise.all([my_info_resp, org_resp])
          .then((resp) => {
            const [my_info, org_info] = resp;
            let data = {};
            if (my_info.status === 200 && org_info.status === 200) {
              data = {
                ...my_info.data,
                ...org_info.data
              };
              if (!org_info?.data?.plan_details?.payment_completed) {
                dispatch(setShowPaymentNotCompleted(true));
              }
              dispatch(setOrganizationId(my_info?.data?.organization?.id));
              dispatch(setMyInformation(data));
              dispatch(setCurrentPlan(org_info?.data?.plan_details));
              dispatch(
                setIsHideWebformGuide(my_info?.data.hide_web_form_message)
              );
              dispatch(
                setIsHideZapierGuide(my_info?.data?.hide_zapier_message)
              );
              dispatch(setIsSuccess(true));
              dispatch(setIsLoading(false));
            }
            callback(data);
          })
          .catch((err) => {
            console.log({ err });
            if (err?.response?.status == 401) {
              if (
                err?.response?.data?.code === 'user_not_found' ||
                err?.response?.data?.code === 'token_not_valid'
              ) {
                clearStore();
                dispatch(clearAuthToken());
              }
            }
            dispatch(setIsLoading(false));
            dispatch(setIsError(true));
            dispatch(setIsSuccess(false));
          });
      }
    } catch (err) {
      console.log({ err });
      if (err?.response?.status == 401) {
        if (
          err?.response?.data?.code === 'user_not_found' ||
          err?.response?.data?.code === 'token_not_valid'
        ) {
          clearStore();
          dispatch(clearAuthToken());
        }
      }
      dispatch(setIsLoading(false));
      dispatch(setIsError(true));
      dispatch(setIsSuccess(false));
    }
  };
};

// get sub organization data
export const getSubOrganization = createAsyncThunk(
  'user/getMyInfo',
  async (id, { dispatch, getState, rejectWithValue }) => {
    const myInfo = getState().myInfo.myInformation;
    try {
      const resp = await new APIRequest().get(
        replaceUrl(GET_SUB_ORG_INFO, 'subOrgId', id)
      );
      if (resp.status === 200) {
        dispatch(setMyInformation({ ...myInfo, ...resp.data }));
        dispatch(setCurrentPlan(resp?.data.plan_details));
        return resp.data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getNudges = (payload) => {
  return async (dispatch) => {
    try {
      const { userId } = payload;
      new APIRequest()
        .get(replaceUrl(GET_OR_UPDATE_NUDEGES, 'userId', userId))
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(setIsNudgeActive(resp.data.move_enquiry_stages_in_boxes));
          }
        });
    } catch (e) {
      console.log(' e', e);
    }
  };
};

export const UpdateNudgeActiveStatus = (payload, onSucess, onError) => {
  return async () => {
    try {
      const { userId, data } = payload;
      new APIRequest()
        .put(replaceUrl(GET_OR_UPDATE_NUDEGES, 'userId', userId), data)
        .then((resp) => {
          if (resp.status === 200) {
            onSucess(resp?.data?.move_enquiry_stages_in_boxes);
          }
        });
    } catch (e) {
      console.log(' e', e);
      if (onError) {
        onError();
      }
    }
  };
};
