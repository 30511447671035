import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import {
  GET_POST_LOCATIONS,
  UPDATE_DELETE_LOCATIONS,
  UPDATE_LABEL_LOCATION_LEAD_STATUS,
  UPDATE_LABEL_LOCATION_STATUS
} from 'urls/locationLabels';
import { replaceUrl } from 'utils/urlReplace';

export const locationLabels = createSlice({
  name: 'locationLabels',
  initialState: {
    locationLabelsList: [],
    isShowLocationLabelStatus: true,
    selectedLocationLabels: []
  },
  reducers: {
    setLocationLabelList: (state, action) => {
      state.locationLabelsList = action.payload;
    },
    setSelectedLocationLabels: (state, action) => {
      state.selectedLocationLabels = action.payload;
    }
  }
});

export const { setLocationLabelList, setSelectedLocationLabels } =
  locationLabels.actions;

export default locationLabels.reducer;

export const getLocationLabelsList = (payload = {}) => {
  const { successCb, sub_org_id } = payload;
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      await new APIRequest()
        .get(replaceUrl(GET_POST_LOCATIONS, 'subOrgId', sub_org_id || subOrgId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setLocationLabelList(res?.data?.results));
            if (successCb) successCb();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const addLocationLabelName = (payload) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const { locationLabelsList } = getState().locationLabels;
    const { data, onSuccess, failedCb } = payload;
    try {
      await new APIRequest()
        .post(replaceUrl(GET_POST_LOCATIONS, 'subOrgId', subOrgId), data)
        .then((res) => {
          if (res.status === 201) {
            dispatch(setLocationLabelList([...locationLabelsList, res.data]));
            if (onSuccess) onSuccess();
          }
        })
        .catch((e) => {
          console.log(e);
          if (failedCb) failedCb();
        });
    } catch (e) {
      console.log(e);
      if (failedCb) failedCb();
    }
  };
};

export const updateLocationLabelName = (payload) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const { locationLabelsList } = getState().locationLabels;
    const { locationId, data, onSuccess, onError } = payload;
    try {
      await new APIRequest()
        .put(
          replaceUrl(
            UPDATE_DELETE_LOCATIONS,
            ['subOrgId', 'locationId'],
            [subOrgId, locationId]
          ),
          data
        )
        .then((res) => {
          if (res.status === 200) {
            const updatedLocation = locationLabelsList.map((obj) =>
              obj.id === locationId ? res.data : obj
            );
            dispatch(setLocationLabelList(updatedLocation));
            if (onSuccess) onSuccess();
          }
        })
        .catch((e) => {
          console.log(e);
          if (onError) onError();
        });
    } catch (e) {
      if (onError) onError();
      console.log(e);
    }
  };
};

export const deleteLocationLabelName = (payload) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const { locationLabelsList } = getState().locationLabels;
    const { locationId, onSuccess } = payload;
    try {
      await new APIRequest()
        .delete(
          replaceUrl(
            UPDATE_DELETE_LOCATIONS,
            ['subOrgId', 'locationId'],
            [subOrgId, locationId]
          )
        )
        .then((res) => {
          if (res.status === 204) {
            const updatedLabels = locationLabelsList.filter(
              (obj) => obj.id !== locationId
            );
            dispatch(setLocationLabelList(updatedLabels));
            if (onSuccess) onSuccess();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateLocationLabelStatus = (payload) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      await new APIRequest()
        .post(
          replaceUrl(UPDATE_LABEL_LOCATION_STATUS, 'subOrgId', subOrgId),
          payload
        )
        .then((res) => {
          if (res.status === 200) {
            return res;
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateLeadLocationLabelStatus = (payload, onSuccess) => {
  return async () => {
    const { lead, label } = payload;
    try {
      await new APIRequest()
        .post(replaceUrl(UPDATE_LABEL_LOCATION_LEAD_STATUS, 'leadId', lead), {
          location_id: label
        })
        .then((res) => {
          if (res.status === 200) {
            if (onSuccess) {
              onSuccess(res);
            }
            return res;
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
export const deleteLeadLocationLabel = (payload, callback) => {
  return async () => {
    const { leadId, locationLabelId } = payload;
    try {
      await new APIRequest()
        .delete(
          replaceUrl(UPDATE_LABEL_LOCATION_LEAD_STATUS, 'leadId', leadId),
          { loacation_id: locationLabelId }
        )
        .then((res) => {
          if (res.status === 200) {
            if (callback) {
              callback(res);
            }
            return res;
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
