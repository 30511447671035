const { REACT_APP_BASE_URL: BASE_API_URL } = process.env;

export const GET_ALL_BOX = `${BASE_API_URL}/box/get_all_box/:subOrgId`;
export const GET_STAGE_LEADS = `${BASE_API_URL}/leads/list_lead_in_stage/:stageId`;
export const GET_REPLY_LATER_STAGE_LEADS = `${BASE_API_URL}/leads/list_lead_in_stage/:stageId`;
export const MOVE_TO_BOX = `${BASE_API_URL}/box/move_card_to_box`;
export const MOVE_CARD_TO_STAGE = `${BASE_API_URL}/box/move_card_stage`;
export const DELETE_BOX = `${BASE_API_URL}/box/delete_leads_in_box/:boxId`;
export const RENAME_BOX = `${BASE_API_URL}/box/update_box_name/:boxId`;
export const DOWNLOAD_LEADS_BEFORE_DELETE = `${BASE_API_URL}/box/sub_organisations/:subOrgId/boxs/:boxId/download_leads_before_deleting`;

// api for box form in box settings
export const GET_BOX_FORM_DATA = `${BASE_API_URL}/box/create_update_box_form/:boxId`;
export const CREATE_UPDATE_BOX_FORM = `${BASE_API_URL}/box/create_update_box_form`;

// api for gettings individual box data
export const GET_BOX_DATA = `${BASE_API_URL}/box/box_details/:boxId`;

// api for adding manual enquiry from box form
export const CREATE_MANUAL_ENQUIRY_BOX_FORM = `${BASE_API_URL}/box/add_manual_enquiry`;
export const ADD_STAGE = `${BASE_API_URL}/box/create_update_stage`;
export const UPDATE_STAGE = `${BASE_API_URL}/box/create_update_stage/:stageId`;
export const MOVE_TO_STAGE_API = `${BASE_API_URL}/box/move_card_to_stage`;
export const DELETE_STAGE = `${BASE_API_URL}/box/box_details/:stageId`;

// api for deleting the sample leads
export const DELETE_SAMPLE_LEADS = `${BASE_API_URL}/leads/delete_sample_lead/:leadId`;

// api for box automations
export const CREATE_BOX_AUTOMATION = `${BASE_API_URL}/automations/create_update_box_automation`;
export const UPDATE_BOX_AUTOMATION = `${BASE_API_URL}/automations/create_update_box_automation/:automation_id`;

// api to get stage contract value
export const GET_STAGE_CONTRACT_VALUE = `${BASE_API_URL}/box/get_stage_contract_value/:stageId`;

// api to get boxes new enquires
export const GET_NEW_BOXES = `${BASE_API_URL}/leads/list_new_leads_in_box/:boxId`;
export const GET_ALL_STAGE_LEAD_COUNT = `${BASE_API_URL}/box/:boxId/get_all_stages_with_lead_count`;

//api to configure box notification for user
export const GET_BOX_NOTIFICATION_OF_USER = `${BASE_API_URL}/box/users/:userId/box_notifications`;
export const UPDATE_BOX_NOTIFICATION_OF_USER = `${BASE_API_URL}/box/box_notifications/:boxNotificationId`;
export const GET_BOX_PERMISSION_NOTIFICATION_OF_USER = `${BASE_API_URL}/organization/organization_users/:userId/permission_and_notifications`;

// api for box drag and drop
export const RE_ORDER_BOX = `${BASE_API_URL}/box/sub_organisations/:subOrgId/reorder_box`;

// api for updating box data
export const UPDATE_BOX_DATA = `${BASE_API_URL}/box/update_box/:boxId`;

// api to hide videos in box and smart rules
export const HIDE_VIDEO = `${BASE_API_URL}/box/hide_tutorial_video`;

// api to dismiss dummy leads in box
export const DISMISS_DUMMY_LEADS = `${BASE_API_URL}/leads/delete_box_dummy_lead/:leadId`;

// api to get the default automation template
export const GET_DEFAULT_AUTOMATION_TEMPLATES_LIST = `${BASE_API_URL}/automation_template/box-template-automation/industry/:industry`;

// api to select one default automation template from the list
export const SELECT_DEFAULT_AUTOMATION_TEMPLATE = `${BASE_API_URL}/automation_template/automation_template`;

//api to delete the unsaved automation template when they click on cancel
export const DELETE_UNSAVED_AUTOMATION_TEMPLATE = `${BASE_API_URL}/automation_template/delete-automation`;

// api to toggle the stage checklist
export const UPDATE_TOGGLE_CHECKLIST = `${BASE_API_URL}/box/toggle_checklist`;

// api to add checklisted field to the stage
export const ADD_CHECKLIST_FIELD_TO_STAGE = `${BASE_API_URL}/box/checklist/:stageId`;

//api to add box templates for the field
export const GET_BOX_TEMPLATES = `${BASE_API_URL}/box/get_box_templates/:subOrgId`;

// api to create box form dental templates
export const CRETATE_BOX_FROM_DENTAL_TEMPLATES = `${BASE_API_URL}/box/create_box_from_template/:subOrgId`;
