const { REACT_APP_BASE_URL: BASE_API_URL } = process.env;

export const BASE_URL = BASE_API_URL;
export const SIGN_UP_RTK = '/users/user_signup';
export const VERIFY_USER_EMAIL_RTK = '/users/verify_user_email';
export const LOG_IN_RTK = '/login/';
export const CREATE_BUSINESS_INFO_RTK = '/organization/create_organization';
export const USER_SOCIAL_SIGNUP_RTK = '/users/user_social_signup';
export const INVITED_USER_SIGNUP_RTK = '/users/invited_user_signup';
export const RESEND_CONFIRMATION_EMAIL_RTK = '/users/resend_confirmation_mail';
export const INVITE_USER_RTK =
  '/organization/sub_organizations/:subOrgId/invite_team_member';
export const SOCIAL_AUTH_CREDENTIALS_RTK = '/users/social_auth_credentials';
export const FORGOT_PASSWORD_RTK = '/users/forgot_password';
export const CREATE_PASSWORD = '/users/create_new_password';
export const VERIFY_FORGOT_PASSWORD = '/users/verify_forgot_password';
export const MY_INFO = `${BASE_URL}/users/my_info`;
export const GET_SUB_ORG_INFO = `${BASE_URL}/organization/sub_organizations/:subOrgId`;

// CHANNELS
export const GET_ALL_GLOBAL_CHANNELS = '/channels/get_all_global_channels';
export const GET_ALL_CONNECTED_CHANNELS =
  '/channels/:subOrgId/get_all_connected_channels';
export const GET_ALL_PENDING_CHANNELS =
  '/channels/:subOrgId/get_all_pending_channels';
export const DELETE_CHANNEL =
  '/channels/sub_organizations/:subOrgId/connected_channels/:channelId';
export const GET_CONNECTED_CHANNEL_BY_ID = `${BASE_API_URL}/channels/sub_organizations/:subOrgId/connected_channels/:channelId`;
export const UPDATE_CHANNEL_NAME_STATUS = `${BASE_API_URL}/ebots/sub_organizations/:subOrgId/connected_channels/:channelId/update_this_channel_setting`;
export const ADD_BOT_ADDON = `${BASE_API_URL}/plans/sub_organizations/:subOrgId/bot_as_addon`;

// CHANNEL CONNECTIONS
export const CONNECT_CHANNEL_AS_DRAFT =
  '/channels/:subOrgId/connect_a_channel_as_draft';
export const GET_WEBFORMS_SCRIPT =
  '/webforms/sub_organizations/:subOrgId/connected_channels/:channelId/';

export const UPDATE_CHANNEL_NAME = `${BASE_API_URL}/channels/sub_organizations/:subOrgId/connected_channels/:channelId`;
export const CONNECT_CHANNEL_AS_DRAFT_API = `${BASE_API_URL}/channels/:subOrgId/connect_a_channel_as_draft`;
export const GET_ALL_GLOBAL_CHANNELS_API = `${BASE_API_URL}/channels/get_all_global_channels`;
export const GET_ALL_CONNECTED_CHANNELS_API = `${BASE_API_URL}/channels/:subOrgId/get_all_connected_channels`;
export const GET_ALL_PENDING_CHANNELS_API = `${BASE_API_URL}/channels/:subOrgId/get_all_pending_channels`;

export const GET_CONNECTED_WEBFORM_API = `${BASE_API_URL}/webforms/sub_organizations/:subOrgId/connected_channels/:channelId/`;
export const DELETE_CHANNEL_API = `${BASE_API_URL}/channels/sub_organizations/:subOrgId/connected_channels/:channelId`;
export const GET_CONNECTED_CHANNEL_API = `${BASE_API_URL}/webforms/get_webform_data/:channelId`;
export const UPDATE_WEBFORM_DATA = `${BASE_API_URL}/webforms/update_webform_mapping/:formId`;

// enquirybot automations
export const GET_ALL_THE_ENQUIRY_AND_SUB_ENQUIRY_TYPE = `${BASE_API_URL}/ebots/sub_organizations/:subOrgId/connected_channels/:channelId/get_all_the_enquiry_and_sub_enquiry_types`;

// get all the enquiry type for website chatbot
export const GET_ALL_ENQUIRY_TYPE_WEBSITE_CHATBOT = `${BASE_API_URL}/website_chat_bots/sub_organizations/:subOrgId/connected_channels/:channelId/enquiry_types`;

// send email to web developer
export const SEND_EMAIL_TO_DEVELOPER = `${BASE_API_URL}/emails/send_an_html_email`;

// delete channel automation
export const DELETE_CHANNEL_AUTOMATION = `${BASE_API_URL}/automations/automations/:automationId`;

// get forms for facebook lead ads
export const GET_FB_LEAD_ADS_FORMS = `${BASE_API_URL}/facebook_lead_ads/connected_channels/:channelId/fb_page/all_forms`;

// get forms for webform
export const GET_WEBFORM_FORMS = `${BASE_API_URL}/webforms/get_webform_data/:channelId`;

// update status for fb lead ads
export const UPDATE_FB_LEAD_ADS_FORM_STATUS = `${BASE_API_URL}/facebook_lead_ads/connected_channels/:channelId/fb_page/all_forms/:formId`;

// hide setup guide for fb leads
export const HIDE_GUIDE_FOR_FB_LEADS = `${BASE_API_URL}/users/update_user_info/:userId`;

// hide webform guide from user
export const HIDE_GUIDE_FOR_WEBFORMS = `${BASE_API_URL}/webforms/hide_message_for_user`;

// sync new fb lead ads form
export const SYNC_NEW_FB_LEAD_ADS = `${BASE_API_URL}/facebook_lead_ads/sub_organizations/:subOrgId/connected_channels/:channelId/get_all_the_latest_ads_forms`;

// get all mapping fields for fb leads
export const GET_ALL_FB_LEADS_MAPPING_FIELDS = `${BASE_API_URL}/facebook_lead_ads/ads_forms/:formId/mapping_fields`;

// edit fb lead ads mapping
export const EDIT_FB_LEAD_ADS_MAPPING = `${BASE_API_URL}/facebook_lead_ads/complete_the_mapping_to_question_fields/forms/:formId`;

// get all webform channel your forms
export const GET_ALL_WEBFORM_YOUR_FORMS = `${BASE_API_URL}/webforms/get_webform_data/:channelId`;

// get individual webform form data
export const GET_WEBFORM_MAPPING_DATA = `${BASE_API_URL}/webforms/get_web_form_mapping_data/:formId`;

// delete webform form data
export const DELETE_WEBFORM_FORM_DATA = `${BASE_API_URL}/webforms/delete_mapping/:subOrgId/:channelId/:formId`;

// edit webform mapping data
export const EDIT_WEBFORM_MAPPED_DATA = `${BASE_API_URL}/webforms/update_webform_mapping/:formId`;

//WebForm Automations
export const SEND_AUTOMATION = `${BASE_API_URL}/automations/automations`;
export const GET_AUTOMATIONS = `${BASE_API_URL}/automations/automations?channel_id=:channelId`;
export const UPDATE_AUTOMATIONS = `${BASE_API_URL}/automations/automations/:automationId`;

//Facebook Automatoins
export const SEND_FACEBOOK_AUTOMATION_AUTO_REPLY = `${BASE_API_URL}/automations/facebook/auto_reply/create_automation`;
export const UPADTE_FACEBOOK_AUTOMATION_AUTO_REPLY = `${BASE_API_URL}/automations/facebook/auto_reply/update_automation/:automationId`;
export const SEND_FACEBOOK_AUTOMATION_ASSIGN_USER = `${BASE_API_URL}/automations/for_all/assign_lead_to_team_member/create_automation`;
export const UPDATE_FACEBOOK_AUTOMATION_ASSIGN_USER = `${BASE_API_URL}/automations/for_all/assign_lead_to_team_member/update_automation/:automationId`;

//FaceBook Lead Ads Automation
export const GET_FB_LEAD_ADS_CONNECTED_CHANNELS = `${BASE_API_URL}/facebook_lead_ads/connected_channels/:channelId/form_names`;
export const SEND_FACEBOOK_LEAD_ADS_AUTOMATION_AUTO_REPLY = `${BASE_API_URL}/automations/email_reply/create`;
export const UPADTE_FACEBOOK_LEAD_ADS_AUTOMATION_AUTO_REPLY = `${BASE_API_URL}/automations/email_reply/update/:automationId`;
export const SEND_FACEBOOK_LEAD_ADS_AUTOMATION_ASSIGN_USER = `${BASE_API_URL}/automations/for_all/assign_lead_to_team_member/create_automation`;
export const UPADTE_FACEBOOK_LEAD_ADS_AUTOMATION_ASSIGN_USER = `${BASE_API_URL}/automations/for_all/assign_lead_to_team_member/update_automation/:automationId`;

export const GET_FB_LEAD_ADS_CONNECTED_PAGES = `${BASE_API_URL}/facebook_lead_ads/connected_channels/:channelId/fb_page/all_forms`;
export const UPDATE_FB_LEAD_ADS_CONNECTED_PAGES = `${BASE_API_URL}/facebook_lead_ads/connected_channels/:channelId/fb_page/all_forms/:formId`;

export const GET_WEBFORM_MAPPING_QUESTIONS = `${BASE_API_URL}/questions/questions`;
export const GET_WEBFORM_MAPPING_QUESTIONS_NEW = `${BASE_API_URL}/webforms/get_webform_questions?sub_organization=:subOrgId`;
export const GET_WEBFORM_MAPPING_FIELDS = `${BASE_API_URL}/webforms/mapping/form/:formId/fields/`;
export const UPDATE_WEBFORM_MAPPING_FIELDS = `${BASE_API_URL}/webforms/mapping/form/:formId/`;

//LEAD COUNT
export const GET_TOTAL_LEADS = `${BASE_API_URL}/leads/get_lead_count`;

// ALL COUNTS
export const GET_ALL_LEAD_COUNT = `${BASE_API_URL}/leads/get_all_lead_count`;

// MARK NOTIFICATION AS READ
export const MARK_NOTIFICATION_AS_READ = `${BASE_API_URL}/notifications/mark_notification_as_read`;

// LEADS
export const GET_LEADS = `${BASE_API_URL}/leads/sub_organizations/:subOrgId/leads`;
export const LEAD_UPDATE_STATUS = `${BASE_API_URL}/leads/update_status`;
export const GET_LEAD_CONVERSATION = `${BASE_API_URL}/leads/lead_conversation/:leadId`;
export const GET_LEAD_REPLY = `${BASE_API_URL}/leads/reply/:replyId`;
export const GET_LEADS_HISTORY = `${BASE_API_URL}/leads/history/:leadId`;
export const ASSIGN_USER_TO_LEAD = `${BASE_API_URL}/leads/update_lead/:leadId`;
export const UNASSIGN_USER_TO_LEAD = `${BASE_API_URL}/leads/update_lead/:leadId?un_assigned=True`;
export const LEADS_REPLY = `${BASE_API_URL}/leads/reply`;

// FILE UPLOAD
export const FILE_UPLOAD = `${BASE_API_URL}/file_library/upload_image/`;
export const FILE_UPLOAD_DELETE = `${BASE_API_URL}/file_library/attachments/:attachmentId`;
export const AUTOMATION_FILE_UPLOAD_DELETE = `${BASE_API_URL}/file_library/attachments/:fileId`;

export const GET_LEAD_DATA = `${BASE_API_URL}/leads/lead_data/:leadId`;

export const USER_SOCIAL_SIGNUP = `${BASE_API_URL}/users/user_social_signup`;
export const SOCIAL_AUTH_CREDENTIALS = `${BASE_API_URL}/users/social_auth_credentials`;
export const INVITE_USER = `${BASE_API_URL}/users/invite_user`;
export const ORGANIZATION_USER = `${BASE_API_URL}/organization/sub_organizations/:subOrgId/organization_users`;

// LEADS LABELS
export const LEADS_LABELS = `${BASE_API_URL}/leads/label`;
export const UPDATE_LEADS_LABELS = `${BASE_API_URL}/leads/label/:labelId`;
export const ADD_LABELS_TO_LEADS = `${BASE_API_URL}/leads/:lead_id/lead_label`;
export const REMOVE_LEADS_LABELS = `${BASE_API_URL}/leads/:lead_id/lead_label`;
export const UPDATE_LABEL_VALUE = `${BASE_API_URL}/leads/:lead_id/lead_label`;

// Api for fetching labels in public box form page
export const LEADS_LABELS_PUBLIC = `${BASE_API_URL}/leads/value_labels/:subOrgId`;

// users list
export const GET_USERS_LIST = `${BASE_API_URL}/organization/sub_organizations/:subOrgId/organization_users`;

//my-profile
export const UPDATE_USER_PROFILE_NAME = `${BASE_API_URL}/users/update_user_info/:user_id`;
export const GET_LOGIN_WAYS = `${BASE_API_URL}/users/get_my_login_ways`;

// user notifications
export const GET_USER_NOTIFICATIONS = `${BASE_API_URL}/notifications/get_notifications/:userId`;
export const GET_LEAD_LOCATION_URL = `${BASE_API_URL}/leads/:leadId/get_lead_location_url`;
export const ACTIVATE_DEACTIVATE_NOTIFICATIONS = `${BASE_API_URL}/notifications/activate_deactivate_notification`;

//taks notes

export const TASK_NOTES = `${BASE_API_URL}/tasks/notes/`;
export const TASK_NOTES_UPDATE = `${BASE_API_URL}/tasks/notes/:noteId/`;
export const LEAD_TASK = `${BASE_API_URL}/tasks/tasks/ `;
export const LEAD_UPDATE_TASK = `${BASE_API_URL}/tasks/tasks/:taskId/`;
export const LEAD_UPDATE_TASK_STATUS = `${BASE_API_URL}/tasks/tasks/status/`;

// contact modal questions
export const GET_ALL_QUESTIONS = `${BASE_API_URL}/questions/questions`;
export const REMOVE_QUESTION = `${BASE_API_URL}/questions/questions/:questId`;
export const UPDATE_QUESTION = `${BASE_API_URL}/questions/questions/:questId`;
export const UPDATE_CHOICE_SET = `${BASE_API_URL}/questions/update_choice/:choiceId`;

// contact modal answers
export const GET_ALL_ANSWERS = `${BASE_API_URL}/questions/answers/`;
export const UPDATE_ANSWER = `${BASE_API_URL}/questions/answers/:answerId/`;

// update contact details
export const UPDATE_CONTACT_DETAILS = `${BASE_API_URL}/leads/update_contact/:leadId`;

// file library
export const GET_FILE_LIBRARY_LIST = `${BASE_API_URL}/file_library/add_file_to_library`;
export const UPDATE_DELETE_FILE = `${BASE_API_URL}/file_library/add_file_to_library/:fileId`;
export const GET_FILE_ATTACHED_TO_LEAD = `${BASE_API_URL}/file_library/get_lead_attachments/:leadId`;

// feed task
export const FEED_TASK = `${BASE_API_URL}/tasks/tasks/`;

// Box Settings cardDesign
export const UPDATE_CARD_DESIGN = `${BASE_API_URL}/box/card_design/:boxId`;
export const GET_CARD_DESIGN = `${BASE_API_URL}/box/card_design/:boxId`;

//Business Info settings
export const GET_ALL_CURRENCIES = `${BASE_API_URL}/business_infos/get_all_the_currencies`;
export const GET_BUSINESS_INFOS = `${BASE_API_URL}/business_infos/sub_organizations/:subOrgId/get_the_business_info`;
export const UPDATED_BUSINESS_INFO = `${BASE_API_URL}/business_infos/sub_organizations/:subOrgId/business_info_update`;
export const GET_BACKGROUNG_COLOR = `${BASE_API_URL}/business_infos/sub_organizations/:subOrgId/get_the_brand_color`;
// Box settings BoxContacts
export const UPLOAD_CONTACTS_CSV = `${BASE_API_URL}/leads/import_leads/boxs/:boxId/upload`;
export const EXPORT_CSV_VIA_MAIL = `${BASE_API_URL}/box/sub_organisations/:subOrgId/boxs/:boxId/download_leads`;
export const GET_ALL_UPLOADED_LEADS_COUNT = `${BASE_API_URL}/box/boxs/:boxId/get_number_of_leads`;
export const GET_MAPPING_FIELD_FROM_CSV_FILE = `${BASE_API_URL}/leads/import_leads/lead_from_csvs/:uploadeFileId/mapping_fields`;
export const GET_MODAL_FIELDS_FOR_CSV_MAPPING = `${BASE_API_URL}/leads/import_leads/sub_organizations/:subOrgId/get_the_question_fields_for_csv_mapping`;
export const FINAL_MAPPING = `${BASE_API_URL}/leads/import_leads/lead_from_csvs/:uploadeFileId/map`;
export const GET_FILE_UPLOAD_HISTORY = `${BASE_API_URL}/leads/import_leads/boxs/:boxId/history`;
export const REVERT_LEAD = `${BASE_API_URL}/leads/import_leads/lead_from_csvs/:uploadeFileId/roll_back`;
// delete all the leads in bin
export const GET_ALL_BIN_LEADS = `${BASE_API_URL}/leads/sub_organizations/:subOrgId/bins/leads`;
export const EMPTY_BIN = `${BASE_API_URL}/leads/sub_organizations/:subOrgId/bins`;

// Firebase
export const CREATE_FCM_DEVICE = `${BASE_API_URL}/notifications/create_fcm_device`;

// add box and box templates
export const GET_BOX_TEMPLATE = `${BASE_API_URL}/box/box_templates`;
export const CREATE_BOX_FORM_STRACH = `${BASE_API_URL}/box/sub_organisations/:subOrgId/create_a_box`;

// Search Leads
export const SEARCH_LEADS = `${BASE_API_URL}/leads/search_leads/:subOrgId`;

// Signature APIs
export const GET_ACTIVE_SIGNATURE = `${BASE_API_URL}/emails/email_signatures/active`;
export const GET_DEFAULT_SIGNATURE = `${BASE_API_URL}/emails/email_signatures/get_default`;
export const GET_CUSTOM_SIGNATURE = `${BASE_API_URL}/emails/email_signatures/get_custom`;
export const UPDATE_SIGNATURE = `${BASE_API_URL}/emails/email_signatures/:signId`;
//Nudges
export const GET_OR_UPDATE_NUDEGES = `${BASE_API_URL}/organization/get_or_create_nudges?user_id=:userId`;

// api for copy box data
export const COPY_FROM_EXISTING_BOX = `${BASE_API_URL}/box/copy_from_existing_box`;

// box stage settings
export const UPDATE_BOX_STAGE_POSITION = `${BASE_API_URL}/box/update_stage_position_in_box/stages/:stageId`;

// list tasks, filter tasks
export const GET_LIST_ENQUIRIES_WITH_TASKS = `${BASE_API_URL}/leads/sub_organizations/:subOrgId/list_of_enquires_with_tasks`;

//list countries
export const GET_COUNTRY_LIST = `${BASE_API_URL}/countries/get_countries_list`;

// get country phone prefix of organization
export const GET_ORGANIZATION_PHONE_PREFIX = `${BASE_API_URL}/organization/sub_organizations/:subOrgId/get_public_contact_us_form_details`;

//organization plan signup
export const ORGANIZATION_PLAN_SIGNUP = `${BASE_API_URL}/plans/sub_organizations/:subOrgId/organization_plan_signup`;

export const SUBSCRIPTION_UPGRADE_PLAN = `${BASE_API_URL}/plans/upgrade_plan/:subOrgId`;

export const SUBSCRIPTION_CONTINUE_PLAN = `${BASE_API_URL}/plans/continue_or_free_plan/:subOrgId`;

export const SUBSCRIPTION_STRIPE_REDIRECT = `${BASE_API_URL}/plans/get_stripe_link/:subOrgId`;

// get the enquiries and task count
export const GET_ENQUIRY_TASK_COUNT = `${BASE_API_URL}/leads/sub_organizations/:subOrgId/things_to_do/get_the_count_enquiry_and_task`;

export const GET_THINGSTODO_ENQUIRY_COUNT = `${BASE_API_URL}/leads/sub_organizations/:subOrgId/things_to_do/get_the_enquiry_count`;

export const GET_THINGSTODO_TASK_COUNT = `${BASE_API_URL}/leads/sub_organizations/:subOrgId/things_to_do/get_the_task_count`;

export const SUBSCRIPTION_DELETE_ACCOUNT = `${BASE_API_URL}/plans/delete_account/:subOrgId`;

// assign plan to user
export const ASSIGN_PLAN_TO_USER = `${BASE_API_URL}/users/create_user_plan_constraints`;

// hide channel introduction video
export const HIDE_EBOX_CHANNEL_INTRODUCTION_VIDEO = `${BASE_API_URL}/box/hide_tutorial_video`;

// to create labels for box
export const CREATE_OR_UPDATE_BOX_LABELS = `${BASE_API_URL}/box/create_boxlabels/:subOrgId`;

// api to mark all overdue task as completed
export const MARK_ALL_OVERDUE_TASK_AS_COMPLETED = `${BASE_API_URL}/leads/sub_organizations/:subOrgId/bulk-update-overdue-tasks`;
// api to update the AI draft reply
export const UPDATE_AI_DRAFT_REPLY = `${BASE_API_URL}/ai_services/set_smart_draft`;

// toggle connected channel
export const TOGGLE_CONNECTED_CHANNEL = `${BASE_API_URL}/channels/sub_organizations/:subOrgId/connected_channels/:channelId`;

// selected location in channel settings (sms,insta,fb,whatsapp,enquirybot)
export const SELECTED_LOCATION_CHANNEL_SETTINGS = `${BASE_API_URL}/channels/sub_organizations/:subOrgId/connected_channels/:channelId/location `;

//update contract value
export const UPDATE_CONTRACT_VALUE = `${BASE_API_URL}/leads/sub_organization/:subOrgId/update_lead_contract_value`;

// update lead close or reopen status
export const UPDATE_LEAD_CLOSE_REOPEN_STATUS = `${BASE_API_URL}/leads/sub_organization/:subOrgId/close_or_open_lead`;
