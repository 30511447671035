import { createSlice } from '@reduxjs/toolkit';
import {
  ADD_OR_EDIT_PRACTIONER,
  ASSIGN_PRACTITIONER_TO_LEAD,
  GET_PRACTITIONER,
  HIDE_HELP_TEXT_PRACTITIONER
} from 'urls/practitioner';
import APIRequest from 'apiRequest';
import { replaceUrl } from 'utils/urlReplace';
import { setIsHidePractionerGuide } from 'slices/myInfoSlice';
import { setActiveLead } from 'slices/conversationSlice';
import { setStageLeads } from 'slices/boxes/slice';
import { setAllLeads } from 'slices/eboxLead';

const practitionersSlice = createSlice({
  name: 'practitioners',
  initialState: {
    practitioners: [],
    selectedPractitioners: [],
    showAddPractitionerModal: false,
    locationBasedPractitioners: []
  },
  reducers: {
    setPractitioners: (state, action) => {
      state.practitioners = action.payload;
    },
    setSelectedPractitioners: (state, action) => {
      state.selectedPractitioners = action.payload;
    },
    SetShowAddPractitionerModal: (state, action) => {
      state.showAddPractitionerModal = action.payload;
      /*  This state we are using while adding practioner from
      contact modal section where we can not handle with local state
      */
    }
  }
});

export const {
  setPractitioners,
  setSelectedPractitioners,
  SetShowAddPractitionerModal
} = practitionersSlice.actions;

export default practitionersSlice.reducer;

// function to create practioner
export const createPractioner = (payload) => {
  return (dispatch, getState) => {
    const { data, successCb } = payload;
    const subOrgId = getState().myInfo.subOrganizationId;
    const practioners = getState().practitioners.practitioners;
    try {
      new APIRequest()
        .post(replaceUrl(ADD_OR_EDIT_PRACTIONER, 'subOrgId', subOrgId), data)
        .then((res) => {
          if (res.status === 201) {
            if (successCb) successCb();
            dispatch(setPractitioners([...practioners, res.data]));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getPractitioners = (payload = {}) => {
  return (dispatch, getState) => {
    const { successCb, location, isFromBoxFilter } = payload;
    try {
      const subOrgId = getState().myInfo.subOrganizationId;
      new APIRequest()
        .get(replaceUrl(GET_PRACTITIONER, 'subOrgId', subOrgId), { location })
        .then((res) => {
          if (isFromBoxFilter) {
            dispatch(setPractitioners(res.data));
          } else {
            const practioners = res?.data?.filter((item) => !item.is_deleted);
            dispatch(setPractitioners(practioners));
          }
          if (successCb) successCb();
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const editPractioner = (payload) => {
  return (dispatch, getState) => {
    const { data, successCb } = payload;
    const subOrgId = getState().myInfo.subOrganizationId;
    const practioners = getState().practitioners.practitioners;
    try {
      new APIRequest()
        .put(replaceUrl(ADD_OR_EDIT_PRACTIONER, 'subOrgId', subOrgId), data)
        .then((res) => {
          if (res.status === 200) {
            if (successCb) successCb();
            const updatedPractioners = practioners.map((item) =>
              item.id === data.id ? res.data : item
            );
            dispatch(setPractitioners(updatedPractioners));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const deletePractitioners = (payload) => {
  return (dispatch, getState) => {
    const practioners = getState().practitioners.practitioners;
    const { data, successCb, failedCb } = payload;
    try {
      const subOrgId = getState().myInfo.subOrganizationId;
      new APIRequest()
        .delete(replaceUrl(ADD_OR_EDIT_PRACTIONER, 'subOrgId', subOrgId), data)
        .then((res) => {
          const updatedPractioners = practioners.filter(
            (item) => item.id !== data.id
          );
          dispatch(setPractitioners(updatedPractioners));
          if (successCb) successCb();
        })
        .catch((e) => {
          if (failedCb) failedCb();
        });
    } catch (e) {
      console.log(e);
      if (failedCb) failedCb();
    }
  };
};

export const hidePractitionerHelpText = (payload) => {
  return (dispatch, getState) => {
    try {
      const userId = getState().myInfo?.myInformation?.id;
      new APIRequest()
        .patch(
          replaceUrl(HIDE_HELP_TEXT_PRACTITIONER, 'userId', userId),
          payload
        )
        .then((res) => {
          dispatch(setIsHidePractionerGuide(true));
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// function to assign or unassign practitioner to lead
export const assignPractitioner = (payload) => {
  return (dispatch, getState) => {
    const { data, successCb, isInBox } = payload;
    const subOrgId = getState().myInfo.subOrganizationId;
    const activeLead = getState().leadConversation.activeLead;
    let stageLeads, allLeads;
    if (isInBox) stageLeads = getState().boxes.stageLeads;
    else allLeads = getState().leads.allLeads;

    try {
      new APIRequest()
        .post(
          replaceUrl(ASSIGN_PRACTITIONER_TO_LEAD, 'subOrgId', subOrgId),
          data
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(
              setActiveLead({
                ...activeLead,
                practitioner: {
                  name: data.practitionerName,
                  id: data.practitioner_id
                }
              })
            );
            if (isInBox) {
              // if user is in box update the active lead practitioner in the stage leads
              dispatch(
                setStageLeads({
                  ...stageLeads,
                  [activeLead?.box_stage]: {
                    ...stageLeads[activeLead?.box_stage],
                    results: stageLeads[activeLead?.box_stage].results.map(
                      (result) =>
                        result.id === activeLead?.id
                          ? {
                              ...result,
                              practitioner: {
                                name: data.practitionerName,
                                id: data.practitioner_id
                              }
                            }
                          : result
                    )
                  }
                })
              );
            } else {
              // if user is in thingstodo update the active lead practitioner in the leads
              dispatch(
                setAllLeads({
                  ...allLeads,
                  results: allLeads?.results?.map((result) =>
                    result.id === activeLead?.id
                      ? {
                          ...result,
                          practitioner: {
                            name: data.practitionerName,
                            id: data.practitioner_id
                          }
                        }
                      : result
                  )
                })
              );
            }
            if (successCb) successCb();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
