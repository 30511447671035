import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';

export const analytics = Analytics({
  app: 'enquirybox',
  plugins: [
    segmentPlugin({
      writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY
    })
  ]
});
