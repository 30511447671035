/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { configureStore, createAsyncThunk } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';

import reducers, { whitelist, getMiddleWare } from './reducers';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { createTransform } from 'redux-persist';

// Transform for persisting only the subOrgId key from myInfo slice
const myInfoTransform = createTransform(
  (inboundState) => ({
    subOrganizationId: inboundState.subOrganizationId,
    userId: inboundState.userId,
    isMultiSubOrg: inboundState.isMultiSubOrg,
    showOrgUserLogOutModal: inboundState.showOrgUserLogOutModal,
    showUserPermissionRemovedModal: inboundState.showUserPermissionRemovedModal
  }),
  (outboundState) => ({ ...outboundState }),
  { whitelist: ['myInfo'] }
);

const persistConfig = {
  key: 'root',
  storage,
  whitelist,
  transforms: [myInfoTransform]
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getMiddleWare
});

export const clearStore = createAsyncThunk(
  'auth/logout',
  async function (_payload) {}
);

const persistor = persistStore(store);
setupListeners(store.dispatch);

export default function AppProvider({ children }) {
  useEffect(() => {
    if (typeof window !== 'undefined' && window) {
      window.__STORE__ = store;
    }
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
}
