import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import {
  ADD_OR_UPDATE_OR_DELETE_LEAD_SOURCES,
  ASSIGNE_LEADSOURCE_TO_LEAD
} from 'urls/accountSettings/laedSources';
import { replaceUrl } from 'utils/urlReplace';

export const leadSourceSlice = createSlice({
  name: 'leadSource',
  initialState: {
    leadSources: [],
    selectedLeadSourcesInFilter: []
  },
  reducers: {
    setLeadSources: (state, action) => {
      state.leadSources = action.payload;
    },
    setSelectedLeadSourcesInFilter: (state, action) => {
      state.selectedLeadSourcesInFilter = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLeaSourceData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLeaSourceData.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getLeaSourceData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const getLeaSourceData = createAsyncThunk(
  'leadSources/getLeaSourceData',
  async (_, { dispatch, getState, rejectWithValue }) => {
    const sub_org_id = getState().myInfo.subOrganizationId;
    try {
      const response = await new APIRequest().get(
        replaceUrl(ADD_OR_UPDATE_OR_DELETE_LEAD_SOURCES, 'subOrgId', sub_org_id)
      );
      dispatch(setLeadSources(response?.data));
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const postLeaSourceData = createAsyncThunk(
  'leadSources/postLeaSourceData',
  async (data, { getState, rejectWithValue }) => {
    const sub_org_id = getState().myInfo.subOrganizationId;
    try {
      const response = await new APIRequest().post(
        replaceUrl(
          ADD_OR_UPDATE_OR_DELETE_LEAD_SOURCES,
          'subOrgId',
          sub_org_id
        ),
        data
      );
      return response?.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const updateLeaSourceData = createAsyncThunk(
  'leadSources/updateLeaSourceData',
  async (payLoad, { getState, rejectWithValue }) => {
    const sub_org_id = getState().myInfo.subOrganizationId;
    try {
      const response = await new APIRequest().put(
        replaceUrl(
          ADD_OR_UPDATE_OR_DELETE_LEAD_SOURCES,
          'subOrgId',
          sub_org_id
        ),
        payLoad
      );
      return response?.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const deleteLeaSourceData = createAsyncThunk(
  'leadSources/deleteLeaSourceData',
  async (payLoad, { getState, rejectWithValue }) => {
    const sub_org_id = getState().myInfo.subOrganizationId;
    try {
      const response = await new APIRequest().delete(
        replaceUrl(
          ADD_OR_UPDATE_OR_DELETE_LEAD_SOURCES,
          'subOrgId',
          sub_org_id
        ),
        payLoad
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const assigneLeaSourceDataToLead = createAsyncThunk(
  'leadSources/assigneLeaSourceDataToLead',
  async (payLoad, { getState, rejectWithValue }) => {
    const sub_org_id = getState().myInfo.subOrganizationId;
    try {
      const response = await new APIRequest().post(
        replaceUrl(ASSIGNE_LEADSOURCE_TO_LEAD, 'subOrgId', sub_org_id),
        payLoad
      );
      return response?.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const { setLeadSources, setSelectedLeadSourcesInFilter } =
  leadSourceSlice.actions;

export default leadSourceSlice.reducer;
