/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import { replaceUrl } from 'utils/urlReplace';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GET_ALL_BOX,
  GET_STAGE_LEADS,
  CREATE_UPDATE_BOX_FORM,
  GET_BOX_FORM_DATA,
  GET_BOX_DATA,
  CREATE_MANUAL_ENQUIRY_BOX_FORM,
  ADD_STAGE,
  UPDATE_STAGE,
  MOVE_TO_STAGE_API,
  DELETE_STAGE,
  GET_STAGE_CONTRACT_VALUE,
  DELETE_BOX,
  RENAME_BOX,
  GET_ALL_STAGE_LEAD_COUNT,
  GET_REPLY_LATER_STAGE_LEADS,
  DOWNLOAD_LEADS_BEFORE_DELETE,
  GET_BOX_NOTIFICATION_OF_USER,
  UPDATE_BOX_NOTIFICATION_OF_USER,
  RE_ORDER_BOX,
  UPDATE_BOX_DATA,
  HIDE_VIDEO,
  DISMISS_DUMMY_LEADS,
  UPDATE_TOGGLE_CHECKLIST,
  ADD_CHECKLIST_FIELD_TO_STAGE,
  GET_BOX_PERMISSION_NOTIFICATION_OF_USER,
  GET_BOX_TEMPLATES,
  CRETATE_BOX_FROM_DENTAL_TEMPLATES,
  DELETE_SAMPLE_LEADS
} from 'urls/boxes';
import {
  LEAD_UPDATE_STATUS,
  UPDATE_BOX_STAGE_POSITION,
  UPDATE_CONTACT_DETAILS
} from 'urls';
import { getIdAndType } from 'modules/boxes/helper';
import { isEmpty } from 'utils/utils';
import { setActiveLead } from 'slices/conversationSlice';

import choices from 'choices';
import { setMyInformation } from 'slices/myInfoSlice';
import _ from 'lodash';

const {
  REPLY_LATER,
  NEW,
  VIEWED,
  TASK_ADDED,
  REPLY_SENT,
  MANUALLY_ADDED,
  MOVE_TO_BOX,
  NOTE_ADDED,
  RESTORED
} = choices.LeadStatusChoices;

const initialState = {
  currentBoxCardList: 0, // to know the lead is in which array
  activeBoxNewLead: null,
  currentBoxId: null,
  boxes: [],
  stageLeads: {},
  replyLaterLeads: {},
  currentBox: {},
  isFiltered: false,
  usersSelected: [],
  channelsSelected: [],
  boxStagesLeadCount: [],
  labelsSelected: [],
  isAppliedFilter: false,
  boxFormData: {},
  activeBoxData: {},
  loadEnquiryInStage: false,
  replyLaterDataLength: null,
  stageData: {},
  isOpenReplyLaterInBox: {},
  isNewStageAdded: false,
  activeLeadIndex: null,
  searchClickedLeadStatus: null,
  searchClickedLead: null,
  searchClickedLeadStage: null,
  activeTab: 0,
  isOpenCheckList: false,
  selectedCheckListFields: [],
  checkListedStageId: null,
  tempSelectedCheckListFields: [],
  isShowCheckListedFields: false,
  activeCheckListStage: null,
  tempMoveToData: {},
  isDropping: false,
  dragLeadId: null,
  selectedBox: {},
  isEditStageCheckList: false,
  dragStageId: null,
  isMovingFromNudge: false,
  dentelBoxTemplates: [],
  resetFilterState: false,
  isRefetchStageLeads: false,
  isIncludeClosedLeadsInBox: false,
  isLeadReopened: false
};

export const boxesSlice = createSlice({
  name: 'boxes',
  initialState,
  reducers: {
    setCurrentBoxCardList: (state, action) => {
      state.currentBoxCardList = action.payload;
    },
    setCurrentBoxId: (state, action) => {
      state.currentBoxId = action.payload;
    },
    setActiveBoxNewLead: (state, action) => {
      state.activeBoxNewLead = action.payload;
    },
    setBoxes: (state, action) => {
      state.boxes = action.payload;
    },
    setStageLeads: (state, action) => {
      state.stageLeads = action.payload;
    },
    setCurrentBox: (state, action) => {
      state.currentBox = action.payload;
    },
    setIsFiltered: (state, action) => {
      state.isFiltered = action.payload;
    },
    setUsersSelected: (state, action) => {
      state.usersSelected = action.payload;
    },
    setChannelsSelected: (state, action) => {
      state.channelsSelected = action.payload;
    },
    setLabelsSelected: (state, action) => {
      state.labelsSelected = action.payload;
    },
    setIsAppliedFilter: (state, action) => {
      state.isAppliedFilter = action.payload;
    },
    setBoxFormData: (state, action) => {
      state.boxFormData = action.payload;
    },
    setActiveBoxData: (state, action) => {
      state.activeBoxData = action.payload;
    },
    setLoadEnquiryInStage: (state, action) => {
      state.loadEnquiryInStage = action.payload;
    },
    setBoxStagesLeadCount: (state, action) => {
      state.boxStagesLeadCount = action.payload;
    },
    setReplyLaterLeads: (state, action) => {
      state.replyLaterLeads = action.payload;
    },
    setReplyLaterDataLength: (state, action) => {
      state.replyLaterDataLength = action.payload;
    },
    setStageData: (state, action) => {
      state.stageData = action.payload;
    },
    setIsOpenReplyLaterInBox: (state, action) => {
      state.isOpenReplyLaterInBox = action.payload;
    },
    setIsNewStageAdded: (state, action) => {
      state.isNewStageAdded = action.payload;
    },
    setActiveLeadIndex: (state, action) => {
      state.activeLeadIndex = action.payload;
    },
    setSearchClickedLeadStatus: (state, action) => {
      state.searchClickedLeadStatus = action.payload;
    },
    setSearchClickedLead: (state, action) => {
      state.searchClickedLead = action.payload;
    },
    setSearchClickedLeadStage: (state, action) => {
      state.searchClickedLeadStage = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setIsOpenCheckList: (state, action) => {
      state.isOpenCheckList = action.payload;
    },
    setSelectedCheckListFields: (state, action) => {
      state.selectedCheckListFields = action.payload;
    },
    setCheckListedStageId: (state, action) => {
      state.checkListedStageId = action.payload;
    },
    setTempSelectedCheckListFields: (state, action) => {
      state.tempSelectedCheckListFields = action.payload;
    },
    setIsShowCheckListedFields: (state, action) => {
      state.isShowCheckListedFields = action.payload;
    },
    setActiveCheckListStage: (state, action) => {
      state.activeCheckListStage = action.payload;
    },
    setTempMoveToData: (state, action) => {
      state.tempMoveToData = action.payload;
    },
    setIsDropping: (state, action) => {
      state.isDropping = action.payload;
    },
    setDragLeadId: (state, action) => {
      state.dragLeadId = action.payload;
    },
    setSelectedBox: (state, action) => {
      state.selectedBox = action.payload;
    },
    setIsEditStageCheckList: (state, action) => {
      state.isEditStageCheckList = action.payload;
    },
    setDragStageId: (state, action) => {
      state.dragStageId = action.payload;
    },
    setIsMovingFromNudge: (state, action) => {
      state.isMovingFromNudge = action.payload;
    },
    setDentalBoxTemplates: (state, action) => {
      state.dentelBoxTemplates = action.payload;
    },
    setResetFilterState: (state, action) => {
      state.resetFilterState = action.payload;
    },
    setIsRefetchStageLeads: (state, action) => {
      state.isRefetchStageLeads = action.payload;
    },
    setIsIncludeClosedLeadsInBox: (state, action) => {
      state.isIncludeClosedLeadsInBox = action.payload;
    },
    setIsLeadReopened: (state, action) => {
      state.isLeadReopened = action.payload;
    }
  }
});

export const {
  setCurrentBoxCardList,
  setBoxes,
  setStageLeads,
  setIsFiltered,
  setUsersSelected,
  setChannelsSelected,
  setLabelsSelected,
  setIsAppliedFilter,
  setBoxFormData,
  setActiveBoxData,
  setCurrentBox,
  setActiveBoxNewLead,
  setCurrentBoxId,
  setBoxStagesLeadCount,
  setLoadEnquiryInStage,
  setReplyLaterLeads,
  setReplyLaterDataLength,
  setStageData,
  setIsOpenReplyLaterInBox,
  setIsNewStageAdded,
  setActiveLeadIndex,
  setSearchClickedLeadStatus,
  setSearchClickedLead,
  setSearchClickedLeadStage,
  setActiveTab,
  setIsOpenCheckList,
  setSelectedCheckListFields,
  setCheckListedStageId,
  setTempSelectedCheckListFields,
  setIsShowCheckListedFields,
  setActiveCheckListStage,
  setTempMoveToData,
  setIsDropping,
  setDragLeadId,
  setSelectedBox,
  setIsEditStageCheckList,
  setDragStageId,
  setIsMovingFromNudge,
  setDentalBoxTemplates,
  setResetFilterState,
  setIsRefetchStageLeads,
  setIsIncludeClosedLeadsInBox,
  setIsLeadReopened
} = boxesSlice.actions;

export default boxesSlice.reducer;

// to get all boxes of the organization
export const getAllBoxes = (successCb) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      await new APIRequest()
        .get(replaceUrl(GET_ALL_BOX, 'subOrgId', subOrgId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setBoxes(res.data.results));
            if (successCb) {
              successCb();
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const renameBox = (payload, successCb) => {
  return async (dispatch, getState) => {
    const { data, box_id } = payload;
    const {
      boxes: { boxes }
    } = getState();
    try {
      await new APIRequest()
        .put(replaceUrl(RENAME_BOX, 'boxId', box_id), data)
        .then((res) => {
          if (res.status === 200) {
            const updatedBoxes = boxes.map((box) => {
              if (box.id === res.data.id) {
                return { ...box, ...res.data };
              }
              return box;
            });
            dispatch(setBoxes(updatedBoxes));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const deleteBox = (payload, onSuccessDelete) => {
  return async () => {
    const { boxId } = payload;
    try {
      await new APIRequest()
        .delete(replaceUrl(DELETE_BOX, 'boxId', boxId))
        .then((res) => {
          if (res.status === 200) {
            onSuccessDelete(boxId);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const downloadLeadsBeforeDeleteBox = (payload, onSuccess) => {
  return async () => {
    const { subOrgId, boxId } = payload;
    try {
      await new APIRequest()
        .post(
          replaceUrl(
            DOWNLOAD_LEADS_BEFORE_DELETE,
            ['subOrgId', 'boxId'],
            [subOrgId, boxId]
          )
        )
        .then(() => {
          if (onSuccess) {
            onSuccess();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// to get stage leads
export const getStageLeads = (payload) => {
  return async (dispatch, getState) => {
    const { stageId, pageNumber, query = {} } = payload;
    const assigned_user = getState().boxes.usersSelected;
    const channel = getState().boxes.channelsSelected;
    const labels = getState().boxes.labelsSelected;
    const selectedLabels = getState().locationLabels.selectedLocationLabels;
    const selectedLeadSourcesInFilter =
      getState().leadSources.selectedLeadSourcesInFilter;
    const practitioners = getState().practitioners.selectedPractitioners;
    const isIncludeClosedLeadsInBox =
      getState().boxes.isIncludeClosedLeadsInBox;
    const params = {
      assigned_user: assigned_user,
      channel: channel,
      label: labels,
      practitioner: practitioners,
      location_ids: selectedLabels,
      page: pageNumber,
      source: selectedLeadSourcesInFilter,
      filter_by: 1,
      include_closed_leads: isIncludeClosedLeadsInBox
    };
    try {
      return await new APIRequest().get(
        replaceUrl(GET_STAGE_LEADS, 'stageId', stageId),
        {
          ...params,
          ...query
        }
      );
    } catch (e) {
      console.log(e);
    }
  };
};

// this function is called to compose all stage data when it is called multiple times
export const composeStageLeads = (payload) => {
  return async (dispatch, getState) => {
    const { stageId, results, isBin = false } = payload;
    const stageLeads = getState().boxes.stageLeads;
    const replyLaterCount = results?.reply_later_count;
    const stageData = getState().boxes.stageData;
    const isOpenReplyLaterInBox = getState().boxes.isOpenReplyLaterInBox;
    const obj = new Object();
    obj[stageId] = results;
    await dispatch(
      setStageLeads({
        ...stageLeads,
        ...obj
      })
    );

    // this is the state of the stage data
    await dispatch(
      setStageData({
        ...stageData,
        seen_and_new: {
          ...stageData.seen_and_new,
          [stageId]: {
            next: results.next,
            current_page: results.current_page,
            total_count: results.total_count,
            total_pages: results.total_pages,
            exclude_lead_ids: []
          }
        },
        reply_later: {
          ...stageData.reply_later,
          [stageId]: {
            next: replyLaterCount > 10 ? 2 : 1,
            current_page: replyLaterCount >= 1 ? 1 : null,
            total_count: results.reply_later_count,
            total_pages: Math.ceil(replyLaterCount / 10),
            exclude_lead_ids: []
          }
        }
      })
    );

    if (!isBin) {
      // create state for open reply later data
      const openReplyLaterObj = new Object();
      openReplyLaterObj[stageId] = { status: false };

      await dispatch(
        setIsOpenReplyLaterInBox({
          ...isOpenReplyLaterInBox,
          ...openReplyLaterObj
        })
      );
    }
  };
};

// to get reply later leads
export const getReplyLaterStageLeads = (payload) => {
  return async (dispatch, getState) => {
    const { stageId, pageNumber, query = {} } = payload;
    const assigned_user = getState().boxes.usersSelected;
    const channel = getState().boxes.channelsSelected;
    const labels = getState().boxes.labelsSelected;
    const params = {
      assigned_user: assigned_user,
      channel: channel,
      label: labels,
      page: pageNumber,
      filter_by: 3
    };
    try {
      return await new APIRequest().get(
        replaceUrl(GET_REPLY_LATER_STAGE_LEADS, 'stageId', stageId),
        {
          ...params,
          ...query
        }
      );
    } catch (e) {
      console.log(e);
    }
  };
};

// this function is called to compose reply later data when it is called multiple times
export const composeReplyLaterStageLeads = (payload) => {
  return async (dispatch, getState) => {
    const { stageId, results } = payload;
    const replyLaterLeads = getState().boxes.replyLaterLeads;
    const stageData = getState().boxes.stageData;
    const obj = new Object();
    obj[stageId] = results;
    await dispatch(
      setReplyLaterLeads({
        ...replyLaterLeads,
        ...obj
      })
    );

    await dispatch(
      setStageData({
        ...stageData,
        seen_and_new: {
          ...stageData.seen_and_new
        },
        reply_later: {
          ...stageData.reply_later,
          [stageId]: {
            next: results.next,
            current_page: results.current_page,
            total_count: results.total_count,
            total_pages: results.total_pages,
            exclude_lead_ids: []
          }
        }
      })
    );
  };
};

// this function is called when we load the next page of seen and new leads
export const getStageLeadsOnLoadMore = (payload) => {
  return async (dispatch, getState) => {
    const { stageId, pageNumber, query = {} } = payload;
    const assigned_user = getState().boxes.usersSelected;
    const channel = getState().boxes.channelsSelected;
    const labels = getState().boxes.labelsSelected;
    const selectedLabels = getState().locationLabels.selectedLocationLabels;
    const isIncludeClosedLeadsInBox =
      getState().boxes.isIncludeClosedLeadsInBox;
    const previousExcludedIds =
      getState().boxes.stageData.seen_and_new[stageId].exclude_lead_ids;
    const params = {
      assigned_user: assigned_user,
      channel: channel,
      location_ids: selectedLabels,
      label: labels,
      page: pageNumber,
      filter_by: 1,
      include_closed_leads: isIncludeClosedLeadsInBox
    };
    if (!isEmpty(previousExcludedIds)) {
      params['exclude_lead_ids'] = previousExcludedIds;
    }
    try {
      return await new APIRequest().get(
        replaceUrl(GET_STAGE_LEADS, 'stageId', stageId),
        {
          ...params,
          ...query
        }
      );
    } catch (e) {
      console.log(e);
    }
  };
};

// this function is called to compose all stage data when loading next page
export const composeStageLeadsOnLoadMore = (payload) => {
  return async (dispatch, getState) => {
    const { stageId, results } = payload;
    const stageLeads = getState().boxes.stageLeads;
    const stageData = getState().boxes.stageData;

    const obj = new Object();
    obj[stageId] =
      isEmpty(stageLeads) || isEmpty(stageLeads[stageId])
        ? results
        : {
            ...results,
            results: [...stageLeads[stageId].results, ...results.results]
          };
    await dispatch(
      setStageLeads({
        ...stageLeads,
        ...obj
      })
    );

    await dispatch(
      setStageData({
        ...stageData,
        seen_and_new: {
          ...stageData.seen_and_new,
          [stageId]: {
            ...stageData.seen_and_new[stageId],
            next: results.next,
            current_page: results.current_page,
            total_count: results.total_count,
            total_pages: results.total_pages
          }
        },
        reply_later: {
          ...stageData.reply_later
        }
      })
    );
  };
};

// this function is called when we load the next page of reply later leads
export const getReplyLaterLeadsOnLoadMore = (payload) => {
  return async (dispatch, getState) => {
    const { stageId, pageNumber, query = {} } = payload;
    const assigned_user = getState().boxes.usersSelected;
    const channel = getState().boxes.channelsSelected;
    const selectedLabels = getState().locationLabels.selectedLocationLabels;
    const labels = getState().boxes.labelsSelected;
    const isIncludeClosedLeadsInBox =
      getState().boxes.isIncludeClosedLeadsInBox;
    const previousExcludedIds =
      getState().boxes.stageData.reply_later[stageId].exclude_lead_ids;
    const params = {
      assigned_user: assigned_user,
      channel: channel,
      location_ids: selectedLabels,
      label: labels,
      page: pageNumber,
      filter_by: 3,
      include_closed_leads: isIncludeClosedLeadsInBox
    };
    if (!isEmpty(previousExcludedIds)) {
      params['exclude_lead_ids'] = previousExcludedIds;
    }
    try {
      return await new APIRequest().get(
        replaceUrl(GET_REPLY_LATER_STAGE_LEADS, 'stageId', stageId),
        {
          ...params,
          ...query
        }
      );
    } catch (e) {
      console.log(e);
    }
  };
};

// this function is called to compose all reply later data when loading next page
export const composeReplyLaterStageLeadsonLoadMore = (payload) => {
  return async (dispatch, getState) => {
    const { stageId, results } = payload;
    const replyLaterLeads = getState().boxes.replyLaterLeads;
    const stageData = getState().boxes.stageData;
    const obj = new Object();
    obj[stageId] =
      isEmpty(replyLaterLeads) || isEmpty(replyLaterLeads[stageId])
        ? results
        : {
            ...results,
            results: [...replyLaterLeads[stageId].results, ...results.results]
          };
    await dispatch(
      setReplyLaterLeads({
        ...replyLaterLeads,
        ...obj
      })
    );
    const totalDisplayedCount = [
      ...replyLaterLeads[stageId].results,
      ...results.results
    ];
    dispatch(setReplyLaterDataLength(totalDisplayedCount.length));

    await dispatch(
      setStageData({
        ...stageData,
        seen_and_new: {
          ...stageData.seen_and_new
        },
        reply_later: {
          ...stageData.reply_later,
          [stageId]: {
            ...stageData.reply_later[stageId],
            next: results.next,
            current_page: results.current_page,
            total_count: results.total_count,
            total_pages: results.total_pages
          }
        }
      })
    );
  };
};

// this function is called when we drop the lead to seen and new section
export const getStageLeadsAfterMoving = (payload) => {
  return async (dispatch, getState) => {
    const { stageId, pageNumber, exclude_lead_ids, query = {} } = payload;
    const assigned_user = getState().boxes.usersSelected;
    const channel = getState().boxes.channelsSelected;
    const labels = getState().boxes.labelsSelected;
    const selectedLabels = getState().locationLabels.selectedLocationLabels;
    const leadSources = getState().leadSources.selectedLeadSourcesInFilter;
    const isIncludeClosedLeadsInBox =
      getState().boxes.isIncludeClosedLeadsInBox;
    const previousExcludedIds =
      getState().boxes.stageData.seen_and_new[stageId].exclude_lead_ids;
    const stageData = getState().boxes.stageData;
    const allIds = [...previousExcludedIds, exclude_lead_ids];
    const params = {
      assigned_user: assigned_user,
      channel: channel,
      label: labels,
      location_ids: selectedLabels,
      sources: leadSources,
      page: pageNumber,
      filter_by: 1,
      include_closed_leads: isIncludeClosedLeadsInBox,
      exclude_lead_ids: !isEmpty(previousExcludedIds)
        ? allIds
        : [exclude_lead_ids]
    };

    dispatch(
      setStageData({
        ...stageData,
        seen_and_new: {
          ...stageData.seen_and_new,
          [stageId]: {
            ...stageData.seen_and_new[stageId],
            total_count: stageData.seen_and_new[stageId].total_count + 1,
            total_pages: Math.ceil(
              (stageData.seen_and_new[stageId].total_count + 1) / 10
            ),
            exclude_lead_ids: allIds
          }
        },
        reply_later: {
          ...stageData.reply_later
        }
      })
    );
    try {
      return await new APIRequest().get(
        replaceUrl(GET_STAGE_LEADS, 'stageId', stageId),
        {
          ...params,
          ...query
        }
      );
    } catch (e) {
      console.log(e);
    }
  };
};

// this function is called to compose the new and seen leads after moving lead from this
export const composeSourceStageLeadsAfterMoving = (payload) => {
  return async (dispatch, getState) => {
    const { stageId, results } = payload;
    const stageLeads = getState().boxes.stageLeads;
    const stageData = getState().boxes.stageData;

    // if the clicked lead next page is not loaded ---> keep all the previous leads and add the last lead from the response

    const updatedResults = [
      ...stageLeads[stageId].results,
      results.results[results.results.length - 1]
    ];

    const UniqueData = _.uniqBy(updatedResults, 'id');

    await dispatch(
      setStageLeads({
        ...stageLeads,
        [stageId]: {
          all_section_lead_count: results.all_section_lead_count,
          contract_value: results.contract_value,
          current_page: results.current_page,
          next: results.next,
          previous: results.previous,
          reply_later_count: results.reply_later_count,
          results: UniqueData,
          total_count: results.total_count,
          total_pages: results.total_pages
        }
      })
    );

    await dispatch(
      setStageData({
        ...stageData,
        seen_and_new: {
          ...stageData.seen_and_new,
          [stageId]: {
            ...stageData.seen_and_new[stageId],
            next: results.next,
            current_page: results.current_page,
            total_count: results.total_count,
            total_pages: results.total_pages
          }
        },
        reply_later: {
          ...stageData.reply_later
        }
      })
    );
  };
};

// this function is called to compose the new and seen leads after moving lead from this if the next page is loaded
export const composeSourceStageLeadsIfNextPageLoaded = (payload) => {
  return async (dispatch, getState) => {
    const { stageId, results } = payload;
    const stageLeads = getState().boxes.stageLeads;
    const stageData = getState().boxes.stageData;

    // if the clicked lead page is not equal to current page
    // keep the leads untill the current page and first item of the current page remove other elements and add the new fetched leads
    const index = (results.current_page - 1) * 10 + 1;
    const updatedResults = [
      ...stageLeads[stageId].results.slice(0, index - 1),
      ...results.results
    ];
    const UniqueData = _.uniqBy(updatedResults, 'id');

    dispatch(
      setStageLeads({
        ...stageLeads,
        [stageId]: {
          all_section_lead_count: results.all_section_lead_count,
          contract_value: results.contract_value,
          current_page: results.current_page,
          next: results.next,
          previous: results.previous,
          reply_later_count: results.reply_later_count,
          results: UniqueData,
          total_count: results.total_count,
          total_pages: results.total_pages
        }
      })
    );

    dispatch(
      setStageData({
        ...stageData,
        seen_and_new: {
          ...stageData.seen_and_new,
          [stageId]: {
            ...stageData.seen_and_new[stageId],
            next: results.next,
            current_page: results.current_page,
            total_count: results.total_count,
            total_pages: results.total_pages
          }
        },
        reply_later: {
          ...stageData.reply_later
        }
      })
    );
  };
};

// this function is called when we drop the lead to reply later section
export const getReplyLaterStageLeadsAfterMoving = (payload) => {
  return async (dispatch, getState) => {
    const { stageId, pageNumber, exclude_lead_ids, query = {} } = payload;
    const assigned_user = getState().boxes.usersSelected;
    const channel = getState().boxes.channelsSelected;
    const labels = getState().boxes.labelsSelected;
    const leadSources = getState().leadSources.selectedLeadSourcesInFilter;
    const previousExcludedIds =
      getState().boxes.stageData.reply_later[stageId].exclude_lead_ids;
    const stageData = getState().boxes.stageData;
    const allIds = [...previousExcludedIds, exclude_lead_ids];
    const params = {
      assigned_user: assigned_user,
      channel: channel,
      label: labels,
      page: pageNumber,
      filter_by: 3,
      sources: leadSources,
      exclude_lead_ids: !isEmpty(previousExcludedIds)
        ? allIds
        : [exclude_lead_ids]
    };
    dispatch(
      setStageData({
        ...stageData,
        seen_and_new: {
          ...stageData.seen_and_new
        },
        reply_later: {
          ...stageData.reply_later,
          [stageId]: {
            ...stageData.reply_later[stageId],
            exclude_lead_ids: allIds
          }
        }
      })
    );
    try {
      return await new APIRequest().get(
        replaceUrl(GET_REPLY_LATER_STAGE_LEADS, 'stageId', stageId),
        {
          ...params,
          ...query
        }
      );
    } catch (e) {
      console.log(e);
    }
  };
};

// this function is called to compose the reply later leads after moving lead from this if the next page is not loaded
export const composeSourceReplyLaterLeadsAfterMoving = (payload) => {
  return async (dispatch, getState) => {
    const { stageId, results } = payload;
    const replyLaterLeads = getState().boxes.replyLaterLeads;
    const stageData = getState().boxes.stageData;

    // if the clicked lead next page is not loaded ---> keep all the previous leads and add the last lead from the response
    const updatedResults = [
      ...replyLaterLeads[stageId].results,
      results.results[results.results.length - 1]
    ];
    const UniqueData = _.uniqBy(updatedResults, 'id');
    await dispatch(
      setReplyLaterLeads({
        ...replyLaterLeads,
        [stageId]: {
          all_section_lead_count: results.all_section_lead_count,
          contract_value: results.contract_value,
          current_page: results.current_page,
          next: results.next,
          previous: results.previous,
          reply_later_count: results.reply_later_count,
          results: UniqueData,
          total_count: results.total_count,
          total_pages: results.total_pages
        }
      })
    );

    await dispatch(
      setStageData({
        ...stageData,
        seen_and_new: {
          ...stageData.seen_and_new
        },
        reply_later: {
          ...stageData.reply_later,
          [stageId]: {
            ...stageData.reply_later[stageId],
            next: results.next,
            current_page: results.current_page,
            total_count: results.total_count,
            total_pages: results.total_pages
          }
        }
      })
    );
  };
};

// this function is called to compose the reply later leads after moving lead from this if the next page is loaded
export const composeSourceReplyLaterLeadsIfNextPageLoaded = (payload) => {
  return async (dispatch, getState) => {
    const { stageId, results } = payload;
    const replyLaterLeads = getState().boxes.replyLaterLeads;
    const stageData = getState().boxes.stageData;

    const index = (results.current_page - 1) * 10 + 1;
    const updatedResults = [
      ...replyLaterLeads[stageId].results.slice(0, index - 1),
      ...results.results
    ];
    const UniqueData = _.uniqBy(updatedResults, 'id');
    // if the clicked lead page is not equal to current page
    // keep the leads untill the current page and first item of the current page remove other elements and add the new fetched leads
    await dispatch(
      setReplyLaterLeads({
        ...replyLaterLeads,
        [stageId]: {
          all_section_lead_count: results.all_section_lead_count,
          contract_value: results.contract_value,
          current_page: results.current_page,
          next: results.next,
          previous: results.previous,
          reply_later_count: results.reply_later_count,
          results: UniqueData,
          total_count: results.total_count,
          total_pages: results.total_pages
        }
      })
    );

    await dispatch(
      setStageData({
        ...stageData,
        seen_and_new: {
          ...stageData.seen_and_new
        },
        reply_later: {
          ...stageData.reply_later,
          [stageId]: {
            ...stageData.reply_later[stageId],
            next: results.next,
            current_page: results.current_page,
            total_count: results.total_count,
            total_pages: results.total_pages
          }
        }
      })
    );
  };
};

// this function helps to move card locally from one stage to another
export const moveCardToStageLocally = (payload) => {
  return async (dispatch, getState) => {
    const stageLeads = getState().boxes.stageLeads;
    const { src, dest, draggableId } = payload;
    const { key: srcKey, id: srcId } = getIdAndType(src.droppableId);
    const { key: dragKey, id: dragId } = getIdAndType(draggableId);
    const { key: destKey, id: destId } = getIdAndType(dest.droppableId);

    if (destKey === 'EMPTY') {
      dispatch(
        moveCardToEmptyDestination({
          stageLeads,
          destId,
          srcId,
          srcKey,
          dragId
        })
      );
    }
  };
};

const moveCardToEmptyDestination = ({ destId, srcId, srcKey, dragId }) => {
  return async (dispatch, getState) => {
    const stageLeads = getState().boxes.stageLeads;
    let stageDestData = stageLeads[destId];
    let stageSrcData = stageLeads[srcId];

    if (srcKey == 'SEEN') {
      const srcLead = stageSrcData.seen_replied.find(
        (lead) => lead.id === dragId
      );
      const d = {
        ...stageDestData,
        seen_replied: [srcLead, ...stageDestData.seen_replied]
      };
      dispatch(
        setStageLeads({
          ...stageLeads,
          [destId]: d
        })
      );
    }
  };
};

// this function is to hit the api to move the card to that particular stage
export const moveCardToStage = () => {};

// this function is to hit the api for creating box form
export const createBoxForm = (payload) => {
  return async (dispatch) => {
    try {
      const { body, callback } = payload;
      new APIRequest().post(CREATE_UPDATE_BOX_FORM, body).then((resp) => {
        if (callback) {
          callback();
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

// this function will be for the deleting the sample leads
export const deleteSampleLeads = createAsyncThunk(
  'leads/deleteSampleLeads',
  async ({ leadId }) => {
    const response = await new APIRequest().delete(
      replaceUrl(DELETE_SAMPLE_LEADS, 'leadId', leadId)
    );
    if (response.status !== 200) {
      throw new Error('Failed to delete sample lead');
    }
    return response.data;
  }
);

const addStageToBoxes = (payload) => {
  return async (dispatch, getState) => {
    const boxes = getState().boxes.boxes;
    const updated_box_stage = boxes.map((box) => {
      if (box.id === payload.box) {
        return {
          ...box,
          boxstage_set: [...box.boxstage_set, payload]
        };
      }
      return box;
    });
    dispatch(setBoxes(updated_box_stage));
  };
};

const updateStageData = (payload) => {
  return async (dispatch, getState) => {
    const currentBox = getState().boxes.currentBox;
    const boxes = getState().boxes.boxes;
    const updated_current_box_stage = currentBox?.boxstage_set?.map((stage) => {
      if (stage.id === payload.id) {
        return {
          ...stage,
          ...payload
        };
      }
      return stage;
    });
    dispatch(
      setCurrentBox({ ...currentBox, boxstage_set: updated_current_box_stage })
    );

    const updated_box_stage = boxes?.map((box) => {
      if (box.id === payload.box) {
        return {
          ...box,
          boxstage_set: updated_current_box_stage
        };
      }
      return box;
    });
    dispatch(setBoxes(updated_box_stage));
  };
};

export const createStage = (payload, successCb, errorCb) => {
  return async (dispatch, getState) => {
    const { stageLeads } = getState().boxes;
    try {
      await new APIRequest().post(ADD_STAGE, payload).then((res) => {
        if (res.status === 200) {
          if (successCb) successCb(res.data);
          dispatch(addStageToBoxes(res.data));
          dispatch(
            setStageLeads({
              ...stageLeads,
              [res.data.id]: {
                all_section_lead_count: 0,
                contract_value: 0,
                current_page: 1,
                next: null,
                previous: null,
                reply_later_count: 0,
                results: [],
                total_count: 0,
                total_pages: 1
              }
            })
          );
          dispatch(updateBoxData({ boxId: res?.data?.box }));
        }
      });
    } catch (e) {
      if (errorCb) errorCb(e);
      console.log(e);
    }
  };
};

// this function is to get the box form data from api
export const getBoxFormData = (payload) => {
  return async (dispatch) => {
    try {
      const { boxId, callback } = payload;
      new APIRequest()
        .get(replaceUrl(GET_BOX_FORM_DATA, 'boxId', boxId))
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(setBoxFormData(resp.data));
            if (callback) {
              callback();
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// rename the stage
export const renameStage = (payload, successCb, errorCb) => {
  return async (dispatch) => {
    const { stageId, body } = payload;
    try {
      await new APIRequest()
        .put(replaceUrl(UPDATE_STAGE, 'stageId', stageId), body)
        .then((res) => {
          if (res.status === 200) {
            if (successCb) successCb(res.data);
            dispatch(updateStageData(res.data));
            dispatch(updateBoxData({ boxId: res?.data?.box }));
          }
        });
    } catch (e) {
      if (errorCb) errorCb(e);
      console.log(e);
    }
  };
};

export const getBoxStageLeadCount = (payload, successCb, errorCb) => {
  return async (dispatch) => {
    const { boxId } = payload;
    try {
      await new APIRequest()
        .get(replaceUrl(GET_ALL_STAGE_LEAD_COUNT, 'boxId', boxId))
        .then((res) => {
          if (res.status === 200) {
            if (successCb) successCb();
            dispatch(setBoxStagesLeadCount(res.data));
          }
        });
    } catch (e) {
      if (errorCb) errorCb(e);
      console.log(e);
    }
  };
};

// get stage contract value
export const getStageContractValue = (payload, successCb, errorCb) => {
  return async (dispatch, getState) => {
    const { stageId, query = {} } = payload;
    const assigned_user = getState().boxes.usersSelected;
    const channel = getState().boxes.channelsSelected;
    const labels = getState().boxes.labelsSelected;

    const params = {
      assigned_user: assigned_user,
      channel: channel,
      label: labels
    };

    try {
      await new APIRequest()
        .get(replaceUrl(GET_STAGE_CONTRACT_VALUE, 'stageId', stageId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            if (successCb) successCb();
            dispatch(updateContractValue({ stageId, data: res.data }));
          }
        });
    } catch (e) {
      if (errorCb) errorCb(e);
      console.log(e);
    }
  };
};

const updateContractValue = (payload) => {
  return async (dispatch, getState) => {
    const { stageId, data } = payload;
    try {
      const {
        boxes: { stageLeads }
      } = getState();
      const currentStage = { ...stageLeads[stageId] };
      const updatedData = {
        ...stageLeads,
        [stageId]: {
          ...currentStage,
          contract_value: data.contract_value
        }
      };
      dispatch(setStageLeads(updatedData));
    } catch (e) {
      console.log(e);
    }
  };
};

// mark stage as conversion
export const markAsConversion = (payload, successCb, errorCb) => {
  return async (dispatch) => {
    const { stageId, body } = payload;
    try {
      await new APIRequest()
        .put(replaceUrl(UPDATE_STAGE, 'stageId', stageId), body)
        .then((res) => {
          if (res.status === 200) {
            if (successCb) successCb(res.data);
            dispatch(updateBoxConversionStage(res.data));
          }
        });
    } catch (e) {
      if (errorCb) errorCb(e);
      console.log(e);
    }
  };
};

// update box for conversion data
const updateBoxConversionStage = (payload) => {
  return async (dispatch, getState) => {
    const boxes = getState().boxes.boxes;
    const updated_box_stage = boxes?.map((box) => {
      if (box.id === payload.box) {
        const stage_set = box.boxstage_set.map((stage) => {
          if (stage.id === payload.id) {
            return {
              ...stage,
              is_sales_converted_stage: payload.is_sales_converted_stage
            };
          }
          return stage;
        });
        return {
          ...box,
          sales_converted_stage: payload.is_sales_converted_stage
            ? payload.id
            : '',
          boxstage_set: stage_set
        };
      }
      return box;
    });
    dispatch(setBoxes(updated_box_stage));
  };
};

// this function is to update the box form
export const updateBoxFormData = (payload) => {
  return async (dispatch) => {
    try {
      const { body, callback } = payload;
      new APIRequest()
        .put(replaceUrl(GET_BOX_FORM_DATA, 'boxId', body.box), body)
        .then((resp) => {
          dispatch(setBoxFormData(resp.data));
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

const removeStageFromBoxes = (payload) => {
  return async (dispatch, getState) => {
    const { currentBox, boxes } = getState().boxes;
    const updated_current_box_stage = currentBox?.boxstage_set?.filter(
      (stage) => {
        return stage.id !== payload.id;
      }
    );
    const updated_box_stage = boxes?.map((box) => {
      if (box.id === currentBox.id) {
        if (payload.id === box.sales_converted_stage) {
          return {
            ...box,
            boxstage_set: updated_current_box_stage,
            sales_converted_stage: ''
          };
        }
        return {
          ...box,
          boxstage_set: updated_current_box_stage
        };
      }
      return box;
    });
    if (payload.targetStageId) {
      dispatch(getStageLeads({ stageId: payload.targetStageId })).then(
        (res) => {
          dispatch(
            composeStageLeads({
              stageId: payload.targetStageId,
              results: res.data
            })
          );
        }
      );
    }
    dispatch(setBoxes(updated_box_stage));
  };
};

//Function for delete single stage
export const deleteStage = (payload, onSuccess) => {
  const { stageId, data, errorCb } = payload;
  return async (dispatch) => {
    try {
      await new APIRequest()
        .delete(replaceUrl(DELETE_STAGE, 'stageId', stageId), data)
        .then((res) => {
          if (data.move_to_stage) {
            dispatch(
              removeStageFromBoxes({
                id: stageId,
                targetStageId: data.move_to_stage
              })
            );
          } else {
            dispatch(
              removeStageFromBoxes({
                id: stageId,
                targetStageId: null
              })
            );
          }
          dispatch(updateBoxData({ boxId: res?.data?.box?.id }));
          if (onSuccess) onSuccess(res?.data);
        });
    } catch (e) {
      if (errorCb) errorCb(e);
      console.log(e);
    }
  };
};

// this function is to get the individual box data using boxId
export const getBoxData = (payload) => {
  return async (dispatch) => {
    try {
      const { boxId, callback } = payload;
      new APIRequest()
        .get(replaceUrl(GET_BOX_DATA, 'boxId', boxId))
        .then((resp) => {
          dispatch(setActiveBoxData(resp.data));
          if (callback) {
            callback(resp.data);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
export const changeBoxLeadStatus = (payload, successCb) => {
  return async (dispatch, getState) => {
    try {
      const { body, stageId, pageNumber, isReplyLater, isBin } = payload;
      const { stageData, stageLeads } = getState().boxes;
      await new APIRequest()
        .post(replaceUrl(LEAD_UPDATE_STATUS), body)
        .then((res) => {
          if (res.status === 200) {
            dispatch(getStageContractValue({ stageId: stageId }));
            if (isReplyLater) {
              dispatch(getReplyLaterStageLeads({ stageId, pageNumber })).then(
                (res) => {
                  dispatch(
                    composeReplyLaterStageLeads({ stageId, results: res.data })
                  );
                }
              );
              dispatch(getStageLeads({ stageId, pageNumber: 1 })).then(
                (res) => {
                  dispatch(
                    composeStageLeads({ stageId, results: res.data, isBin })
                  );
                }
              );
            } else {
              const currentStage = stageLeads[stageId];
              const updated_current_stage = {
                ...currentStage,
                reply_later_count: isBin
                  ? currentStage.reply_later_count
                  : currentStage.reply_later_count + 1,
                results: currentStage.results.filter((o) => o.id !== body.lead),
                total_count: currentStage.total_count - 1,
                total_pages: Math.ceil((currentStage.total_count - 1) / 10),
                all_section_lead_count: isBin
                  ? currentStage.all_section_lead_count - 1
                  : currentStage.all_section_lead_count
              };
              dispatch(
                setStageLeads({
                  ...stageLeads,
                  [stageId]: {
                    ...updated_current_stage
                  }
                })
              );

              dispatch(
                setStageData({
                  ...stageData,
                  seen_and_new: {
                    ...stageData.seen_and_new,
                    [stageId]: {
                      ...stageData.seen_and_new[stageId],
                      next:
                        stageData.seen_and_new[stageId].total_count - 1 > 10
                          ? stageData.seen_and_new[stageId].next
                          : null,
                      total_count:
                        stageData.seen_and_new[stageId].total_count - 1,
                      total_pages: Math.ceil(
                        (stageData.seen_and_new[stageId].total_count - 1) / 10
                      )
                    }
                  },
                  reply_later: {
                    ...stageData.reply_later
                  }
                })
              );
            }
            dispatch(getStageContractValue({ stageId: stageId }));
            if (successCb) successCb();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getAndComposeStageLeads = ({ stageId }) => {
  return async (dispatch) => {
    dispatch(getStageLeads({ stageId })).then((res) => {
      dispatch(composeStageLeads({ stageId, results: res.data }));
    });
  };
};
// this function is to create manual enquiry from box form
export const createManualEnquiryFromBoxForm = (payload) => {
  return async () => {
    try {
      const { body, callback } = payload;
      new APIRequest()
        .post(CREATE_MANUAL_ENQUIRY_BOX_FORM, body)
        .then((resp) => {
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
// when clicked on new leads change its status to viewed
export const updateBoxUnreadLeadStatus = (payload, successCb) => {
  return async (dispatch) => {
    try {
      const { body, stageId } = payload;
      await new APIRequest()
        .post(replaceUrl(LEAD_UPDATE_STATUS), body)
        .then((res) => {
          if (res.status === 200) {
            dispatch(updateUnreadLeads(res.data, stageId));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// update the un read leads of box
const updateUnreadLeads = (data, stageId) => {
  return async (dispatch, getState) => {
    const {
      boxes: { stageLeads }
    } = getState();
    const Leads = stageLeads[stageId];
    // Find item index using _.findIndex
    const results = _.cloneDeep(Leads?.results);
    let index = _.findIndex(results, { id: data.id });
    // Replace item at index using native splice
    if (index >= 0) {
      results.splice(index, 1, data);
      const updatedData = {
        ...stageLeads,
        [stageId]: {
          ...Leads,
          results: results
        }
      };
      dispatch(setStageLeads(updatedData));
    }
  };
};

// when clicked on new card and then clicked on another card then
// move the previous viewed card to the viewed section
export const onUnreadLeadFocused = () => {};

// when user clicks another card after clicking the new status card
// move the card from new to seen
export const onNewBoxCardPostClick = (payload) => {
  return async (dispatch, getState) => {
    try {
      const { id } = payload;
      const {
        boxes: { activeBoxNewLead }
      } = getState();
      if (!isEmpty(activeBoxNewLead)) {
        dispatch(setActiveBoxNewLead(id));
      } else {
        dispatch(setActiveBoxNewLead(id));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

// move lead from one stage to another through helper functionality nudge
export const moveLeadtoAnotherStage = (payload, successCb, errorCb) => {
  return async (dispatch) => {
    try {
      const { body, currentStageId, targetStageId, lead } = payload;
      await new APIRequest()
        .post(replaceUrl(MOVE_TO_STAGE_API), body)
        .then((res) => {
          if (res.status === 200) {
            dispatch(
              moveLeadToStage(
                { currentStageId, targetStageId, lead },
                successCb,
                errorCb
              )
            );
          }
        });
    } catch (e) {
      errorCb();
      console.log(e);
    }
  };
};

export const moveLeadToStage = (
  { currentStageId, targetStageId, lead },
  successCb,
  errorCb
) => {
  return async (dispatch, getState) => {
    try {
      const {
        boxes: { stageLeads }
      } = getState();
      const currentStage = stageLeads[currentStageId];
      const targetStage = stageLeads[targetStageId];
      let updated_target_stage = {};
      let final_box_data = {};
      const updated_current_stage = {
        ...currentStage,
        all_section_lead_count: currentStage.all_section_lead_count - 1,
        results: currentStage.results.filter((o) => o.id !== lead.id),
        total_count: currentStage.total_count - 1,
        total_pages: Math.ceil((currentStage.total_count - 1) / 10)
      };
      if (lead.status === REPLY_LATER) {
        updated_current_stage['reply_later_count'] =
          currentStage['reply_later_count'] - 1;
      }

      final_box_data = {
        ...stageLeads,
        [currentStageId]: updated_current_stage
      };

      // if coming directly to lead detail will not have other stages
      if (targetStage) {
        updated_target_stage = {
          ...targetStage,
          all_section_lead_count: targetStage.all_section_lead_count + 1,
          results: [lead, ...targetStage.results],
          total_count: targetStage.total_count + 1,
          total_pages: Math.ceil((targetStage.total_count + 1) / 10)
        };
        final_box_data = {
          ...final_box_data,
          [targetStageId]: updated_target_stage
        };
      }
      dispatch(setStageLeads(final_box_data));
      dispatch(getStageContractValue({ stageId: currentStageId }));
      dispatch(getStageContractValue({ stageId: targetStageId }));
      if (successCb) {
        successCb();
      }
    } catch (e) {
      if (errorCb) errorCb();
      console.log(e);
    }
  };
};

export const updateContactDetailsInBox = (payload) => {
  return async (dispatch, getState) => {
    try {
      const {
        leadConversation: { activeLead }
      } = getState();
      const { body, leadId, callback } = payload;
      new APIRequest()
        .put(replaceUrl(UPDATE_CONTACT_DETAILS, 'leadId', leadId), body)
        .then((resp) => {
          if (resp.status === 201) {
            dispatch(setActiveLead({ ...activeLead, ...resp.data }));
            dispatch(updateContactinLeadsList());
            if (callback) {
              callback();
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// remove lead from the list
export const removeLeadFromList = (payload) => {
  return async (dispatch, getState) => {
    try {
      const { stageId, cardId } = payload;
      const {
        boxes: { stageLeads, currentBoxCardList, replyLaterLeads }
      } = getState();
      const currentStage =
        currentBoxCardList == REPLY_LATER
          ? replyLaterLeads[stageId]
          : stageLeads[stageId];
      if (currentBoxCardList == REPLY_LATER) {
        const updatedNewLeadsList = currentStage?.results?.filter(
          (lead) => lead.id !== cardId
        );
        if (updatedNewLeadsList) {
          const updated_current_stage = {
            ...currentStage,
            all_section_lead_count: currentStage.all_section_lead_count - 1,
            reply_later_count: currentStage.reply_later_count - 1,
            results: updatedNewLeadsList
          };
          const final_box_data = {
            ...stageLeads,
            [stageId]: updated_current_stage
          };
          dispatch(setReplyLaterLeads(final_box_data));
        }
      } else {
        const updatedNewLeadsList = currentStage?.results?.filter(
          (lead) => lead.id !== cardId
        );

        if (updatedNewLeadsList) {
          const updated_current_stage = {
            ...currentStage,
            all_section_lead_count: currentStage.all_section_lead_count - 1,
            results: updatedNewLeadsList,
            total_count: currentStage.total_count - 1,
            total_pages: Math.ceil((currentStage.total_count - 1) / 10)
          };
          const final_box_data = {
            ...stageLeads,
            [stageId]: updated_current_stage
          };
          dispatch(setStageLeads(final_box_data));
        }
      }
      dispatch(setIsLeadReopened(false));
    } catch (e) {
      console.log(e);
    }
  };
};

// when contact is updated this function updates the lead in the list
export const updateContactinLeadsList = () => {
  return async (dispatch, getState) => {
    try {
      const {
        boxes: { stageLeads, currentBoxCardList, replyLaterLeads },
        leadConversation: { activeLead }
      } = getState();
      const stageId = activeLead.box_stage;
      if (stageId) {
        const leadsList =
          currentBoxCardList == REPLY_LATER
            ? replyLaterLeads[stageId]
            : stageLeads[stageId];
        if (currentBoxCardList == REPLY_LATER) {
          if (leadsList.results) {
            const leadIndex = leadsList.results?.findIndex(
              (lead) => lead.id === activeLead.id
            );
            const leads = [...leadsList.results];
            leads.splice(leadIndex, 1, activeLead);
            const updatedList = {
              ...replyLaterLeads,
              [stageId]: {
                ...leadsList,
                results: leads
              }
            };
            dispatch(setReplyLaterLeads(updatedList));
          }
        }
        if (currentBoxCardList == NEW || currentBoxCardList == VIEWED) {
          if (leadsList.results) {
            const leadIndex = leadsList.results?.findIndex(
              (lead) => lead.id === activeLead.id
            );
            const leads = [...leadsList.results];
            leads.splice(leadIndex, 1, activeLead);
            const updatedList = {
              ...stageLeads,
              [stageId]: {
                ...leadsList,
                results: leads
              }
            };
            dispatch(setStageLeads(updatedList));
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

// Update the status of a lead
export const updateLeadStatus = (payload, successCb) => {
  return async () => {
    try {
      const { body } = payload;
      await new APIRequest()
        .post(replaceUrl(LEAD_UPDATE_STATUS), body)
        .then((res) => {
          if (res.status === 200 && successCb) {
            successCb(res.data);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const actionsAfterLeadStatusChange = (payload) => {
  return async (dispatch, getState) => {
    try {
      const { lead, source, destination } = payload;
      const tempLead = JSON.parse(JSON.stringify(lead));
      tempLead.status = destination.status;
      const { id: leadId } = lead;
      const {
        boxes: { stageLeads, replyLaterLeads }
      } = getState();
      const updatedData = JSON.parse(JSON.stringify(stageLeads));
      const updatedReplyLaterData = JSON.parse(JSON.stringify(replyLaterLeads));
      const sourceStage =
        source.status === 3
          ? JSON.parse(JSON.stringify(replyLaterLeads[source.stage]))
          : JSON.parse(JSON.stringify(stageLeads[source.stage]));
      const destinationStage =
        destination.status === 3
          ? JSON.parse(JSON.stringify(replyLaterLeads[destination.stage]))
          : JSON.parse(JSON.stringify(stageLeads[destination.stage]));
      if (source.stage !== destination.stage) {
        if (source.status !== REPLY_LATER) {
          // remove the lead from new and seen data of the source stage
          const temp = sourceStage.results.filter((item) => item.id !== leadId);
          sourceStage.results = temp;
          sourceStage.total_count = sourceStage.total_count - 1;
          updatedData[source.stage] = sourceStage;

          // add the moved lead to the destination stage
          destinationStage.results = [tempLead, ...destinationStage.results];
          destinationStage.total_count = destinationStage.total_count + 1;
          destinationStage.total_pages = Math.ceil(
            (destinationStage.total_count + 1) / 10
          );
          destinationStage.all_section_lead_count =
            destinationStage.all_section_lead_count + 1;
          updatedData[destination.stage] = destinationStage;
          dispatch(setStageLeads(updatedData));
        }
      } else if (source.stage === destination.stage) {
        if (source.status === REPLY_LATER) {
          // remove the lead from reply later
          const temp = sourceStage?.results.filter(
            (item) => item.id !== leadId
          );
          sourceStage.results = temp;
          sourceStage.reply_later_count =
            replyLaterLeads[source.stage].reply_later_count - 1;
          sourceStage.total_count =
            replyLaterLeads[source.stage].total_count - 1;
          updatedReplyLaterData[destination.stage] = sourceStage;
          dispatch(setReplyLaterLeads(updatedReplyLaterData));

          // add the lead to the seen and new data
          const updateDestinationData = {
            ...stageLeads[destination.stage],
            reply_later_count: updatedData[source.stage].reply_later_count - 1
          };
          updatedData[destination.stage] = updateDestinationData;
          updatedData[destination.stage].results = [
            tempLead,
            ...updatedData[destination.stage].results
          ];
          updatedData[destination.stage].total_count =
            updatedData[destination.stage].total_count + 1;
          dispatch(setStageLeads(updatedData));
        } else {
          // remove the lead from seen and new section
          const temp = sourceStage?.results.filter(
            (item) => item.id !== leadId
          );
          sourceStage.results = temp;
          sourceStage.total_count = temp.length;
          // sourceStage.reply_later_count = sourceStage.reply_later_count + 1;
          updatedData[source.stage] = sourceStage;
          dispatch(setStageLeads(updatedData));

          // add the lead to reply later section
          updatedReplyLaterData[destination.stage].results = [
            tempLead,
            ...updatedReplyLaterData[destination.stage].results
          ];
          updatedReplyLaterData[destination.stage].total_count =
            updatedReplyLaterData[destination.stage].total_count + 1;
          // updatedReplyLaterData[destination.stage].reply_later_count =
          //   updatedReplyLaterData[destination.stage].reply_later_count + 1;
          const totalReplyLaterPage = Math.ceil(
            updatedReplyLaterData[destination.stage].total_count / 10
          );
          updatedReplyLaterData[destination.stage].total_pages =
            totalReplyLaterPage;
          updatedReplyLaterData[destination.stage].next =
            totalReplyLaterPage > 1 ? totalReplyLaterPage : null;
          dispatch(setReplyLaterLeads(updatedReplyLaterData));
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

// move lead from one stage to another through helper functionality nudge
export const moveCardtoAnotherStage = (payload, successCb, errorCb) => {
  return async () => {
    try {
      const { body } = payload;
      await new APIRequest()
        .post(replaceUrl(MOVE_TO_STAGE_API), body)
        .then((res) => {
          if (res.status === 200 && successCb) {
            successCb();
          }
        });
    } catch (e) {
      if (errorCb) errorCb();
      console.log(e);
    }
  };
};

export const updatePositionOfStage = (
  payload,
  successCb,
  errorCb,
  tempOptionsData
) => {
  return async () => {
    try {
      const { currentStageId, position } = payload;
      await new APIRequest()
        .post(
          replaceUrl(UPDATE_BOX_STAGE_POSITION, 'stageId', currentStageId),
          { position: position }
        )
        .then((res) => {
          if (res.status === 200 && successCb) {
            successCb(tempOptionsData);
          }
        });
    } catch (e) {
      if (errorCb) errorCb();
      console.log(e);
    }
  };
};

export const handleMoveLead = (data, callback) => {
  return async (dispatch, getState) => {
    try {
      const { stageLeads, replyLaterLeads } = getState().boxes;
      const { source, destination, draggableId } = data;
      const [cardId] = draggableId.split('_');
      const sourceStageId = source.droppableId.split('__')[0];
      const destinationStageId = destination.droppableId.split('__')[0];
      const destinationReplyLaterLeads =
        replyLaterLeads[destinationStageId]?.results;
      const sourceNewSeenLeads = stageLeads[sourceStageId]?.results;
      const sourceReplyLaterLeads = replyLaterLeads[sourceStageId]?.results;

      let activeLead;
      if (Array.isArray(sourceNewSeenLeads)) {
        activeLead = sourceNewSeenLeads.find((item) => item.id === cardId);
      }
      if (!activeLead && Array.isArray(sourceReplyLaterLeads)) {
        activeLead = sourceReplyLaterLeads.find((item) => item.id === cardId);
      }
      const currentStatus = activeLead.status;

      /**
       * Only change the status of the lead if the source and destination are same
       */
      if (sourceStageId === destinationStageId) {
        let destinationStatus;
        switch (currentStatus) {
          case NEW:
            if (
              Array.isArray(destinationReplyLaterLeads) &&
              destinationReplyLaterLeads.length > 0 &&
              destination.index <= destinationReplyLaterLeads.length - 1
            ) {
              destinationStatus = REPLY_LATER;
            } else {
              if (callback) {
                callback();
              }
              return;
            }
            break;
          case RESTORED:
            if (
              Array.isArray(destinationReplyLaterLeads) &&
              destinationReplyLaterLeads.length > 0 &&
              destination.index <= destinationReplyLaterLeads.length - 1
            ) {
              destinationStatus = REPLY_LATER;
            } else {
              if (callback) {
                callback();
              }
              return;
            }
            break;
          case REPLY_LATER:
            {
              destinationStatus = VIEWED;
            }
            break;
          case VIEWED:
            if (
              Array.isArray(destinationReplyLaterLeads) &&
              destinationReplyLaterLeads.length > 0 &&
              destination.index <= destinationReplyLaterLeads.length - 1
            ) {
              destinationStatus = REPLY_LATER;
            } else {
              if (callback) {
                callback();
              }
              return;
            }
            break;
          case MANUALLY_ADDED:
            if (
              Array.isArray(destinationReplyLaterLeads) &&
              destinationReplyLaterLeads.length > 0 &&
              destination.index <= destinationReplyLaterLeads.length - 1
            ) {
              destinationStatus = REPLY_LATER;
            } else {
              if (callback) {
                callback();
              }
              return;
            }
            break;
          case TASK_ADDED:
            if (
              Array.isArray(destinationReplyLaterLeads) &&
              destinationReplyLaterLeads.length > 0 &&
              destination.index <= destinationReplyLaterLeads.length - 1
            ) {
              destinationStatus = REPLY_LATER;
            } else {
              if (callback) {
                callback();
              }
              return;
            }
            break;
          case REPLY_SENT:
            if (
              Array.isArray(destinationReplyLaterLeads) &&
              destinationReplyLaterLeads.length > 0 &&
              destination.index <= destinationReplyLaterLeads.length - 1
            ) {
              destinationStatus = REPLY_LATER;
            } else {
              if (callback) {
                callback();
              }
              return;
            }
            break;
          case MOVE_TO_BOX:
            if (
              Array.isArray(destinationReplyLaterLeads) &&
              destinationReplyLaterLeads.length > 0 &&
              destination.index <= destinationReplyLaterLeads.length - 1
            ) {
              destinationStatus = REPLY_LATER;
            } else {
              if (callback) {
                callback();
              }
              return;
            }
            break;
          case NOTE_ADDED:
            if (
              Array.isArray(destinationReplyLaterLeads) &&
              destinationReplyLaterLeads.length > 0 &&
              destination.index <= destinationReplyLaterLeads.length - 1
            ) {
              destinationStatus = REPLY_LATER;
            } else {
              if (callback) {
                callback();
              }
              return;
            }
            break;
        }

        const data = {
          stageId: destinationStageId,
          body: {
            status: destinationStatus,
            lead: cardId,
            is_current: true
          }
        };
        await dispatch(
          updateLeadStatus(data, () => {
            if (callback) {
              callback();
            }
            dispatch(
              actionsAfterLeadStatusChange({
                lead: activeLead,
                source: {
                  stage: sourceStageId,
                  status: currentStatus
                },
                destination: {
                  stage: destinationStageId,
                  status: destinationStatus
                }
              })
            );
          })
        );
      } else {
        const payload = {
          box_stage: destinationStageId,
          lead: cardId,
          is_current: true
        };
        let destinationStatus;
        switch (currentStatus) {
          case NEW:
            destinationStatus = MOVE_TO_BOX;
            break;
          default:
            destinationStatus = currentStatus;
            break;
        }
        dispatch(
          moveCardtoAnotherStage(
            {
              body: payload,
              currentStageId: sourceStageId,
              targetStageId: destinationStageId,
              lead: activeLead
            },
            () => {
              if (callback) {
                callback();
              }
              dispatch(
                actionsAfterLeadStatusChange({
                  lead: activeLead,
                  source: {
                    stage: sourceStageId,
                    status: currentStatus
                  },
                  destination: {
                    stage: destinationStageId,
                    status: destinationStatus
                  }
                })
              );
              dispatch(getStageContractValue({ stageId: sourceStageId }));
              dispatch(getStageContractValue({ stageId: destinationStageId }));
            }
          )
        );
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getBoxNotificationOfUser = (payload) => {
  return async () => {
    const { userId, successCb, errorCb } = payload;
    try {
      await new APIRequest()
        .get(replaceUrl(GET_BOX_NOTIFICATION_OF_USER, 'userId', userId))
        .then((res) => {
          if (res.status === 200) {
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log('e', e);
      if (errorCb) {
        errorCb();
      }
    }
  };
};

export const getBoxPermissionsNotificationOfUser = (payload) => {
  return async () => {
    const { userId, successCb, errorCb } = payload;
    try {
      await new APIRequest()
        .get(
          replaceUrl(GET_BOX_PERMISSION_NOTIFICATION_OF_USER, 'userId', userId)
        )
        .then((res) => {
          if (res.status === 200) {
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log('e', e);
      if (errorCb) {
        errorCb();
      }
    }
  };
};

export const updateBoxPermissionsNotificationOfUser = (payload) => {
  return async () => {
    const { userId, data, successCb, errorCb } = payload;
    try {
      await new APIRequest()
        .patch(
          replaceUrl(GET_BOX_PERMISSION_NOTIFICATION_OF_USER, 'userId', userId),
          data
        )
        .then((res) => {
          if (res.status === 200) {
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log('e', e);
      if (errorCb) {
        errorCb();
      }
    }
  };
};

export const updateBoxNotificationOfUser = (payload) => {
  return async () => {
    const { id, data, onError } = payload;
    try {
      await new APIRequest().patch(
        replaceUrl(UPDATE_BOX_NOTIFICATION_OF_USER, 'boxNotificationId', id),
        data
      );
    } catch (e) {
      console.log('e', e);
      if (onError) {
        onError();
      }
    }
  };
};

export const reOrderBox = (data, failedCb) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const cbData = {
      placedIdx: data.destinationIdx,
      startIdx: data.startIdx,
      newOrder: data.newboxOrder
    };
    const payload = {
      box_id: data.boxId,
      new_position: data.destinationIdx
    };
    try {
      await new APIRequest()
        .post(replaceUrl(RE_ORDER_BOX, 'subOrgId', subOrgId), payload)
        .catch((e) => {
          if (failedCb) failedCb(cbData);
          console.log(e);
        });
    } catch (e) {
      if (failedCb) failedCb(cbData);
      console.log(e);
    }
  };
};

export const updateBoxData = (payload, successCb, errorCb) => {
  return async (dispatch, getState) => {
    const { boxId, data } = payload;
    const { boxes } = getState().boxes;
    try {
      await new APIRequest()
        .patch(replaceUrl(UPDATE_BOX_DATA, 'boxId', boxId), data)
        .then((res) => {
          dispatch(setCurrentBox(res.data));
          const copyAllBox = _.cloneDeep(boxes);
          const updatedAllBox = copyAllBox.map((item) => {
            if (item.id === res.data.id) {
              return res.data;
            }
            return item;
          });
          dispatch(setBoxes(updatedAllBox));
          if (successCb) {
            successCb(res.data);
          }
        });
    } catch (e) {
      if (errorCb) {
        errorCb(e?.response?.data);
      }
      console.log(e);
    }
  };
};

export const hideVideo = (payload) => {
  return async (dispatch, getState) => {
    const { data, callback } = payload;
    const { myInformation } = getState().myInfo;
    try {
      await new APIRequest().post(HIDE_VIDEO, data).then((res) => {
        if (callback()) {
          callback();
        }
        const copyMyInfo = _.cloneDeep(myInformation);
        const updatedMyInfo = {
          ...copyMyInfo,
          show_these_videos_to_users: res.data.message
        };
        dispatch(setMyInformation(updatedMyInfo));
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const dismissBoxDummyLead = (payload) => {
  return async () => {
    const { leadId, callback } = payload;
    try {
      await new APIRequest()
        .delete(replaceUrl(DISMISS_DUMMY_LEADS, 'leadId', leadId))
        .then((res) => {
          if (res.status === 200) {
            if (callback) {
              callback(leadId);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateToggleStageChecklist = (payload, callback) => {
  return async (dispatch) => {
    const { boxId, data } = payload;
    try {
      await new APIRequest().post(UPDATE_TOGGLE_CHECKLIST, data).then((res) => {
        const formData = {
          id: data.box_stage_id,
          box: boxId,
          is_checklist_added_to_stage: data.is_checklist_added_to_stage
        };
        dispatch(updateStageData(formData));
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const addCheckListFieldsToStage = (payload, callback) => {
  return async (dispatch) => {
    try {
      const { data, stageId } = payload;
      await new APIRequest()
        .post(
          replaceUrl(ADD_CHECKLIST_FIELD_TO_STAGE, 'stageId', stageId),
          data
        )
        .then((res) => {
          dispatch(setSelectedCheckListFields(res.data));
          dispatch(setTempSelectedCheckListFields(res.data));
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getAllTheChecklistedFieldsOfStage = (payload, callback) => {
  return async (dispatch) => {
    try {
      const { stageId } = payload;
      await new APIRequest()
        .get(replaceUrl(ADD_CHECKLIST_FIELD_TO_STAGE, 'stageId', stageId))
        .then((res) => {
          dispatch(setSelectedCheckListFields(res.data));
          dispatch(setTempSelectedCheckListFields(res.data));
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getAllDentalBoxTemplates = (callback) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      await new APIRequest()
        .get(replaceUrl(GET_BOX_TEMPLATES, 'subOrgId', subOrgId))
        .then((res) => {
          dispatch(
            setDentalBoxTemplates(
              res?.data?.map((item) => {
                return {
                  label: item?.box_name,
                  value: item?.id,
                  icon: item?.box_avatar
                };
              })
            )
          );
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const createBoxFromDentalBoxTemplates = (payload, callback) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const { boxes } = getState().boxes;
    try {
      await new APIRequest()
        .post(
          replaceUrl(CRETATE_BOX_FROM_DENTAL_TEMPLATES, 'subOrgId', subOrgId),
          payload
        )
        .then((res) => {
          dispatch(setCurrentBox(res?.data));

          if (callback) {
            dispatch(setBoxes([...boxes, res?.data]));
            callback(res?.data);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
