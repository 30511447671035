/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import { GET_CONNECTED_CHANNEL_BY_ID } from 'urls';
import {
  CONNECT_WHATSAPP_CHANNEL,
  GET_WHATSAPP_DEFAULT_TEMPLATE,
  SEND_WHATSAPP_DEFAULT_TEMPLATE,
  SEND_WHATSAPP_REPLY,
  UPDATE_LAST_REPLY_RECIEVED_STATUS,
  SYNC_WHATSAPP_TEMPLATES,
  UPDATE_WHATSAPP_TEMPLATE
} from 'urls/whatsapp';
import { replaceUrl } from 'utils/urlReplace';
import { isEmpty } from 'utils/utils';

export const whatsAppSlice = createSlice({
  name: 'whatsApp',
  initialState: {
    connectedChannelById: {},
    isShowErrorModal: false,
    defaultTemplates: [],
    defaultApprovedTemplates: []
  },
  reducers: {
    setConnectedChannelById: (state, action) => {
      state.connectedChannelById = action.payload;
    },
    setIsShowErrorModal: (state, action) => {
      state.isShowErrorModal = action.payload;
    },
    setDefaultTemplates: (state, action) => {
      state.defaultTemplates = action.payload;
    },
    setDefaultApprovedTemplates: (state, action) => {
      state.defaultApprovedTemplates = action.payload;
    }
  }
});

export const {
  setConnectedChannelById,
  setIsShowErrorModal,
  setDefaultTemplates,
  setDefaultApprovedTemplates
} = whatsAppSlice.actions;

export default whatsAppSlice.reducer;

export const connectWhatsApp = (payload) => {
  return async (dispatch) => {
    try {
      const { body, callback, errorCallback } = payload;
      new APIRequest()
        .post(CONNECT_WHATSAPP_CHANNEL, body)
        .then((resp) => {
          if (callback) {
            callback(resp.data);
          }
        })
        .catch((err) => {
          errorCallback(err);
          dispatch(setIsShowErrorModal(true));
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getConnectedChannelById = (payload, successCb) => {
  return async (dispatch) => {
    try {
      const { subOrgId, channelId } = payload;
      await new APIRequest()
        .get(
          replaceUrl(
            GET_CONNECTED_CHANNEL_BY_ID,
            ['subOrgId', 'channelId'],
            [subOrgId, channelId]
          )
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(setConnectedChannelById(res.data));
            if (successCb) {
              successCb(res.data);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log('e', e);
      return '';
    }
  };
};

export const updateReplyRecievedMessageStatus = (payload) => {
  return async (dispatch) => {
    try {
      const { body, callback } = payload;
      new APIRequest()
        .post(UPDATE_LAST_REPLY_RECIEVED_STATUS, body)
        .then((resp) => {
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const sendWhatsAppReply = (payload) => {
  return async (dispatch) => {
    const { body, callback, errorCallback } = payload;
    try {
      new APIRequest()
        .post(SEND_WHATSAPP_REPLY, body)
        .then((resp) => {
          if (callback) {
            callback();
          }
        })
        .catch(() => {
          if (errorCallback) {
            errorCallback();
          }
        });
    } catch (e) {
      if (errorCallback) {
        errorCallback();
      }
      console.log(e);
    }
  };
};

export const getWhatsAppDefaultTemplates = (payload) => {
  return async (dispatch) => {
    try {
      const { subOrgId, callback, errorCallback } = payload;
      const query = {
        sub_organization_id: subOrgId
      };
      new APIRequest()
        .get(GET_WHATSAPP_DEFAULT_TEMPLATE, query)
        .then((resp) => {
          const approvedTemplates = resp?.data?.message.filter(
            (item) =>
              item.template_data.status === 'APPROVED' &&
              isEmpty(item.template_error)
          );
          dispatch(setDefaultTemplates(resp?.data?.message));
          dispatch(setDefaultApprovedTemplates(approvedTemplates));
          if (callback) {
            callback();
          }
        })
        .catch((err) => {
          if (errorCallback) {
            errorCallback(err);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const sendWhatsAppDefaultTemplates = (payload) => {
  return async () => {
    const { data, callback, errorCallback } = payload;
    try {
      new APIRequest()
        .post(SEND_WHATSAPP_DEFAULT_TEMPLATE, data)
        .then((resp) => {
          if (callback) {
            callback(resp?.data);
          }
        })
        .catch(() => {
          if (errorCallback) {
            errorCallback();
          }
        });
    } catch (e) {
      console.log(e);
      if (errorCallback) {
        errorCallback();
      }
    }
  };
};

// sync whatsapp templates
export const syncWhatsappTemplates = (payload) => {
  return async (dispatch, getState) => {
    const { onSuccess, onError } = payload;
    try {
      const subOrgId = getState().myInfo.subOrganizationId;
      const query = {
        sub_organization_id: subOrgId
      };
      new APIRequest()
        .get(SYNC_WHATSAPP_TEMPLATES, query)
        .then((resp) => {
          dispatch(setDefaultTemplates(resp?.data?.message));
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((err) => {
          if (onError) {
            onError();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// update whatsapp template by template id
export const updateWhatsappTemplate = (payload) => {
  return async (dispatch, getState) => {
    const { templateId, onSuccess, onError } = payload;
    try {
      const subOrgId = getState().myInfo.subOrganizationId;
      const defaultTemplates = getState().whatsApp.defaultTemplates;
      const body = {
        template_id: templateId,
        sub_org_id: subOrgId
      };
      new APIRequest()
        .patch(UPDATE_WHATSAPP_TEMPLATE, body)
        .then((resp) => {
          const updatedTemplates = defaultTemplates.map((template) =>
            template.id === templateId ? resp?.data?.message : template
          );
          dispatch(setDefaultTemplates(updatedTemplates));
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((err) => {
          if (onError) {
            onError();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
