const { REACT_APP_BASE_URL: BASE_API_URL } = process.env;

export const CREATE_DOMAIN = `${BASE_API_URL}/emails/create_domain`;

export const GET_DOMAIN_DETAILS = `${BASE_API_URL}/emails/domain_details`;

export const CHECK_DOMAIN_DETAIL = `${BASE_API_URL}/emails/check-domain-detail/domain/:domainId`;

export const SENDER_EMAIL_LOCATION = `${BASE_API_URL}/emails/sender-email/:senderEmailId/location/:locationId`;

export const DELETE_DOMAIN = `${BASE_API_URL}/emails/domain/:subOrgId/domain_id/:domainId`;

export const GET_SENDER_EMAIL = `${BASE_API_URL}/emails/sender_email`;

export const ADD_SENDER_EMAIL = `${BASE_API_URL}/emails/sender_email`;

export const UPDATE_SENDER_EMAIL = `${BASE_API_URL}/emails/sender_email/:senderEmailId/`;

export const RESEND_SENDER_EMAIL_VERIFICATION = `${BASE_API_URL}/emails/resend_otp_email`;

export const REFRESH_STATUS = `${BASE_API_URL}/emails/sender_email/refresh/:signatureId/`;

export const DELETE_SENDER_EMAIL = `${BASE_API_URL}/emails/sender_email/:senderEmailId/`;

export const GET_SENDER_EMAIL_AUTOMATION_COUNT = `${BASE_API_URL}/emails/sender_email_automation_count`;

export const VALIDATE_SENDER_EMAIL = `${BASE_API_URL}/emails/validate_sender_email`;
