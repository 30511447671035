import Lottie from 'lottie-react';
import spinner from '../../lottie/spinner.json';
import { errorBoundaryWrap } from 'components/errorBoundary';

const LogoLoader = ({ children }) => {
  return (
    <div className="flex flex-col items-center justify-center h-full h-screen">
      <Lottie style={{ height: 50 }} animationData={spinner} />
      {children}
    </div>
  );
};

export default errorBoundaryWrap(LogoLoader);
