import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL, SOCIAL_AUTH_CREDENTIALS_RTK } from 'urls';
import { ApiHeaders } from '../headerSetup';

export const socialCrendetialsApi = createApi({
  reducerPath: 'socialCrendetialsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: ApiHeaders
  }),
  endpoints: (builder) => ({
    socialCredentials: builder.query({
      query: () => SOCIAL_AUTH_CREDENTIALS_RTK
    })
  })
});

export const { useSocialCredentialsQuery } = socialCrendetialsApi;
