import { createSlice } from '@reduxjs/toolkit';
import {
  GET_DOMAIN_DETAILS,
  DELETE_DOMAIN,
  CREATE_DOMAIN,
  CHECK_DOMAIN_DETAIL
} from 'urls/domainVerification';
import { replaceUrl } from 'utils/urlReplace';
import APIRequest from 'apiRequest';

export const domainVerificationSlice = createSlice({
  name: 'domainVerification',
  initialState: {
    domainDetails: []
  },
  reducers: {
    setDomains: (state, action) => {
      state.domainDetails = action.payload;
    }
  }
});

export const { setDomains } = domainVerificationSlice.actions;

export const createDomain = (payload, successCb, errorCb) => {
  return async (dispatch, getState) => {
    const {
      domainVerification: { domainDetails }
    } = getState();
    try {
      await new APIRequest().post(CREATE_DOMAIN, payload).then((res) => {
        dispatch(setDomains([...domainDetails, res.data]));
        if (successCb) {
          successCb(res.data);
        }
      });
    } catch (e) {
      console.log('e', e);
      if (errorCb) {
        errorCb(e.response);
      }
    }
  };
};

export const getDomainDetails = (payload, successCb, errorCb) => {
  return async (dispatch) => {
    try {
      const query = {
        sub_org_id: payload
      };
      await new APIRequest().get(GET_DOMAIN_DETAILS, query).then((res) => {
        if (
          res?.data?.ErrorCode == '510' &&
          res?.status == '200' &&
          res.data.Message == 'This domain was not found.'
        ) {
          errorCb(res?.data.Messsage);
          dispatch(setDomains([]));
        } else {
          dispatch(setDomains(res?.data));
          if (successCb) {
            successCb(res?.data);
          }
        }
      });
    } catch (e) {
      console.log('e', e);
      dispatch(setDomains([]));
      if (errorCb) {
        errorCb();
      }
    }
  };
};

export const deleteDomain = (payload, successCb, errorCb) => {
  const { subOrganizationId, domainId } = payload;
  return async (dispatch, getState) => {
    const {
      domainVerification: { domainDetails }
    } = getState();
    try {
      await new APIRequest()
        .delete(
          replaceUrl(
            DELETE_DOMAIN,
            ['subOrgId', 'domainId'],
            [subOrganizationId, domainId]
          )
        )
        .then((res) => {
          if (res.status == 200) {
            const updatedList = domainDetails.filter(
              (domain) => domain?.id !== domainId
            );
            dispatch(setDomains(updatedList));
            if (successCb) {
              successCb();
            }
          }
        });
    } catch (e) {
      console.log('e', e);
      if (errorCb) {
        errorCb();
      }
    }
  };
};

export const checkDomain = (payload) => {
  const { domainId, successCb, errorCb } = payload;
  return async (dispatch, getState) => {
    const {
      domainVerification: { domainDetails }
    } = getState();
    try {
      await new APIRequest()
        .get(replaceUrl(CHECK_DOMAIN_DETAIL, ['domainId'], [domainId]))
        .then((res) => {
          if (res.status == 200) {
            const updatedList = domainDetails.map((domain) => {
              if (domain?.id == domainId) return res.data;
              return domain;
            });
            dispatch(setDomains(updatedList));
            if (successCb) {
              successCb();
            }
          }
        });
    } catch (e) {
      console.log('e', e);
      if (errorCb) {
        errorCb();
      }
    }
  };
};

export default domainVerificationSlice.reducer;
