export const AUTH_TOKEN = 'authToken';
export const GMAIL = 'gmail';
export const MICROSOFT = 'microsoft';
export const EMAIL = 'email';
export const ALL = 'all';
export const ENQUIRIES = 'enquiries';
export const ASSIGNED_TO = 'assignedTo';
export const REPLY = 'Reply';
export const NOTE = 'Note';
export const TASK = 'Task';
export const TOMORROW = 'Tomorrow';
export const MONDAY = 'Monday';
export const CUSTOM_DATE = 'Pick a date';
export const INVITED_EMAIL = 'invitedEmail';
export const SOCIAL_AUTH_ERROR = 'socialAuthError';
export const USER_INVITATION_LINK = 'invitationLink';
export const SUB_ORG_ID = 'subOrgId';
export const SUB_ORG_IDS = 'subOrgIds';
export const BUSY = 'Busy';
export const AVAILABLE = 'Available';
export const CALL = 'Call';
export const FOLLOW_UP = 'Follow up';
export const OTHER = 'Other';
export const CONTACT = 'Contact';
export const HISTORY = 'History';
export const FILE = 'File';
export const WHOEVER__IS_AVAILABLE = 'Whoever is available';
export const EBOT_EMAIL = 'ebotEmail';
export const BOT_ID = 'botId';
export const DELETE_ACCOUNT = 'Delete_Account';
export const CANCEL_ACCOUNT = 'Cancel_Account';
export const PAYMENT_INCOMPLETE = 'Payment Incomplete';
export const LOGOUT = 'Log_out';
export const ENQUIRYBOX_PLAN_TYPE = 'enquirybox_plan_type';
export const NOTIFICATIONS = 'Notifications';
export const PERMISIONS_AND_NOTIFICTAIONS = 'Permissions & Notifications';
export const WHOEVER_IS_ASSIGNED_TO = 'Whoever is assigned to the lead';
export const NEW_INCOMING_LEAD = 1;
export const NEW_TREATMENT_LEAD = 6;
export const LEAD_IS_CUSTOMER = 2;
export const CUSTOM = 'custom';
export const COPY_FROM_BOX = 3;
export const NO_OUTCOME = 'No Outcome';
export const TASK_TYPE = 'task';
export const ENQUIRY_TYPE = 'enquiry';
export const INBOUND = 'Inbound';
export const OUTBOUND = 'Outbound';
export const MISSED = 'Missed';
export const ANSWERED = 'Answered';
export const CONNECTED = 'Connected';
export const NOTCONNECTED = 'Not Connected';
export const LEAD_CHANNEL = 'lead-channel';
export const PMS_CHANNEL = 'pms-channel';
export const SUBSCRIBE_CHOICE_WHATSAPP = 'WhatsApp';
export const SUBSCRIBE_CHOICE_POSTCARD = 'PostCard';
export const CARESTACK_LEAD_SOURCE = 'Care Stack';
export const CS_PHONES_LEAD_SOURCE = 'CS Phones';
export const VALUE_LABELS = 'VALUE_LABELS';
export const OTHER_LABELS = 'OTHER_LABELS';
export const HIDE_LEAD_SOURCE = true;
