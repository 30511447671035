/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import { setAllConnectedChannels } from 'slices/channelsSlice';
import { UPDATE_CHANNEL_NAME } from 'urls';
import {
  ASSIGN_LOCATION_TO_ZAP_FORM,
  GET_CONNECTED_ZAPS,
  HIDE_ZAPIER_HELP_GUIDE,
  TOGGLE_ON_OFF_ZAP_FORMS
} from 'urls/zapier';
import { replaceUrl } from 'utils/urlReplace';

export const zapierSlice = createSlice({
  name: 'zapier',
  initialState: {
    connectedZaps: []
  },
  reducers: {
    setConnectedZaps: (state, action) => {
      state.connectedZaps = action.payload;
    }
  }
});

export const { setConnectedZaps } = zapierSlice.actions;

export const getAllConnectedZaps = createAsyncThunk(
  'zapier/getAllConnectedZaps',
  async (payload, { dispatch, rejectWithValue }) => {
    const { successCb, failedCb, channelId } = payload;
    try {
      const response = await new APIRequest().get(
        replaceUrl(GET_CONNECTED_ZAPS, 'channelId', channelId)
      );
      if (response.status === 200) {
        dispatch(setConnectedZaps(response.data));
        if (successCb) successCb(response.data);
      }
    } catch (e) {
      if (failedCb) failedCb();
      return rejectWithValue(e?.response?.data);
    }
  }
);

export const toggleOnOffZapform = createAsyncThunk(
  'zapier/toggleOnOffZapform',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const { successCb, failedCb, data } = payload;
    const connectedZaps = getState().zapier.connectedZaps;
    try {
      const res = await new APIRequest().patch(TOGGLE_ON_OFF_ZAP_FORMS, data);
      if (res.status === 200) {
        const updatedZapsValues = connectedZaps.map((item) =>
          item.id === data.zap_form_id
            ? { ...item, is_active: data.is_active }
            : item
        );
        dispatch(setConnectedZaps(updatedZapsValues));
        if (successCb) successCb(data);
      }
    } catch (e) {
      if (failedCb) failedCb();
      return rejectWithValue(e?.response?.data);
    }
  }
);

export const assignLocationToZapForm = createAsyncThunk(
  'zapier/assignLocationToZapForm',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const { onSuccess, data } = payload;
    const connectedZaps = getState().zapier.connectedZaps;
    try {
      const res = await new APIRequest().put(ASSIGN_LOCATION_TO_ZAP_FORM, data);
      if (res.status === 200) {
        const updatedZapsValues = connectedZaps.map((item) =>
          item.id === data.zap_form_id
            ? { ...item, location: data.location_id }
            : item
        );
        dispatch(setConnectedZaps(updatedZapsValues));
        if (onSuccess) onSuccess(data);
      }
    } catch (e) {
      return rejectWithValue(e?.response?.data);
    }
  }
);

export const updateZapierChannelName = createAsyncThunk(
  'zapier/updateZapierChannelName',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const allConnectedChannels = getState().channels.allConnectedChannels;
    const { channel, channel_name, onSuccess } = payload;
    try {
      const res = await new APIRequest().patch(
        replaceUrl(
          UPDATE_CHANNEL_NAME,
          ['subOrgId', 'channelId'],
          [subOrgId, channel]
        ),
        { name: channel_name }
      );
      const updatedConnectedChannelData = allConnectedChannels.map((item) =>
        item.id === channel ? { ...item, name: channel_name } : item
      );
      dispatch(setAllConnectedChannels(updatedConnectedChannelData));
      if (onSuccess) onSuccess();
    } catch (e) {
      return rejectWithValue(e?.response?.data);
    }
  }
);

export const hideZapierGuide = createAsyncThunk(
  'zapier/hideZapierGuide',
  async (payload, { getState, rejectWithValue }) => {
    const { callback } = payload;
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      const res = await new APIRequest().post(
        replaceUrl(HIDE_ZAPIER_HELP_GUIDE, 'subOrgId', subOrgId)
      );
      if (res.status === 200) {
        if (callback) callback();
      }
    } catch (e) {
      return rejectWithValue(e?.response?.data);
    }
  }
);

export default zapierSlice.reducer;
