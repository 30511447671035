import 'core-js/stable';
import 'regexp-polyfill';
import 'regenerator-runtime/runtime'; // For async/await support
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Main from './Main';
import AppProvider from 'store/store';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { AnalyticsProvider } from 'use-analytics';
import { analytics } from 'segment_analytics';
import ErrorBoundaryWrap from 'components/errorBoundary';
import BrowserInfo from 'supportedBrowser';

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <AnalyticsProvider instance={analytics}>
        <ErrorBoundaryWrap>
          <BrowserInfo>
            <Main />
          </BrowserInfo>
        </ErrorBoundaryWrap>
      </AnalyticsProvider>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// console.log('navigator.serviceWorker', navigator.serviceWorker);
if (navigator.serviceWorker) {
  serviceWorkerRegistration.register();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
