import { createSlice } from '@reduxjs/toolkit';
import { replaceUrl } from 'utils/urlReplace';
import APIRequest from 'apiRequest';
import { GET_NEW_BOXES } from 'urls/boxes';

export const boxesNewEnquires = createSlice({
  name: 'boxesNewEnquires',
  initialState: {
    boxesNewEnquires: []
  },
  reducers: {
    setBoxesNewEnquires: (state, action) => {
      state.boxesNewEnquires = action.payload;
    }
  }
});

export const { setBoxesNewEnquires } = boxesNewEnquires.actions;

export default boxesNewEnquires.reducer;

export const getAllNewBoxesEnquires = (payload, successCb) => {
  return async (dispatch) => {
    try {
      const { boxId } = payload;
      new APIRequest()
        .get(replaceUrl(GET_NEW_BOXES, 'boxId', boxId))
        .then((res) => {
          dispatch(setBoxesNewEnquires(res.data?.results));
          if (successCb) {
            successCb(res.data);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
