const { REACT_APP_BASE_URL: BASE_API_URL, REACT_APP_IP_KEY } = process.env;

export const IP_DATA_URL = `https://api.ipdata.co?api-key=${REACT_APP_IP_KEY}&fields=ip,country_code,time_zone`;

// CRM with website
export const BUILD_CRM = `${BASE_API_URL}/organization/build_my_crm`;

export const SUMMARY_FOR_INDUSTRY = `${BASE_API_URL}/ai_services/generate_the_summary_for_industry`;

export const CRM_STATUS = `${BASE_API_URL}/organization/is_crm_build`;

export const SEARCH_GOOGLE_PLACES = `${BASE_API_URL}/organization/google/retrieve_places`;

export const GET_PLACES_DETAILS = `${BASE_API_URL}/organization/google/retrieve_website`;
