import { createSlice } from '@reduxjs/toolkit';
import {
  ORGANIZATION_USER,
  ASSIGN_USER_TO_LEAD,
  UNASSIGN_USER_TO_LEAD
} from 'urls';
import APIRequest from 'apiRequest';
import { replaceUrl } from 'utils/urlReplace';
import CHOICES from 'choices';
import whoever_is_available_icon from 'assets/icons/whoever-is-available.svg';
import { setAssignedTo } from './leadsFilter';
import { getTaskEnquiryCount } from 'slices/taskThingsToDo/slice';
import { setAllLeads, setReplyLaterLeads } from './eboxLead';
import { isEmpty } from 'utils/utils';
import { setAccess, setRoleChangeStatus } from './access/slice';

const PENDING = CHOICES.OnboardingTeamMemberChoice.PENDING;

const { REPLY_LATER } = CHOICES.LeadStatusChoices;

export const organizationUser = createSlice({
  name: 'organizationUser',
  initialState: {
    organizationUser: {},
    teamMembers: {},
    activeTeamMembers: {}
  },
  reducers: {
    setOrganizationUser: (state, action) => {
      state.organizationUser = action.payload;
    },
    setTeamMembers: (state, action) => {
      state.teamMembers = action.payload;
    },
    setActiveTeamMembers: (state, action) => {
      state.activeTeamMembers = action.payload;
    }
  }
});

export const reset_user = {
  user_id: 'reset_user',
  name: 'reset_user'
};

// whoever_is_available
export const whoever_is_available = {
  user_id: 'whoever_is_available',
  first_name: 'Whoever is available',
  avatar: whoever_is_available_icon,
  email: '',
  name: 'Whoever is available'
};

export const whoever_assigned_to_data = {
  user_id: 'whoever_is_assigned_to',
  first_name: 'Whoever is assigned to',
  avatar: whoever_is_available_icon,
  email: '',
  name: 'Whoever is assigned to'
};

export const { setOrganizationUser, setTeamMembers, setActiveTeamMembers } =
  organizationUser.actions;

export const getOrganizationUser = (payload, successCb, setUserFilter) => {
  return async (dispatch, getState) => {
    try {
      const { myInformation } = getState().myInfo;
      const { id } = myInformation;
      const { subOrgId } = payload;
      new APIRequest()
        .get(replaceUrl(ORGANIZATION_USER, 'subOrgId', subOrgId))
        .then((res) => {
          if (res.status === 200) {
            const signedupUserData = res.data.results.filter(
              (item) => item.onboarding_status !== PENDING
            );
            const getCurrentUser = signedupUserData.find(
              (user) => user.user_id === id
            );
            const filteredWithoutCurrentUser = signedupUserData.filter(
              (user) => user.user_id !== id
            );
            const finalUsers = getCurrentUser
              ? [getCurrentUser, ...filteredWithoutCurrentUser]
              : signedupUserData;
            dispatch(setOrganizationUser({ ...res.data, results: finalUsers }));
            if (setUserFilter) {
              if (myInformation.is_assigned_to_filter_in_things_to_do_active) {
                dispatch(setAssignedTo(getCurrentUser));
              }
              dispatch(getTaskEnquiryCount());
            }
            dispatch(setTeamMembers(res.data));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
export const updateListOnAssign = (data, myInfo) => {
  return async (dispatch, getState) => {
    const {
      leads: { replyLaterLeads, currentCardList, allLeads }
    } = getState();
    try {
      if (data.assigned_to !== myInfo?.id) {
        if (currentCardList === REPLY_LATER) {
          let updatedLaterLeadsList = null;
          updatedLaterLeadsList = replyLaterLeads?.results?.filter(
            (lead) => lead.id !== data.id
          );
          if (updatedLaterLeadsList) {
            dispatch(
              setReplyLaterLeads({
                ...replyLaterLeads,
                results: updatedLaterLeadsList
              })
            );
          }
        } else {
          let updatedViewedLeadsList = null;
          updatedViewedLeadsList = allLeads?.results?.filter(
            (lead) => lead.id !== data.id
          );
          if (updatedViewedLeadsList) {
            dispatch(
              setAllLeads({
                ...allLeads,
                results: updatedViewedLeadsList
              })
            );
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const assigntoUserLead = (payload, successCb, errorCb) => {
  return async (dispatch, getState) => {
    try {
      const { isBox, leadId, body } = payload;
      const { myInformation } = getState().myInfo;
      new APIRequest()
        .put(replaceUrl(ASSIGN_USER_TO_LEAD, 'leadId', leadId), body)
        .then((res) => {
          if (res.status === 200) {
            if (successCb) {
              successCb(res.data);
            }
            if (!isBox) {
              dispatch(updateListOnAssign(res.data, myInformation));
            }
          }
        })
        .catch(() => {
          if (errorCb) {
            errorCb();
          }
        });
    } catch (e) {
      if (errorCb) {
        errorCb();
      }
      console.log(e);
    }
  };
};
export const unAssigntoUserLead = (payload, successCb, errorCb) => {
  return async () => {
    try {
      const { leadId, body } = payload;
      new APIRequest()
        .put(replaceUrl(UNASSIGN_USER_TO_LEAD, 'leadId', leadId), body)
        .then((res) => {
          if (res.status === 200) {
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      if (errorCb) {
        errorCb();
      }
      console.log(e);
    }
  };
};
export const getActiveOrganizationUsers = (payload) => {
  return async (dispatch) => {
    const { subOrgId, org_level } = payload;
    const query = {
      org_level
    };
    try {
      new APIRequest()
        .get(replaceUrl(ORGANIZATION_USER, 'subOrgId', subOrgId), query)
        .then((res) => {
          if (res.status === 200) {
            dispatch(setActiveTeamMembers(res.data));
          }
        });
    } catch (e) {
      console.log('e', e);
    }
  };
};

export const manipulateUserList = ({ list, payload }) => {
  if (payload && !isEmpty(list)) {
    const { box, location, user_id } = payload;
    if (payload.event_name == 'box_access') {
      const updatedUsersList = list.map((user) => {
        if (user.user_id == user_id) {
          const x = box.access_value
            ? [...user.my_boxes, box.box_id] // add box id if access is given
            : user.my_boxes.filter((b) => b !== box.box_id); // remove box id if access is removed
          const obj = {
            ...user,
            my_boxes: x
          };
          return obj;
        }
        return user;
      });
      return updatedUsersList;
    }
    if (payload.event_name == 'location_access') {
      const updatedUsersList = list.map((user) => {
        if (user.user_id == user_id) {
          const obj = {
            ...user,
            my_locations: location.access_value
              ? [...user.my_locations, location.location_id]
              : [...user.my_locations].filter(
                  (loc) => loc !== location.location_id
                )
          };
          return obj;
        }
        return user;
      });
      return updatedUsersList;
    }
    return list;
  }
};

export const updateUserAccess = (payload) => {
  return async (dispatch, getState) => {
    const myInformation = getState().myInfo.myInformation;
    const orgUsers = getState().organizationUser?.organizationUser;
    const teamMembers = getState().organizationUser?.teamMembers;
    if (
      myInformation.id === payload.user_id &&
      myInformation.id !== payload.changed_by_user
    ) {
      dispatch(setAccess(true));
      return;
    }
    dispatch(
      setOrganizationUser({
        ...orgUsers,
        results: manipulateUserList({ list: orgUsers?.results, payload })
      })
    );
    dispatch(
      setTeamMembers({
        ...teamMembers,
        results: manipulateUserList({ list: teamMembers?.results, payload })
      })
    );
  };
};

export const updateUserList = ({ list, payload }) => {
  if (payload && !isEmpty(list)) {
    const { role, user_id } = payload;
    const updatedUsersList = list.map((user) => {
      if (user.user_id === user_id) {
        return { ...user, role: role };
      }
      return user;
    });
    return updatedUsersList;
  }
  return list;
};

export const updateUserRole = (payload) => {
  return async (dispatch, getState) => {
    const myInformation = getState().myInfo.myInformation;
    const orgUsers = getState().organizationUser?.organizationUser;
    const teamMembers = getState().organizationUser?.teamMembers;
    if (
      myInformation.id === payload.user_id &&
      myInformation.id !== payload.changed_by_user
    ) {
      dispatch(setRoleChangeStatus(true));
      return;
    }
    dispatch(
      setOrganizationUser({
        ...orgUsers,
        results: updateUserList({ list: orgUsers?.results, payload })
      })
    );
    dispatch(
      setTeamMembers({
        ...teamMembers,
        results: updateUserList({ list: teamMembers?.results, payload })
      })
    );
  };
};

export default organizationUser.reducer;
