/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { replaceUrl } from 'utils/urlReplace';
import APIRequest from 'apiRequest';
import {
  CONNECT_WEBFORM_FORWARDING_CHANNEL,
  CREATE_WEBFORM_ADDRESS,
  DELETE_WEBFORM_ADDRESS,
  GET_ALL_WEBFORM_ADDRESS
} from 'urls/webformForwarding';

const initialState = {
  webformAddress: [],
  selectedWebformAddress: {}
};

export const webformForwardingSlice = createSlice({
  name: 'webformForwarding',
  initialState,
  reducers: {
    setWebformAddress: (state, action) => {
      state.webformAddress = action.payload;
    },
    setSelectedWebformAddress: (state, action) => {
      state.selectedWebformAddress = action.payload;
    }
  }
});

export const { setWebformAddress, setSelectedWebformAddress } =
  webformForwardingSlice.actions;

export default webformForwardingSlice.reducer;

// function to connect webform forwording channel
export const connectWebformForwardingChannel = (payload, callback) => {
  return async () => {
    const { subOrgId } = payload;
    try {
      await new APIRequest()
        .post(
          replaceUrl(CONNECT_WEBFORM_FORWARDING_CHANNEL, 'subOrgId', subOrgId)
        )
        .then((res) => {
          if (callback) {
            callback(res);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

/// function to get all the webform address
export const getAllWebformAddress = (payload, callback) => {
  return async (dispatch) => {
    const { subOrgId } = payload;
    try {
      await new APIRequest()
        .get(replaceUrl(GET_ALL_WEBFORM_ADDRESS, 'subOrgId', subOrgId))
        .then((res) => {
          dispatch(setWebformAddress(res.data));
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

//function to create webform forwarding address
export const createWebformAddress = (payload, callback) => {
  return async (dispatch, getState) => {
    const { data } = payload;
    const webformAddress = getState().webformForwarding.webformAddress;
    try {
      new APIRequest().post(CREATE_WEBFORM_ADDRESS, data).then((res) => {
        dispatch(setWebformAddress([...webformAddress, res.data]));
        if (callback) {
          callback(res.data);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

//function to update webform forwarding address
export const updateWebformAddress = (payload, callback) => {
  return async (dispatch, getState) => {
    const { data } = payload;
    const webformAddress = getState().webformForwarding.webformAddress;
    try {
      new APIRequest().put(CREATE_WEBFORM_ADDRESS, data).then((res) => {
        const updatedData = webformAddress.map((item) => {
          if (item.id === data.new_webform_id) {
            return res.data;
          }
          return item;
        });
        dispatch(setWebformAddress(updatedData));
        if (callback) {
          callback(res.data);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

// function to delete webform forwarding address
export const deleteWebformAddress = (payload, callback) => {
  return async () => {
    const { addressId } = payload;
    try {
      new APIRequest()
        .delete(replaceUrl(DELETE_WEBFORM_ADDRESS, 'addressId', addressId))
        .then(() => {
          if (callback) {
            callback(addressId);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
