import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import {
  GET_FILE_LIBRARY_LIST,
  UPDATE_DELETE_FILE,
  GET_FILE_ATTACHED_TO_LEAD
} from 'urls';
import { replaceUrl } from 'utils/urlReplace';

const fileLibrarySlice = createSlice({
  name: 'fileLibrary',
  initialState: {
    fileData: {},
    leadFileData: {},
    imageFileData: [],
    attachmentsFileData: []
  },
  reducers: {
    setFileData: (state, action) => {
      state.fileData = action.payload;
    },
    setLeadFileData: (state, action) => {
      state.leadFileData = action.payload;
    },
    setImageFile: (state, action) => {
      state.imageFileData = action.payload;
    },
    setAttachementFile: (state, action) => {
      state.attachmentsFileData = action.payload;
    }
  }
});

export const {
  setFileData,
  setLeadFileData,
  setImageFile,
  setAttachementFile
} = fileLibrarySlice.actions;

export default fileLibrarySlice.reducer;

export const getFileLibraryData = (payload) => {
  return (dispatch) => {
    try {
      const { subOrgId, callback, query = {} } = payload;
      new APIRequest()
        .get(GET_FILE_LIBRARY_LIST, {
          sub_organization_id: subOrgId,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setFileData(res.data));
            if (callback) {
              callback();
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const uploadFilesSettings = (payload, successCb, errorCb) => {
  return () => {
    try {
      const { body } = payload;
      new APIRequest()
        .post(GET_FILE_LIBRARY_LIST, body)
        .then((resp) => {
          if (successCb) {
            successCb(resp.data);
          }
        })
        .catch((err) => {
          if (errorCb) errorCb(err);
        });
    } catch (e) {
      if (errorCb) {
        errorCb(e);
      }
    }
  };
};

export const editFileName = (payload) => {
  return () => {
    try {
      const { body, fileId, callback } = payload;
      new APIRequest()
        .put(replaceUrl(UPDATE_DELETE_FILE, 'fileId', fileId), body)
        .then(() => {
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const deleteFile = (payload) => {
  return () => {
    try {
      const { fileId, callback } = payload;
      new APIRequest()
        .delete(replaceUrl(UPDATE_DELETE_FILE, 'fileId', fileId))
        .then(() => {
          if (callback) {
            callback(fileId);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getFileAttachedToLead = (payload) => {
  return (dispatch) => {
    try {
      const { leadId, callback } = payload;
      let tempAttachment = [];
      let tempImage = [];
      new APIRequest()
        .get(replaceUrl(GET_FILE_ATTACHED_TO_LEAD, 'leadId', leadId))
        .then((resp) => {
          dispatch(setLeadFileData(resp.data));
          resp.data?.results.filter((item) => {
            const { file_name, file_url, mime_type } = item;
            if (mime_type) {
              if (
                mime_type === 'image/png' ||
                mime_type === 'image/jpeg' ||
                mime_type === 'image/jpg' ||
                mime_type === 'image' ||
                mime_type === 'image/webp'
              ) {
                tempImage.push(item);
              } else {
                tempAttachment.push(item);
              }
            } else {
              if (file_name) {
                if (
                  file_name.includes('.png') ||
                  file_name.includes('.jpg') ||
                  file_name.includes('.jpeg') ||
                  file_name.includes('.gif')
                ) {
                  tempImage.push(item);
                } else {
                  tempAttachment.push(item);
                }
              } else {
                if (
                  file_url.includes('.png') ||
                  file_url.includes('.jpg') ||
                  file_url.includes('.jpeg') ||
                  file_url.includes('.gif')
                ) {
                  tempImage.push(item);
                } else {
                  tempAttachment.push(item);
                }
              }
            }
          });
          dispatch(setAttachementFile(tempAttachment));
          dispatch(setImageFile(tempImage));
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
