import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  LEADS_LABELS,
  UPDATE_LEADS_LABELS,
  ADD_LABELS_TO_LEADS,
  REMOVE_LEADS_LABELS,
  CREATE_OR_UPDATE_BOX_LABELS,
  UPDATE_LABEL_VALUE,
  LEADS_LABELS_PUBLIC
} from 'urls';
import APIRequest from 'apiRequest';
import { replaceUrl } from 'utils/urlReplace';
import { VALUE_LABELS } from 'utils/constants';

export const labelsSlice = createSlice({
  name: 'labels',
  initialState: {
    allLabels: [],
    error: [],
    selectedLabels: [],
    tempSelected: [],
    removedLabels: [],
    boxLabels: [],
    tempBoxLabels: [],
    activeLabelTab: VALUE_LABELS
  },
  reducers: {
    setLabels: (state, action) => {
      state.allLabels = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSelectedLabels: (state, action) => {
      state.selectedLabels = action.payload;
    },
    setTempSelected: (state, action) => {
      state.tempSelected = action.payload;
    },
    setRemovedLabels: (state, action) => {
      state.removedLabels = action.payload;
    },
    setBoxLabels: (state, action) => {
      state.boxLabels = action.payload;
    },
    setTempBoxLabels: (state, action) => {
      state.tempBoxLabels = action.payload;
    },
    setActiveLabelTab: (state, action) => {
      state.activeLabelTab = action.payload;
    }
  }
});

export const {
  setLabels,
  setError,
  setSelectedLabels,
  setTempSelected,
  setRemovedLabels,
  setBoxLabels,
  setTempBoxLabels,
  setActiveLabelTab
} = labelsSlice.actions;

export const getBoxLabel = (payload) => {
  return async (dispatch) => {
    try {
      const { boxId, callback, query = {} } = payload;
      return new APIRequest()
        .get(LEADS_LABELS, {
          box_id: boxId,
          ...query
        })
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(setBoxLabels(resp.data));
            dispatch(setTempBoxLabels(resp.data));
            if (callback) {
              callback();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(' e', e);
    }
  };
};

export const getLeadLabel = (payload) => {
  return async (dispatch, getState) => {
    const { subOrganizationId } = getState().myInfo;
    try {
      const { subOrgId, boxId, callback, query = {} } = payload;
      return new APIRequest()
        .get(LEADS_LABELS, {
          sub_organization: subOrgId || subOrganizationId,
          box_id: boxId,
          ...query
        })
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(setLabels(resp.data));
            if (callback) {
              callback();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(' e', e);
    }
  };
};

export const addNewLabel = (payload, successCb, onError) => {
  return async (dispatch) => {
    try {
      const { body, callback } = payload;
      new APIRequest()
        .post(replaceUrl(LEADS_LABELS), body)
        .then((res) => {
          if (res.status === 201) {
            dispatch(setError([]));
            if (callback) {
              callback(res.data);
            }
            if (successCb) {
              successCb(res.data);
            }
          }
        })
        .catch((err) => {
          if (onError) {
            onError();
          }
          dispatch(setError(err?.response?.data?.label_name));
        });
    } catch (e) {
      if (onError) {
        onError();
      }
      console.log(e);
    }
  };
};

export const updateLabels = (payload, successCb, errorCb) => {
  return async (dispatch) => {
    try {
      const { labelId, body, callback } = payload;
      new APIRequest()
        .put(replaceUrl(UPDATE_LEADS_LABELS, 'labelId', labelId), body)
        .then((res) => {
          if (res.status === 200) {
            if (callback) {
              callback(res.data);
            }
            dispatch(modifyLabels({ data: res.data }));
            dispatch(setError([]));
          }
        })
        .catch((err) => {
          if (err) {
            if (errorCb) {
              errorCb();
            }
            dispatch(setError(err.response.data.label_name));
            console.log(err.response.data);
          }
        });
    } catch (e) {
      console.log('e', e);
      if (errorCb) {
        errorCb();
      }
      return '';
    }
  };
};

export const deleteLabels = (payload) => {
  return async () => {
    try {
      const { labelId, callback } = payload;
      new APIRequest()
        .delete(replaceUrl(UPDATE_LEADS_LABELS, 'labelId', labelId))
        .then((res) => {
          if (res.status === 200) {
            if (callback) {
              callback(labelId);
            }
          }
        })
        .catch((err) => {
          if (err) {
            console.log(err.response.data);
          }
        });
    } catch (e) {
      console.log('e', e);
      return '';
    }
  };
};

export const addLabelsToLeads = (payload) => {
  return async () => {
    try {
      const { body, leadId, callback } = payload;
      new APIRequest()
        .post(replaceUrl(ADD_LABELS_TO_LEADS, 'lead_id', leadId), body)
        .then((res) => {
          if (res.status === 201) {
            if (callback) {
              callback();
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const removeLabelsFromLeads = (payload) => {
  return async () => {
    try {
      const { body, leadId, callback } = payload;
      new APIRequest()
        .delete(replaceUrl(REMOVE_LEADS_LABELS, 'lead_id', leadId), body)
        .then((res) => {
          if (res.status === 200) {
            if (callback) {
              callback();
            }
          }
        });
    } catch (e) {
      console.log('e', e);
      return '';
    }
  };
};

export const updateLabelValue = createAsyncThunk(
  'labels/updateLabelValue',
  async (payload, { dispatch, rejectWithValue }) => {
    const { leadId, body, callback } = payload;
    try {
      const response = await new APIRequest().patch(
        replaceUrl(UPDATE_LABEL_VALUE, 'lead_id', leadId),
        body
      );
      if (callback) {
        callback(response.data);
      }
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const createOrUpdateBoxLabels = (payload) => {
  return async () => {
    try {
      const { subOrgId, body, callback } = payload;
      new APIRequest()
        .post(
          replaceUrl(CREATE_OR_UPDATE_BOX_LABELS, 'subOrgId', subOrgId),
          body
        )
        .then((res) => {
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const modifyLabels = (payload) => {
  return async (dispatch, getState) => {
    const { data } = payload;
    const labels = getState().labels.allLabels;
    const updatedLabels = labels.map((label) => {
      if (label.id == data.id) {
        return data;
      }
      return label;
    });
    dispatch(setLabels(updatedLabels));
  };
};

export const getPublicLabels = createAsyncThunk(
  'labels/getPublicLabels',
  async (data, { dispatch, rejectWithValue }) => {
    const { subOrgId } = data;
    try {
      const response = await new APIRequest().get(
        replaceUrl(LEADS_LABELS_PUBLIC, 'subOrgId', subOrgId)
      );
      dispatch(setLabels(response.data));
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export default labelsSlice.reducer;
