import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import CHOICES from 'choices';
import {
  setShowOrgUserLogOutModal,
  setShowUserDeletedModal,
  setShowUserPermissionRemovedModal
} from 'slices/myInfoSlice';
import {
  CLONE_ACCOUNT,
  CREATE_ACCOUNT,
  DELETE_INVITED_ORG_USER,
  EDIT_ORG_USER_PERMISSION,
  GET_ALL_ACCOUNTS_LIST,
  GET_ORG_DETAILS,
  GET_ORG_USERS,
  INVITE_ORG_USER,
  ORG_CHECKOUT,
  RESEND_INVITE_ORG_USER,
  SIGN_UP_INVITED_ORG_USER,
  UPDATE_ACCOUNT,
  UPDATE_ORG_USER_ROLE
} from 'urls/organizationAccount';
import { replaceUrl } from 'utils/urlReplace';

const { ORG_ADMIN, GROUP_ADMIN } = CHOICES.TeamMemberRoleChoice;

const OrganizationAccountSlice = createSlice({
  name: 'OrganizationAccount',
  initialState: {
    organizationData: {},
    accountList: [],
    websiteData: {},
    isShowSummary: false,
    inviteUserData: {},
    orgUsersList: [],
    editUserData: {},
    organizationId: null,
    orgUserPermissionUpdated: false,
    isFetching: true,
    isSubOrgDataLoading: false
  },
  reducers: {
    setOrganizationData: (state, action) => {
      state.organizationData = action.payload;
    },
    setAccountList: (state, action) => {
      state.accountList = action.payload;
    },
    setWebsiteData: (state, action) => {
      state.websiteData = action.payload;
    },
    setIsShowSummary: (state, action) => {
      state.isShowSummary = action.payload;
    },
    setInviteUserData: (state, action) => {
      state.inviteUserData = action.payload;
    },
    setOrgUsersList: (state, action) => {
      state.orgUsersList = action.payload;
    },
    setEditUserData: (state, action) => {
      state.editUserData = action.payload;
    },
    setOrganizationId: (state, action) => {
      state.organizationId = action.payload;
    },
    setOrgUserPermissionUpdated: (state, action) => {
      state.orgUserPermissionUpdated = action.payload;
    },
    setIsFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    setIsSubOrgDataLoading: (state, action) => {
      state.isSubOrgDataLoading = action.payload;
    }
  }
});

export const {
  setAccountList,
  setWebsiteData,
  setIsShowSummary,
  setInviteUserData,
  setOrgUsersList,
  setEditUserData,
  setOrganizationData,
  setOrganizationId,
  setOrgUserPermissionUpdated,
  setIsFetching,
  setIsSubOrgDataLoading
} = OrganizationAccountSlice.actions;

export default OrganizationAccountSlice.reducer;

export const getOrganizationDetails = (payload, onSuccess) => {
  return async (dispatch) => {
    try {
      const { orgId } = payload;
      new APIRequest()
        .get(replaceUrl(GET_ORG_DETAILS, 'orgId', orgId))
        .then((res) => {
          dispatch(setOrganizationData(res.data));
          if (onSuccess) {
            onSuccess(res.data);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const orgCheckout = (payload, onSuccess) => {
  return async (dispatch) => {
    try {
      new APIRequest().post(ORG_CHECKOUT, payload).then((res) => {
        if (onSuccess) {
          onSuccess(res.data);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getAllAccounts = (payload, onSuccess) => {
  return async (dispatch) => {
    try {
      const { orgId } = payload;
      new APIRequest()
        .get(replaceUrl(GET_ALL_ACCOUNTS_LIST, 'orgId', orgId))
        .then((res) => {
          dispatch(setAccountList(res.data));
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const createAccount = (payload, onSuccess) => {
  return async (dispatch) => {
    try {
      const { orgId, data } = payload;
      new APIRequest()
        .post(replaceUrl(CREATE_ACCOUNT, 'orgId', orgId), data)
        .then((res) => {
          if (onSuccess) {
            onSuccess(res.data);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const cloneAccount = (payload, onSuccess) => {
  return async (dispatch) => {
    try {
      const { data, orgId } = payload;
      new APIRequest()
        .post(replaceUrl(CLONE_ACCOUNT, 'orgId', orgId), data)
        .then((res) => {
          if (onSuccess) {
            onSuccess(res.data);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateAccountData = (payload, onSuccess) => {
  return async (dispatch) => {
    try {
      const { data, subOrgId } = payload;
      new APIRequest()
        .put(replaceUrl(UPDATE_ACCOUNT, 'subOrgId', subOrgId), data)
        .then((res) => {
          if (onSuccess) {
            onSuccess(res.data);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const sendOrgUserInvite = (payload, onSuccess, onError) => {
  return async () => {
    try {
      const { data, orgId } = payload;
      new APIRequest()
        .post(replaceUrl(INVITE_ORG_USER, 'orgId', orgId), data)
        .then((resp) => {
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((e) => {
          onError();
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getOrgUsers = (payload, onSuccess) => {
  return async (dispatch) => {
    try {
      const { orgId } = payload;
      new APIRequest()
        .get(replaceUrl(GET_ORG_USERS, 'orgId', orgId))
        .then((resp) => {
          dispatch(setOrgUsersList(resp.data));
          if (onSuccess) {
            onSuccess();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const signUpInvitedOrgUser = (payload, onSuccess) => {
  return async (dispatch) => {
    try {
      const { body, orgId, errorCallback } = payload;
      new APIRequest()
        .post(replaceUrl(SIGN_UP_INVITED_ORG_USER, 'orgId', orgId), body)
        .then((resp) => {
          if (onSuccess) {
            onSuccess(resp?.data);
          }
        })
        .catch((err) => {
          if (err?.response?.data?.email) {
            errorCallback(err?.response?.data?.email[0]);
          } else {
            errorCallback(err?.response?.data.error);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const deleteInvitedOrgUser = (payload, onSuccess) => {
  return async (dispatch) => {
    try {
      const { orgId, userId, body } = payload;
      new APIRequest()
        .delete(
          replaceUrl(
            DELETE_INVITED_ORG_USER,
            ['orgId', 'userId'],
            [orgId, userId]
          ),
          body
        )
        .then((res) => {
          if (onSuccess) {
            onSuccess(userId);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const resendOrgUserInvite = (payload, onSuccess) => {
  return async (dispatch) => {
    try {
      const { data, orgId } = payload;
      new APIRequest()
        .post(replaceUrl(RESEND_INVITE_ORG_USER, 'orgId', orgId), data)
        .then((res) => {
          if (onSuccess) {
            onSuccess();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const editOrgUserPermission = (payload, onSuccess) => {
  return async (dispatch) => {
    try {
      const { orgId, userId, data, company_name } = payload;
      new APIRequest()
        .patch(
          replaceUrl(
            EDIT_ORG_USER_PERMISSION,
            ['orgId', 'userId'],
            [orgId, userId]
          ),
          data
        )
        .then((res) => {
          if (onSuccess) {
            onSuccess(data, company_name, userId);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateOrgUserRole = (payload, onSuccess) => {
  return async (dispatch) => {
    try {
      const { orgId, userId, data, selectedRole, user } = payload;
      new APIRequest()
        .post(
          replaceUrl(
            UPDATE_ORG_USER_ROLE,
            ['orgId', 'userId'],
            [orgId, userId]
          ),
          data
        )
        .then((res) => {
          if (onSuccess) {
            onSuccess(res.data, selectedRole, user);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// function to call when user role changed from org admin to group admin, if that user is in org dashboard, we need to log him out
export const updatingOrgUserRoleSocket = (payload) => {
  return async (dispatch, getState) => {
    const myInformation = getState().myInfo.myInformation;
    if (
      myInformation.id === payload.user_id &&
      myInformation.id !== payload.changed_by_user
    ) {
      if (myInformation.my_role === ORG_ADMIN && payload.role === GROUP_ADMIN) {
        dispatch(setShowOrgUserLogOutModal(true));
      }
    }
  };
};

// function to call when org users permission changed, if user logged in with same subOrg we should logout, orelse we need to ask him to refresh
export const updateOrgUserPermission = (payload) => {
  return async (dispatch, getState) => {
    const myInformation = getState().myInfo.myInformation;
    const subOrganizationId = getState().myInfo.subOrganizationId;
    if (
      myInformation.id === payload.removed_user_id &&
      subOrganizationId === payload.removed_sub_org_id
    ) {
      dispatch(setShowUserPermissionRemovedModal(true));
    } else {
      dispatch(setOrgUserPermissionUpdated(true));
    }
  };
};

// function to call when the loggedIn user deleted, we have to show the modal to user to logout
export const deleteOrgUser = (payload) => {
  return async (dispatch, getState) => {
    const myInformation = getState().myInfo.myInformation;
    if (myInformation.id === payload.removed_user_id) {
      dispatch(setShowUserDeletedModal(true));
    }
  };
};
