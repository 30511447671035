import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import { BUILD_CRM, SUMMARY_FOR_INDUSTRY } from 'urls/signup';

export const buildCrmSlice = createSlice({
  name: 'buildCrm',
  initialState: {
    industrySummary: ''
  },
  reducers: {
    setIndustrySummary: (state, action) => {
      state.industrySummary = action.payload;
    }
  }
});

export const { setIndustrySummary } = buildCrmSlice.actions;

export const buildCrmWithWebsite = (payload) => {
  const { data, onSuccess, onError } = payload;
  return async () => {
    try {
      await new APIRequest().post(BUILD_CRM, data).then((res) => {
        if (onSuccess) {
          onSuccess(res.data);
        }
      });
    } catch (e) {
      console.log('e', e);
      if (onError) {
        onError(e.response);
      }
    }
  };
};

export const generateIndustrySummary = (payload) => {
  const { data, onSuccess, onError } = payload;
  return async (dispatch) => {
    try {
      await new APIRequest().post(SUMMARY_FOR_INDUSTRY, data).then((res) => {
        dispatch(setIndustrySummary(res.data.message));
        if (onSuccess) {
          onSuccess(res.data);
        }
      });
    } catch (e) {
      console.log('e', e);
      if (onError) {
        onError(e.response);
      }
    }
  };
};

export default buildCrmSlice.reducer;
