import { createSlice } from '@reduxjs/toolkit';
import {
  RESEND_SENDER_EMAIL_VERIFICATION,
  UPDATE_SENDER_EMAIL,
  ADD_SENDER_EMAIL,
  GET_SENDER_EMAIL,
  REFRESH_STATUS,
  DELETE_SENDER_EMAIL,
  GET_SENDER_EMAIL_AUTOMATION_COUNT,
  VALIDATE_SENDER_EMAIL,
  SENDER_EMAIL_LOCATION
} from 'urls/domainVerification';
import { replaceUrl } from 'utils/urlReplace';
import APIRequest from 'apiRequest';
// import { getMyInfo } from 'slices/myInfoSlice';
import CHOICES from 'choices';

const { VERIFIED } = CHOICES.EmailSenderChoice;

export const senderEmailSlice = createSlice({
  name: 'senderEmails',
  initialState: {
    senderEmails: [],
    verifiedSenderEmails: [],
    showSignature: null,
    senderEmail: null
  },
  reducers: {
    setSenderEmails: (state, action) => {
      state.senderEmails = action.payload;
    },
    setShowSignature: (state, action) => {
      state.showSignature = action.payload;
    },
    setVerifiedSenderEmails: (state, action) => {
      state.verifiedSenderEmails = action.payload;
    },
    setSenderEmail: (state, action) => {
      state.senderEmail = action.payload;
    }
  }
});

export const {
  setSenderEmails,
  setShowSignature,
  setVerifiedSenderEmails,
  setSenderEmail
} = senderEmailSlice.actions;

const updateSenderEmailsList = (data) => {
  return async (dispatch, getState) => {
    const {
      senderEmail: { senderEmails }
    } = getState();
    const updatedList = senderEmails.map((email) => {
      if (email.id == data.id) return data;
      // if (data.is_default) {
      //   return { ...email, is_default: false };
      // }
      return email;
    });
    // update my information sender id if default is made as false
    // dispatch(getMyInfo());
    dispatch(setSenderEmails(updatedList));
    const verifiedEmails = updatedList.filter((d) => d.status == VERIFIED);
    dispatch(setVerifiedSenderEmails(verifiedEmails));
  };
};

export const createSenderEmail = (payload) => {
  return async (dispatch, getState) => {
    const {
      senderEmail: { senderEmails }
    } = getState();
    const { data, successCb, errorCb } = payload;
    try {
      await new APIRequest().post(ADD_SENDER_EMAIL, data).then((res) => {
        if (res.status == 200) {
          if (!res.data.ErrorCode) {
            const updatedList = [...senderEmails, res.data];
            dispatch(setSenderEmails(updatedList));
          }
          if (successCb) {
            successCb(res.data);
          }
        }
      });
    } catch (e) {
      if (errorCb) {
        errorCb();
      }
      console.log('e', e);
    }
  };
};

export const getSenderEmails = (payload) => {
  return async (dispatch, getState) => {
    const {
      myInfo: { subOrganizationId }
    } = getState();
    try {
      await new APIRequest()
        .get(GET_SENDER_EMAIL, {
          sub_org_id: payload?.sub_org_id || subOrganizationId
        })
        .then((res) => {
          dispatch(setSenderEmails(res?.data));
          const verifiedEmails = res?.data.filter((d) => d.status == VERIFIED);
          dispatch(setVerifiedSenderEmails(verifiedEmails));
          if (payload?.successCb) {
            payload?.successCb();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateSenderEmail = (payload) => {
  return async (dispatch) => {
    const { data, senderEmailId, successCb } = payload;
    try {
      await new APIRequest()
        .patch(
          replaceUrl(UPDATE_SENDER_EMAIL, 'senderEmailId', senderEmailId),
          data
        )
        .then((res) => {
          if (res.status == 200) {
            const { data } = res;
            dispatch(updateSenderEmailsList(data));
          }
          if (successCb) {
            successCb(res.data);
          }
        });
    } catch (e) {
      console.log('e', e);
    }
  };
};

export const resendEmailVerification = (payload) => {
  const { data, successCb, errorCb } = payload;
  return async (dispatch, getState) => {
    try {
      await new APIRequest()
        .post(RESEND_SENDER_EMAIL_VERIFICATION, data)
        .then((res) => {
          if (res.status == 200) {
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
      if (errorCb) {
        errorCb(e);
      }
    }
  };
};

export const refreshSenderEmail = (payload) => {
  const { signature_id, successCb, errorCb } = payload;
  return async (dispatch) => {
    try {
      await new APIRequest()
        .get(replaceUrl(REFRESH_STATUS, 'signatureId', signature_id))
        .then((res) => {
          if (res.status == 200) {
            dispatch(updateSenderEmailsList(res.data));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
      if (errorCb) {
        errorCb(e);
      }
    }
  };
};

export const deleteEmail = (payload) => {
  const { senderEmailId, successCb, errorCb, query } = payload;
  return async (dispatch, getState) => {
    const {
      senderEmail: { senderEmails }
    } = getState();
    try {
      await new APIRequest()
        .delete(
          replaceUrl(DELETE_SENDER_EMAIL, 'senderEmailId', senderEmailId),
          {},
          query
        )
        .then((res) => {
          if (res.status == 200) {
            const updatedList = senderEmails.filter(
              (email) => email.id !== senderEmailId
            );
            dispatch(setSenderEmails(updatedList));
            const verifiedEmails = updatedList.filter(
              (d) => d.status == VERIFIED
            );
            dispatch(setVerifiedSenderEmails(verifiedEmails));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
      if (errorCb) {
        errorCb(e);
      }
    }
  };
};

export const getAutomationCount = (payload) => {
  const { successCb, errorCb, query } = payload;
  return async () => {
    try {
      await new APIRequest()
        .get(GET_SENDER_EMAIL_AUTOMATION_COUNT, query)
        .then((res) => {
          successCb(res.data);
        });
    } catch (e) {
      console.log(e);
      if (errorCb) {
        errorCb(e);
      }
    }
  };
};

export const validateSenderEmail = (payload) => {
  const { data, successCb, errorCb } = payload;
  return async () => {
    try {
      await new APIRequest().post(VALIDATE_SENDER_EMAIL, data).then((res) => {
        if (res.status == 200) {
          if (successCb) {
            successCb(res.data);
          }
        }
      });
    } catch (e) {
      if (errorCb) {
        errorCb(e);
      }
    }
  };
};

export const addLocationToSenderEmail = (payload) => {
  const { data, successCb, errorCb } = payload;
  return async (dispatch, getState) => {
    const {
      senderEmail: { senderEmails }
    } = getState();
    try {
      await new APIRequest()
        .post(
          replaceUrl(
            SENDER_EMAIL_LOCATION,
            ['senderEmailId', 'locationId'],
            [data.senderEmailId, data.locationId]
          )
        )
        .then((res) => {
          if (res.status == 200) {
            const updatedList = senderEmails.map((email) => {
              if (email.id == data.senderEmailId) return res.data;
              return email;
            });
            dispatch(setSenderEmails(updatedList));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      if (errorCb) {
        errorCb(e);
      }
    }
  };
};

export default senderEmailSlice.reducer;
